import React from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  IconButton,
  Link,
  TextField,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import "./NewsletterPage.css";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import HomePageInterface from "../../../Portal/types/HomePageInterface";
import HeaderNewsletterPage from "./Header/HeaderNewsletterPage";
import Bottom from "../Bottom";
import FooterPage from "../FooterPage/FooterPage";

export default function NewsletterPage() {
  const mobile = useMediaQuery("(min-width: 901px)");
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const { id } = useParams();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        width: "100svw",
        height: "100svh",
        flexDirection: "column",
      }}
    >
      <Fab
        sx={{
          backgroundColor: "rgba(53, 68, 145, 1)",
          "&:hover": { backgroundColor: "rgba(53, 68, 100, 1)" },
        }}
        className="NewsletterPage-FloatingButton"
        href="javascript:history.back()"
      >
        <ArrowBackOutlinedIcon id="NewsletterPage-BackIcon" />
      </Fab>

      <Box className="NewsletterPage-Box">
        <HeaderNewsletterPage />
        <Bottom />
        <FooterPage />
      </Box>
    </Box>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Layout from "./layout/Layout";
import { queryClient } from "./services/queryClient";
import { QueryClientProvider } from "react-query";
import { router } from "./routes";
import { ContextProvider } from "./components/Portal/contexts/ContextProvider";
import { ChakraProvider } from "@chakra-ui/react";
export const permAdministrador = 1;
export const permCoordenador = 2;
export const permGeral = 3;
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ContextProvider>
        <Layout>
          <RouterProvider router={router} />
        </Layout>
      </ContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";
// Chakra imports
import {
  Box,
  Grid,
  Icon,
  SimpleGrid,
  Skeleton,
  Spinner,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
// Assets
// Custom components
import Navbar from "./components/navbar/NavbarAdmin";
import { useQuery } from "react-query";
import axios from "axios";
import dayjs from "dayjs";
import _ from "lodash";
import ActivityInterface from "../../../types/ActivityInterface";
import CompanyInterface from "../../../types/CompanyInterface";
import MiniStatistics from "../Components/card/MiniStatistics";
import IconBox from "../Components/icons/IconBox";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TabelaUltimasAtividades from "./components/Tabela/UltimasAtividades";
import GraficoPizzaHorasCliente from "./components/GraficoPizza/HorasCliente";
import GraficoBarraTotalHoras from "./components/GraficoBarra/TotalHoras";
import GraficoLinhaPorcentagemAgendamento from "./components/GraficoLinha/PorcentagemAgendamento";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";

dayjs.extend(customParseFormat);

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = "white";
  const [mobile] = useMediaQuery("(min-width: 901px)");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { onOpen } = useDisclosure();
  const currentUserId = localStorage.getItem("currentUserId") ?? "-1";
  const [graphReady, setGraphReady] = useState(false);
  const [userContextId, setUserContextId] = useState(currentUserId);
  const meses = [
    {
      name: "Horas",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];
  const [dataFinal, setDataFinal] = useState<
    {
      name: string;
      data: number[];
    }[]
  >(meses);

  const horaCliente = [0];
  const [horaFinalCliente, setHoraFinalCliente] =
    useState<number[]>(horaCliente);

  const clientes: string[] = [];

  const [lista, setLista] = useState<string[]>(clientes);

  const mesesSchedule = useRef([
    {
      name: "% de dias",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]);
  const [porcentagemSchedule, setPorcentagemSchedule] = useState<
    React.MutableRefObject<
      {
        name: string;
        data: number[];
      }[]
    >
  >(mesesSchedule);

  const [data, setData] = useState<ActivityInterface[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const consultas = async () =>
    Promise.all([
      axios.get("https://api.incortec.com.br:8080/registrationservice/company/list"),
      axios.get(
        "https://api.incortec.com.br:8080/registrationservice/activity/user/" +
          userContextId
      ),
      axios.get(
        "https://api.incortec.com.br:8080/registrationservice/schedule/user/" +
          userContextId
      ),
    ])
      .then(response => {
        response[0].data.forEach((val: any) => {
          if (clientes.length === response[0].data.length) {
            return;
          } else {
            clientes.push(val.nm_company);
            setLista(clientes);
          }
        });
        const monthGroup = _.groupBy(response[1].data, ({ dt_activity }) =>
          dayjs(dayjs(dt_activity, "DD/MM/YYYY")).month()
        );

        const companyGroup = _.groupBy(
          response[1].data,
          ({ company }) => company.nr_sequency
        );

        const scheduleMonthGroup = _.groupBy(response[2].data, ({ dt_ini }) =>
          dayjs(dt_ini, "DD/MM/YYYY").month()
        );

        const averageIniTimeGroup = _.groupBy(
          response[1].data,
          ({ dt_time_ini }) =>
            dayjs(dayjs(dt_time_ini, "HH:mm")).isBefore(dayjs("12:01", "HH:mm"))
        );

        const averageFinTimeGroup = _.groupBy(
          response[1].data,
          ({ dt_time_fin }) =>
            dayjs(dayjs(dt_time_fin, "HH:mm")).isAfter(dayjs("12:00", "HH:mm"))
        );

        function getAverageTime24Hours(hours: string[]): string {
          const totalMinutes = hours.reduce((total, time) => {
            const [hour, minute] = time.split(":").map(Number);
            return total + hour * 60 + minute;
          }, 0);

          const averageMinutes = Math.floor(totalMinutes / hours.length);

          const averageHours = Math.floor(averageMinutes / 60);
          const averageMinutesRemainder = averageMinutes % 60;
          const averageTime = `${averageHours
            .toString()
            .padStart(2, "0")}:${averageMinutesRemainder
            .toString()
            .padStart(2, "0")}`;

          return averageTime;
        }

        if (averageIniTimeGroup["true"] != null) {
          setAverageIniTimeHour(
            getAverageTime24Hours(
              averageIniTimeGroup["true"].map(val => {
                return val.dt_time_ini;
              })
            )
          );
        }

        if (averageFinTimeGroup["true"] != null) {
          setAverageFinTimeHour(
            getAverageTime24Hours(
              averageFinTimeGroup["true"].map(val => {
                return val.dt_time_fin;
              })
            )
          );
        }

        for (let i = 0, mes = 12; i < mes; i++) {
          if (monthGroup[i] != undefined) {
            meses[0].data[i] = Object.keys(monthGroup[i]).reduce(
              (horas, atividade: any) => {
                return horas + monthGroup[i][atividade].dt_time_dif;
              },
              0
            );
          }
        }
        for (let index = 1, cliente = lista.length; index <= cliente; index++) {
          if (companyGroup[index] !== undefined) {
            companyGroup[index].forEach(val => {
              if (
                (horaCliente.length === lista.length &&
                  horaCliente.length === index) ||
                (horaCliente.length === 1 && index === 1)
              ) {
                horaCliente.splice(
                  index - 1,
                  1,
                  horaCliente[index - 1] + val.dt_time_dif
                );
              } else {
                horaCliente.push(0);
                horaCliente.splice(
                  index - 1,
                  1,
                  horaCliente[index - 1] + val.dt_time_dif
                );
              }
            });
          } else {
            horaCliente.push(0);
          }
        }

        let quantidadeDias = 0;

        for (let i = 0, mes = 12; i < mes; i++) {
          if (scheduleMonthGroup[i] != undefined) {
            scheduleMonthGroup[i].forEach((val, index) => {
              const groupLength = scheduleMonthGroup[i].length;
              quantidadeDias =
                dayjs(val.dt_fin, "DD/MM/YYYY").diff(
                  dayjs(val.dt_ini, "DD/MM/YYYY"),
                  "day"
                ) +
                1 +
                quantidadeDias;
              if (groupLength === index + 1) {
                mesesSchedule.current[0].data[i] = quantidadeDias;
                quantidadeDias = 0;
              }
            });
          }
        }

        const porcentagens = mesesSchedule.current[0].data.map(value =>
          parseFloat(((value / 21) * 100).toFixed(2))
        );

        mesesSchedule.current[0].data = porcentagens;
        setPorcentagemSchedule(mesesSchedule);
        setHoraFinalCliente(horaCliente);
        setDataFinal(meses);
        setGraphReady(true);
        setData(response[1].data);
        setIsLoading(false);
        return response[1].data;
      })
      .catch(err => {
        /**
         * The catch block will be executed if the requests are not fulfilled.
         */
        console.log(err);
      });

  const [averageIniTimeHour, setAverageIniTimeHour] = useState("");
  const [averageFinTimeHour, setAverageFinTimeHour] = useState("");

  const dataFinalFiltrado = dataFinal[0].data.filter(val => val != 0);
  const ValorTotal = dataFinalFiltrado.reduce((sum, value) => sum + value, 0);
  const mesAtual =
    (ValorTotal / dataFinalFiltrado.length).toFixed(2) + " horas";

  useEffect(() => {
    setTimeout(() => {
      consultas();
    }, 100);
  }, [userContextId]);

  return (
    <Box
      w="100%"
      h={mobile ? "100svh" : "92.5svh"}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {isLoading ? (
        <Box
          w="30%"
          h="auto"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap="1em"
          position="fixed"
          zIndex={10}
        >
          <img
            src="../Images/Others/dashboardLine.gif"
            alt="foto"
            id="Dashboard-Image"
          />
          <img
            src="../Images/Others/dashboardBar.gif"
            alt="foto"
            id="Dashboard-Image"
          />
          <img
            src="../Images/Others/dashboardPie.gif"
            alt="foto"
            id="Dashboard-Image"
          />
        </Box>
      ) : (
        ""
      )}
      <Box
        w="100%"
        h="100%"
        overflow="scroll"
        overflowX="hidden"
        pl="20px"
        pr="20px"
        pt={{ base: "20px" }}
        sx={{
          "&::-webkit-scrollbar": {
            width: "0.3em",
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(255, 255, 255)",
            boxShadow: "rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px",
            borderRadius: "150px",
          },
        }}
      >
        <SimpleGrid
          columns={{ base: 1 }}
          mb="20px"
          sx={{ position: "sticky", top: "0px", zIndex: 2 }}
        >
          <Navbar
            userContextId={userContextId}
            setUserContextId={setUserContextId}
            consultas={consultas}
            setIsLoading={setIsLoading}
            onOpen={onOpen}
            logoText={"Horizon UI Dashboard PRO"}
            brandText={"Resumo do usuário"}
            secondary={false}
            message={"Colocar algo aqui"}
            fixed={false}
          />
        </SimpleGrid>
        {isLoading === false ? (
          <>
            <SimpleGrid
              columns={{ base: 1, md: 3, lg: 3 }}
              gap="20px"
              mb="20px"
            >
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    boxShadow="rgba(0, 0, 0, 0.5) 2.5px 2.5px 7.5px"
                    bg="rgb(82, 169, 167)"
                    icon={
                      <Icon
                        w="32px"
                        h="32px"
                        as={HourglassBottomIcon}
                        color={brandColor}
                        sx={{
                          transition: "0.5s",
                          "&:hover": { transform: "scale(1.15)" },
                        }}
                      />
                    }
                  />
                }
                name="Média de horas do mês"
                value={mesAtual}
              />

              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    boxShadow="rgba(0, 0, 0, 0.5) 2.5px 2.5px 7.5px"
                    bg="rgb(216, 178, 210)"
                    icon={
                      <Icon
                        w="32px"
                        h="32px"
                        as={HourglassEmptyIcon}
                        color={brandColor}
                        sx={{
                          transition: "0.5s",
                          "&:hover": { transform: "scale(1.15)" },
                        }}
                      />
                    }
                  />
                }
                name="Hora média de início"
                value={averageIniTimeHour}
              />

              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    boxShadow="rgba(0, 0, 0, 0.5) 2.5px 2.5px 7.5px"
                    bg="rgb(82, 169, 167)"
                    icon={
                      <Icon
                        w="32px"
                        h="32px"
                        as={HourglassFullIcon}
                        color={brandColor}
                        sx={{
                          transition: "0.5s",
                          "&:hover": { transform: "scale(1.15)" },
                        }}
                      />
                    }
                  />
                }
                name="Hora média de fim"
                value={averageFinTimeHour}
              />
            </SimpleGrid>
            <Grid
              gridTemplateColumns={{
                base: "66% auto",
                sm: "100%",
                md: "100%",
                xl: "66% auto",
              }}
              gridTemplateRows={{
                base: "60svh",
                sm: "45svh 50svh",
                md: "45svh 50svh",
                xl: "60svh",
              }}
              gap="20px"
              mb="20px"
            >
              <GraficoBarraTotalHoras
                graphReady={graphReady}
                dataFinal={dataFinal}
              />

              <GraficoPizzaHorasCliente
                graphReady={graphReady}
                horaFinalCliente={horaFinalCliente}
                clientes={lista}
              />
            </Grid>
            <SimpleGrid columns={{ base: 1 }} mb="20px">
              <GraficoLinhaPorcentagemAgendamento
                graphReady={graphReady}
                porcentagemSchedule={porcentagemSchedule}
              />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1 }} mb="20px">
              <TabelaUltimasAtividades tableData={data} />
            </SimpleGrid>
          </>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}

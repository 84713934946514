import React, { useCallback, useContext, useState } from "react";
import { CurrentUserContext } from "../../contexts/ContextProvider";
import {
  IconButton,
  Menu,
  MenuItem,
  Box,
  Avatar,
  Tooltip,
  Divider,
  ListItemIcon,
  createTheme,
  Drawer,
  Modal,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ThemeProvider } from "@emotion/react";
import "./Header.css";
import { Logout, PersonAdd } from "@mui/icons-material";
import { ProfileDrawer } from "./ProfileDrawer";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

type Anchor = "top";

export default function HeaderMobile() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickLogout = useCallback(() => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserId");
    localStorage.removeItem("permission");
    window.location.href = "/login";
  }, []);

  const handleClickHomepage = useCallback(() => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserId");
    localStorage.removeItem("permission");
    window.location.href = "/";
  }, []);

  const { currentUser } = useContext(CurrentUserContext);

  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <>
      <ProfileDrawer />
      <Divider />
      <Box sx={{ width: "100%" }}>
        <ThemeProvider theme={theme}>
          <IconButton onClick={toggleDrawer(anchor, false)} color="primary">
            <KeyboardArrowUpIcon fontSize="large" />
          </IconButton>
        </ThemeProvider>
      </Box>
    </>
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100vw",
        height: "7.5svh",
        flexDirection: "column",
      }}
    >
      <Box className="HeaderMobile">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {(["top"] as const).map(anchor => (
            <React.Fragment key={anchor}>
              <ThemeProvider theme={theme}>
                <Box
                  sx={{
                    width: "auto",
                    height: "100%",
                    backgroundColor: "white",
                    borderRadius: "0px 0px 15px 15px",
                  }}
                >
                  <IconButton
                    onClick={toggleDrawer(anchor, true)}
                    color="primary"
                    className="DrawerIcon"
                  >
                    <KeyboardArrowDownIcon fontSize="large" />
                  </IconButton>
                </Box>
                <div>
                  <Drawer
                    anchor={anchor}
                    variant="temporary"
                    open={state[anchor]}
                    className="Drawer"
                  >
                    {list(anchor)}
                  </Drawer>
                </div>
              </ThemeProvider>
            </React.Fragment>
          ))}
          <img
            src="../Images/Logos/Incortec_nome_brancos.png"
            alt="logo"
            id="Header-LogoImage"
          />
          <Box className="AvatarIcon">
            <ThemeProvider theme={theme}>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    src="../Images/Logos/Incortec_logo_branco.png"
                    sx={{
                      width: 40,
                      height: 40,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </ThemeProvider>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                maxWidth: "90vw",
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Box
              sx={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "0.5em 1em",
                gap: "0.5em",
              }}
            >
              <Avatar />
              <p id="Header-pName">{currentUser}</p>
            </Box>
            <Divider sx={{ margin: "8px 0px" }} />
            <MenuItem
              onClick={handleClickHomepage}
              sx={{ padding: "0.5em 1em" }}
            >
              <ListItemIcon>
                <HomeOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <p>Home page</p>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClickLogout} sx={{ padding: "0.5em 1em" }}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              <p>Sair</p>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
}

import React, { ReactNode, useContext, ReactElement, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { CurrentUserContext } from "./contexts/ContextProvider";

interface ProtectedRouteProps {
  children?: ReactNode;
}

export default function ProtectedRoute({
  children,
}: ProtectedRouteProps): ReactElement {
  const {
    setCurrentUserId,
    setCurrentUser,
    setCurrentUserPermission,
  } = useContext(CurrentUserContext);

  useEffect(() => {
    const loggedUser = localStorage.getItem("currentUser");
    const loggedUserId = localStorage.getItem("currentUserId")??"-1";
    const loggedUserPermission = localStorage.getItem("currentUserPermission") ?? "-1";
    if (loggedUser) {
      try {
        const foundUser = JSON.parse(loggedUser);
        setCurrentUser(foundUser);
      } catch {
        setCurrentUser(loggedUser);
      }
      try{
        const foundUserId = JSON.parse(loggedUserId);
        setCurrentUserId(parseInt(foundUserId?.toString(), 10)??-1);
      }
      catch {
        setCurrentUserId(parseInt(loggedUserId, 10)??-1);
      }
      try{
        const foundUserPermission = JSON.parse(loggedUserPermission);
        setCurrentUserPermission(
          foundUserPermission.split(",").map((item: string) => {
            return parseInt(item, 10);
          })
        );
      } catch {
        setCurrentUserPermission(loggedUserPermission.split(",").map((item: string) => {
          return parseInt(item, 10);
        }));
      }
    }
  },[]);
  const currentUserId = localStorage.getItem("currentUserId");
  if (currentUserId == null) {
    return <Navigate to="/Login" replace />;
  }
  const currentUser = localStorage.getItem("currentUser");
  if (currentUser == null || currentUser === "") {
    return <Navigate to="/Login" replace />;
  }

  return <>{children}</>;
}

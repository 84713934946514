// Chakra Imports
import {
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from "react";
import AdminNavbarLinks from "./NavbarLinksAdmin";
import UserInterface from "../../../../../types/UserInterface";
import { useQuery } from "react-query";
import axios from "axios";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { IoMdMoon, IoMdSunny } from "react-icons/io";

export default function AdminNavbar(props: {
  userContextId: any;
  setUserContextId: any;
  consultas: () => Promise<any>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  secondary: boolean;
  message: string | boolean;
  brandText: string;
  logoText: string;
  fixed: boolean;
  onOpen: (...args: any[]) => any;
}) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const { secondary, brandText, userContextId, setUserContextId, consultas, setIsLoading } = props;
  const [mobile] = useMediaQuery("(min-width: 901px)");
  const { colorMode, toggleColorMode } = useColorMode();

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  const navbarIcon = useColorModeValue("gray.400", "white");
  const mainText = useColorModeValue("navy.700", "white");
  const secondaryText = useColorModeValue("gray.700", "white");
  const navbarPosition = "sticky";
  const navbarFilter = "none";
  const navbarBackdrop = "blur(20px)";
  const navbarShadow = "none";
  const navbarBg = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgba(11,20,55,0.5)"
  );
  const navbarBorder = "transparent";
  const secondaryMargin = "0px";
  const paddingX = "15px";
  const gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  const menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");

  const permission = localStorage.getItem("currentUserPermission") ?? "-1";
  const currentPermission =
    permission.split(",").map(item => {
      return parseInt(item, 10);
    }) ?? 0;

  const handleUserCompany = useCallback((event: any) => {
    if (parseInt(event.target.value.toString()) == 0) {
      setUserContextId(
        parseInt(localStorage.getItem("currentUserId") ?? "-1") ?? -1
      );
      setIsLoading(true);
    } else {
      setUserContextId(parseInt(event.target.value.toString(), 10) ?? 0);
      setIsLoading(true);
    }
  }, []);

  const { data: repoUsers, refetch: userRefetch } = useQuery<UserInterface[]>(
    "User",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/user/list"
      );
      return response.data;
    },
    {
      staleTime: 1000 * 8,
    }
  );

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      display={secondary ? "block" : "flex"}
      minH="75px"
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      px={{
        sm: paddingX,
        md: "10px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      w={{
        base: "100%",
      }}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: "row",
          md: "row",
        }}
        alignItems={{ sm: "center", md: "center", xl: "center" }}
        justifyContent={{ base: "space-between" }}
        mb={gap}
      >
        <Box mb={{ sm: "0px", md: "0px" }}>
          {/* Here we create navbar brand, based on route name */}
          <Text
            color={mainText}
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            fontSize={mobile ? "34px" : "22px"}
          >
            {brandText}
          </Text>
        </Box>
        {mobile ? (
          <Box w={{ base: "auto" }}>
            <AdminNavbarLinks
              userContextId={userContextId}
              setUserContextId={setUserContextId}
              setIsLoading={setIsLoading}
              onOpen={props.onOpen}
              secondary={props.secondary}
              fixed={props.fixed}
            />
          </Box>
        ) : (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Button
              variant="no-hover"
              bg="transparent"
              p="0px"
              minW="unset"
              minH="unset"
              h="18px"
              w="max-content"
              onClick={toggleColorMode}
            >
              <Icon
                me="10px"
                h="18px"
                w="18px"
                color={navbarIcon}
                as={colorMode === "light" ? IoMdMoon : IoMdSunny}
              />
            </Button>
            <Menu>
              <MenuButton
                p="0px"
                sx={{
                  transition: "1.5s",
                  "&:hover": { transform: "rotateZ(359deg)" },
                }}
              >
                <Avatar
                  _hover={{ cursor: "pointer" }}
                  color="white"
                  bg="#11047A"
                  size="sm"
                  w="40px"
                  h="40px"
                  icon={<SettingsOutlinedIcon />}
                />
              </MenuButton>
              <MenuList
                boxShadow="rgba(0, 0, 0, 0.25) 2.5px 2.5px 10px"
                p="0px"
                mt="10px"
                borderRadius="20px"
                bg={menuBg}
                border="none"
              >
                <Flex w="100%" mb="0px">
                  <Text
                    pt="16px"
                    pb="10px"
                    w="100%"
                    borderBottom="1px solid"
                    borderColor={borderColor}
                    fontSize="sm"
                    fontWeight="700"
                    color={textColor}
                  >
                    Configurações
                  </Text>
                </Flex>
                <Flex flexDirection="column" p="10px">
                  <Flex
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    flexDirection="column"
                    alignItems="flex-start"
                    borderRadius="8px"
                    p="6px 14px"
                  >
                    <Text fontSize="sm">Usuário</Text>
                    <Select
                      sx={{ borderRadius: "8px" }}
                      onChange={handleUserCompany}
                      disabled={
                        !currentPermission.includes(1) ||
                        !currentPermission.includes(2)
                      }
                    >
                      <option selected key={0} value={0}>
                        Usuário Logado
                      </option>
                      {repoUsers?.map(item => {
                        return (
                          <option
                            key={item.nr_sequency}
                            value={item.nr_sequency}
                          >
                            {item.nm_person}
                          </option>
                        );
                      })}
                    </Select>
                  </Flex>
                </Flex>
              </MenuList>
            </Menu>
          </Box>
        )}
      </Flex>
    </Box>
  );
}

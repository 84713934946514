import React from "react";

interface Props {
    children: React.ReactNode;
}

export default function Layout({ children }: Props): JSX.Element {
  
  return (
    <div style={{height: "100%"}}>    
      <main style={{height: "100%"}}>
        {children}
      </main>   
    </div>
  );
}
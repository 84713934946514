import TextField from "@mui/material/TextField";
import moment from "moment";
import React, {
  useCallback,
  useState,
} from "react";
import {
  Button,
  Box,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
  Alert,
  Snackbar,
  IconButton,
  Modal,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from "react-query";
import UserInterface from "../../types/UserInterface";
import ProfileInterface from "../../types/ProfileInterface";
import "./User.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CompanyInterface from "../../types/CompanyInterface";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";

interface UserModalProps {
  userRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<UserInterface[]>>;
  handleCriarClose(): void;
  handleClickAdd: () => void;
}

export default function UserModal({
  userRefetch,
  handleCriarClose,
  handleClickAdd,
}: UserModalProps) {
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const [openPerfil, setOpenPerfil] = useState(false);

  const handlePerfil = useCallback((): void => {
    setOpenPerfil(true);
  }, []);

  const handlePerfilClose = useCallback((): void => {
    setOpenPerfil(false);
  }, []);

  const selectedCards = React.useRef<string[]>([]);

  const [UserNome, setUserNome] = useState("");
  const [UserEmail, setUserEmail] = useState("");
  const [UserSenha, setUserSenha] = useState("");
  const [UserCPF, setUserCPF] = useState("");
  const [UserPhone, setUserPhone] = useState("");
  const [UserCompany, setUserCompany] = useState(0);

  const [UserNomeError, setUserNomeError] = useState(false);
  const [UserEmailError, setUserEmailError] = useState(false);
  const [UserSenhaError, setUserSenhaError] = useState(false);
  const [UserCPFError, setUserCPFError] = useState(false);
  const [UserPhoneError, setUserPhoneError] = useState(false);
  const [UserProfileError, setUserProfileError] = useState(false);
  const [UserCompanyError, setUserCompanyError] = useState(false);
  const [dsMsgError, setDsMsgError] = useState("");

  const handleUserNome = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setUserNomeError(false);
      }
      setUserNome(event.target.value);
    },
    []
  );

  const handleUserEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setUserEmailError(false);
      }
      setUserEmail(event.target.value);
    },
    []
  );

  const handleUserSenha = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setUserSenhaError(false);
      }
      setUserSenha(event.target.value);
    },
    []
  );

  const handleUserCompany = useCallback((event: SelectChangeEvent<number>) => {
    if (event.target.value !== 0) {
      setUserCompanyError(false);
    }
    setUserCompany(parseInt(event.target.value.toString(), 10) ?? 0);
    if (UserCompany !== event.target.value) {
      selectedCards.current.length = 0;
    }
  }, []);

  const addUser = () => {
    const User = {
      nm_person: UserNome,
      ds_password: UserSenha,
      ds_token: "",
      nr_status: 1,
      ds_cpf: UserCPF,
      ds_email: UserEmail,
      ds_phone: UserPhone,
      profile: selectedCards.current.toString(),
      company: {
        nr_sequency: UserCompany,
      },
    };

    axios
      .post("https://api.incortec.com.br:8080/registrationservice/user/add", User)
      .then(() => {
        userRefetch();
        handleCriarClose();
        handleClickAdd();
      });
  };

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (UserNome === "") {
      setUserNomeError(true);
      setDsMsgError("Favor informar o nome do usuário");
      handleClick();
    } else if (UserEmail === "") {
      setUserEmailError(true);
      setDsMsgError("Favor informar o email");
      handleClick();
    } else if (UserSenha === "") {
      setUserSenhaError(true);
      setDsMsgError("Favor informar a senha");
      handleClick();
    } else if (UserCPF.match(/^\S{14}$/) === null) {
      setUserCPFError(true);
      setDsMsgError("Favor informar o CPF");
      handleClick();
    } else if (UserPhone.match(/^(\S{4})(\s{1})(\S{10})$/) === null) {
      setUserPhoneError(true);
      setDsMsgError("Favor informar o telefone");
      handleClick();
    } else if (UserCompany === 0) {
      setUserCompanyError(true);
      setDsMsgError("Favor informar o cliente");
      handleClick();
    } else if (selectedCards.current.length === 0) {
      setUserProfileError(true);
      setDsMsgError("Favor escolher ao menos 1 perfil");
      handleClick();
    } else {
      addUser();
    }
  }, [
    UserNome,
    UserEmail,
    UserSenha,
    UserCPF,
    UserPhone,
    UserCompany,
    selectedCards,
  ]);

  const maskCPF = (value: string) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+$/, "$1");
  };

  const maskPhone = (value: string) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+$/, "$1");
  };

  const { data: repoCompany } = useQuery<CompanyInterface[]>(
    "Company",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/company/list"
      );
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
      onKeyDown={event => {
        if (event.key === "Enter") {
          multiFunctions();
        }
      }}
    >
      <Box className="ModalUser-Main">
        <Box className="ModalUser-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalUser-pTitle">Inserir novo usuário</p>
            <IconButton onClick={handleCriarClose}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          <TextField
            error={UserNomeError}
            fullWidth
            onChange={handleUserNome}
            size="small"
            label={<p>Nome</p>}
            variant="standard"
          ></TextField>
          <TextField
            error={UserEmailError}
            fullWidth
            onChange={handleUserEmail}
            size="small"
            label={<p>Email</p>}
            variant="standard"
          ></TextField>
          <TextField
            error={UserSenhaError}
            fullWidth
            onChange={handleUserSenha}
            size="small"
            type="password"
            label={<p>Senha</p>}
            variant="standard"
          ></TextField>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "0.5em",
            }}
          >
            <TextField
              error={UserCPFError}
              fullWidth
              value={UserCPF}
              onChange={useCallback(
                (e: { target: { value: string } }) => {
                  if (UserCPF !== "") {
                    setUserCPFError(false);
                  }
                  setUserCPF(maskCPF(e.target.value));
                },
                [UserCPF]
              )}
              size="small"
              label={<p>CPF</p>}
              variant="standard"
            ></TextField>
            <TextField
              fullWidth
              error={UserPhoneError}
              value={UserPhone}
              onChange={useCallback(
                (e: { target: { value: string } }) => {
                  if (UserPhone !== "") {
                    setUserPhoneError(false);
                  }
                  setUserPhone(maskPhone(e.target.value));
                },
                [UserPhone]
              )}
              size="small"
              label={<p>Telefone</p>}
              variant="standard"
            ></TextField>
          </Box>
          <FormControl fullWidth sx={{ textAlign: "left" }}>
            <InputLabel>{<p>Cliente</p>}</InputLabel>
            <Select
              error={UserCompanyError}
              label={<p>Cliente</p>}
              onChange={handleUserCompany}
              fullWidth
              color="primary"
              variant="standard"
            >
              {repoCompany?.map(val => {
                return (
                  <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                    {val.nm_company}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            disabled={UserCompany === 0}
            onClick={handlePerfil}
            size="small"
            disableRipple
            variant="outlined"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
              color: UserProfileError ? "red" : blue,
              borderColor: UserProfileError ? "red" : blue,
              "&:hover": { backgroundColor: blue, color: "white" },
              borderRadius: "10px",
              textTransform: "none",
              padding: "0.25em 5em",
            }}
            fullWidth
          >
            <PostAddOutlinedIcon />
            <p id="ModalHomePageNovidades-pButton">Adicionar perfil</p>
          </Button>
          <Modal open={openPerfil} onClose={handlePerfilClose}>
            <PerfilModal
              handlePerfilClose={handlePerfilClose}
              selectedCards={selectedCards}
              UserCompany={UserCompany}
            />
          </Modal>
          <Button
            size="small"
            onClick={multiFunctions}
            disableRipple
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: blue,
              "&:hover": { backgroundColor: "white", color: blue },
              borderRadius: "10px",
              boxShadow: 5,
              textTransform: "none",
              padding: "0.25em 5em",
            }}
          >
            <p id="ModalUser-pButton">Inserir usuário</p>
          </Button>
        </Box>
        <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
          <Alert severity="error" variant="filled">
            {dsMsgError}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

function PerfilModal(props: {
  handlePerfilClose: () => void;
  selectedCards: React.MutableRefObject<string[]>;
  UserCompany: number;
}) {
  const {
    handlePerfilClose,
    selectedCards,
    UserCompany,
  } = props;
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const blue = "rgba(53, 68, 145, 1)";

  const handleCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        selectedCards.current = [...selectedCards.current, e.target.value];
      } else {
        const buscar = e.target.value;
        let indice = selectedCards.current.indexOf(buscar);
        while (indice >= 0) {
          selectedCards.current.splice(indice, 1);
          indice = selectedCards.current.indexOf(buscar);
        }
      }
    },
    [selectedCards]
  );

  const { data: repoProfile } = useQuery<ProfileInterface[]>(
    "profile",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/profileuser/list"
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
    >
      <Box className="ModalHomePageNovidades-Main">
        <Box className="ModalHomePageNovidades-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalHomePageNovidades-pTitle">Perfil</p>
            <IconButton onClick={handlePerfilClose}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          {repoProfile?.map(val => {
            if (val.company.nr_sequency === UserCompany) {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    gap: "0.5em",
                  }}
                  key={val.nr_sequency}
                >
                  <Checkbox
                    value={val.nr_sequency}
                    onChange={handleCheckbox}
                    defaultChecked={selectedCards.current.includes(
                      val.nr_sequency.toString()
                    )}
                  />
                  <p>{val.ds_profile}</p>
                </Box>
              );
            } else {
              return;
            }
          })}
          <Button
            size="small"
            onClick={handlePerfilClose}
            disableRipple
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: blue,
              "&:hover": { backgroundColor: "white", color: blue },
              borderRadius: "10px",
              boxShadow: 5,
              textTransform: "none",
              padding: "0.25em 5em",
            }}
          >
            <p id="ModalUser-pButton">Voltar</p>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

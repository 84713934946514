import React, { useRef, useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Text,
  Select,
  useColorModeValue,
  Button,
  Icon,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
// Custom components
import Card from "../../../Components/card/Card";
import PieChart from "../../../Components/charts/PieChart";
import { VSeparator } from "../../../Components/separator/Separator";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ActivityInterface from "../../../../../types/ActivityInterface";
import axios from "axios";
import _, { rest } from "lodash";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import CompanyInterface from "../../../../../types/CompanyInterface";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import { lineChartDataTotalSpent, lineChartOptionsTotalSpent } from "../charts";
import LineChart from "../../../Components/charts/LineChart";

interface pieCardInterface {
  graphReady: boolean;
  horaFinalCliente: number[];
  clientes: React.MutableRefObject<string[]>;
}

export default function GraficoPizzaProcedimento({
  graphReady,
  horaFinalCliente,
  clientes,
}: pieCardInterface) {
  const ValorTotal = horaFinalCliente.reduce((sum, value) => sum + value, 0);
  const porcentagens = horaFinalCliente.map(value =>
    ((value / ValorTotal) * 100).toFixed(2)
  );
  const grupo = clientes.current.map((val, index) => {
    return { cliente: val, porcentagem: porcentagens[index] };
  });

  const sortGrupo = grupo.sort(
    (a, b) => parseFloat(b.porcentagem) - parseFloat(a.porcentagem)
  );

  const pieChartOptions: any = {
    labels: clientes.current,
    colors: [
      "rgb(216, 178, 210)",
      "rgb(176, 151, 194)",
      "rgb(135, 123, 178)",
      "rgb(94, 96, 162)",
      "rgb(53, 68, 145)",
      "rgb(61, 94, 151)",
      "rgb(68, 119, 156)",
      "rgb(75, 144, 162)",
      "rgb(82, 169, 167)",
    ],
    chart: {
      width: "50px",
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 1,
        left: 2,
        blur: 4,
        color: "#000000",
        opacity: 0.15,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: true,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: [
        "rgb(216, 178, 210)",
        "rgb(176, 151, 194)",
        "rgb(135, 123, 178)",
        "rgb(94, 96, 162)",
        "rgb(53, 68, 145)",
        "rgb(61, 94, 151)",
        "rgb(68, 119, 156)",
        "rgb(75, 144, 162)",
        "rgb(82, 169, 167)",
      ],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const bgList = useColorModeValue("white", "whiteAlpha.100");

  const iconColor = useColorModeValue("brand.500", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const colorArray: string[] = [
    "rgb(216, 178, 210)",
    "rgb(135, 123, 178)",
    "rgb(53, 68, 145)",
    "rgb(68, 119, 156)",
    "rgb(82, 169, 167)",
  ];

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const [index, setIndex] = useState(0);
  return (
    <Card
      p="20px"
      alignItems="center"
      flexDirection="column"
      w="auto"
      boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
    >
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Procedimento
        </Text>
        <Menu isOpen={isOpen1} onClose={onClose1}>
          <MenuButton
            alignItems="center"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            w="37px"
            h="37px"
            lineHeight="100%"
            borderRadius="10px"
            onClick={onOpen1}
            {...rest}
            boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
          >
            <Icon
              as={MoreVertOutlinedIcon}
              color={iconColor}
              w="24px"
              h="24px"
            />
          </MenuButton>
          <MenuList
            w="150px"
            minW="unset"
            maxW="150px !important"
            border="transparent"
            backdropFilter="blur(63px)"
            bg={bgList}
            boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
            borderRadius="20px"
            p="15px"
          >
            <MenuItem
              transition="0.2s linear"
              color={textColor}
              _hover={textHover}
              p="0px"
              borderRadius="8px"
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              mb="10px"
              onClick={onOpen2}
            >
              <Flex alignItems="center" flexDirection="row">
                <Icon as={ZoomOutMapOutlinedIcon} h="16px" w="16px" me="8px" />
                <Text fontSize="sm" fontWeight="400">
                  Maximizar
                </Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Drawer isOpen={isOpen2} onClose={onClose2}>
        <DrawerOverlay />
        <DrawerContent
          minH="100svh"
          maxH="100svh"
          minW="95svw"
          maxW="95svw"
          p="15px"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <p>Procedimento</p>
            <Menu isOpen={isOpen1} onClose={onClose1}>
              <MenuButton
                alignItems="center"
                justifyContent="center"
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w="37px"
                h="37px"
                lineHeight="100%"
                borderRadius="10px"
                onClick={onOpen1}
                {...rest}
                boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
              >
                <Icon
                  as={MoreVertOutlinedIcon}
                  color={iconColor}
                  w="24px"
                  h="24px"
                />
              </MenuButton>
              <MenuList
                w="150px"
                minW="unset"
                maxW="150px !important"
                border="transparent"
                backdropFilter="blur(63px)"
                bg={bgList}
                boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                borderRadius="20px"
                p="15px"
              >
                <MenuItem
                  transition="0.2s linear"
                  color={textColor}
                  _hover={textHover}
                  p="0px"
                  borderRadius="8px"
                  _active={{
                    bg: "transparent",
                  }}
                  _focus={{
                    bg: "transparent",
                  }}
                  mb="10px"
                  onClick={onClose2}
                >
                  <Flex alignItems="center" flexDirection="row">
                    <Icon
                      as={ZoomInMapOutlinedIcon}
                      h="16px"
                      w="16px"
                      me="8px"
                    />
                    <Text fontSize="sm" fontWeight="400">
                      Minimizar
                    </Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
          <Box h="100%" mt="auto" w="100%">
            {graphReady && (
              <PieChart
                h="100%"
                w="100%"
                chartData={horaFinalCliente}
                chartOptions={pieChartOptions}
              />
            )}
          </Box>
          <Card
            bg={cardColor}
            flexDirection="row"
            boxShadow={cardShadow}
            justifyContent="space-evenly"
            w="100%"
            p="15px"
            px="10px"
            mx="auto"
          >
            {sortGrupo.map((val, i) => {
              const color = colorArray[i];
              return (
                <>
                  <Flex direction="column" py="5px" key={i}>
                    <Flex align="center">
                      <Box
                        h="8px"
                        w="8px"
                        sx={{ backgroundColor: color }}
                        borderRadius="50%"
                        me="4px"
                      />
                      <Text
                        fontSize="xs"
                        color="secondaryGray.600"
                        fontWeight="700"
                        mb="5px"
                      >
                        {val.cliente}
                      </Text>
                    </Flex>
                    <Text fontSize="lg" color={textColor} fontWeight="700">
                      {val.porcentagem}%
                    </Text>
                  </Flex>
                  <VSeparator
                    mx={{ base: "20px", xl: "20px", "2xl": "20px" }}
                  />
                </>
              );
            })}
          </Card>
        </DrawerContent>
      </Drawer>
      <Box h="100%" mt="auto" w="100%">
        {graphReady && (
          <PieChart
            h="100%"
            w="100%"
            chartData={horaFinalCliente}
            chartOptions={pieChartOptions}
          />
        )}
      </Box>
      <Card
        bg={cardColor}
        flexDirection="row"
        boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
        w="100%"
        p="10px"
        px="20px"
        mx="auto"
      >
        {sortGrupo.map((val, i) => {
          const color = colorArray[i];
          return (
            <>
              <Flex direction="column" py="5px" key={i}>
                <Flex align="center">
                  <Box
                    h="8px"
                    w="8px"
                    sx={{ backgroundColor: color }}
                    borderRadius="50%"
                    me="4px"
                  />
                  <Text
                    fontSize="xs"
                    color="secondaryGray.600"
                    fontWeight="700"
                    mb="5px"
                  >
                    {val.cliente}
                  </Text>
                </Flex>
                <Text fontSize="lg" color={textColor} fontWeight="700">
                  {val.porcentagem}%
                </Text>
              </Flex>
              <VSeparator mx={{ base: "20px", xl: "20px", "2xl": "20px" }} />
            </>
          );
        })}
      </Card>
    </Card>
  );
}

import {
  Alert,
  Box,
  Button,
  createTheme,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useContext, useState } from "react";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
  useQuery,
} from "react-query";
import "./Schedule.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CompanyInterface from "../../types/CompanyInterface";
import ScheduleInterface from "../../types/ScheduleInterface";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { CurrentUserContext } from "../../contexts/ContextProvider";

export default function ScheduleEditModal(props: {
  row: ScheduleInterface;
  handleCloseEdit: () => void;
  ScheduleRefetch: (
    ScheduleMonthPicker: DateObject,
    ScheduleUser: number
  ) => Promise<any>;
  handleClickUpdate: () => void;
  setSemRegistros: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const days = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  const theme = createTheme({
    palette: {
      primary: {
        main: "rgba(53, 68, 145, 1)",
      },
      secondary: {
        main: "#ffffff",
      },
    },
  });

  const {
    row,
    handleCloseEdit,
    ScheduleRefetch,
    handleClickUpdate,
    setSemRegistros,
    setIsLoading,
  } = props;
  const mobile = useMediaQuery("(min-width: 901px)");
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const { currentUserId } = useContext(CurrentUserContext);

  const [ScheduleID] = useState(row.id);
  const [ScheduleDescription, setScheduleDescription] = useState(
    row.ds_schedule
  );
  const [ScheduleCompany, setScheduleCompany] = useState(
    row.company.nr_sequency
  );

  const dateIni = new DateObject({
    date: row.dt_ini,
    format: "DD/MM/YYYY",
  });
  const dateFin = new DateObject({
    date: row.dt_fin,
    format: "DD/MM/YYYY",
  });
  const [scheduleDate, setScheduleDate] = useState([dateIni, dateFin]);

  const [ScheduleError, setScheduleError] = useState(0);
  const [dsMsgError, setDsMsgError] = useState("");

  const handleScheduleDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setScheduleError(1);
      }
      setScheduleDescription(event.target.value);
    },
    []
  );

  const handleCliente = useCallback((event: SelectChangeEvent<number>) => {
    if (event.target.value !== 0) {
      setScheduleError(2);
    }
    setScheduleCompany(parseInt(event.target.value.toString(), 10) ?? 0);
  }, []);

  function handleDateChange(value: DateObject[]) {
    setScheduleDate(value);
  }

  const updateSchedule = () => {
    const Schedule = {
      id: ScheduleID,
      ds_schedule: ScheduleDescription,
      dt_ini: scheduleDate[0].format("DD/MM/YYYY"),
      dt_fin: scheduleDate[1].format("DD/MM/YYYY"),
      user: {
        nr_sequency: currentUserId,
      },
      company: {
        nr_sequency: ScheduleCompany,
      },
    };

    axios
      .post("https://api.incortec.com.br:8080/registrationservice/schedule/add", Schedule)
      .then(() => {
        handleClickUpdate();
        ScheduleRefetch(scheduleDate[0], currentUserId!);
        setIsLoading(true);
        setSemRegistros(true);
        handleCloseEdit();
      });
  };

  const { data: repoClientes } = useQuery<CompanyInterface[]>(
    "company",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/company/list"
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}
        onKeyDown={event => {
          if (event.key === "Enter") {
            updateSchedule();
          }
        }}
      >
        <Box className="ModalSchedule-Main">
          <Box className="ModalSchedule-Wrapper">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p id="ModalSchedule-pTitle">Inserir novo agendamento</p>
              <IconButton onClick={handleCloseEdit}>
                <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: mobile ? "row" : "column",
                alignItems: "center",
                width: "100%",
                gap: "1em",
              }}
            >
              <DatePicker
                months={months}
                weekDays={days}
                className="rmdp-container blue"
                value={scheduleDate}
                onChange={handleDateChange}
                range
                mapDays={({ date }) => {
                  const isWeekend = [0, 6].includes(date.weekDay.index);
                  if (isWeekend)
                    return {
                      disabled: true,
                    };
                }}
                format="DD/MM/YYYY"
                render={
                  <TextField
                    label={<p>Tempo da jornada</p>}
                    variant="standard"
                    color="primary"
                    fullWidth
                  />
                }
              />
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <InputLabel>
                  <p>Cliente</p>
                </InputLabel>
                <Select
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    textAlign: "left",
                  }}
                  error={ScheduleError === 2}
                  fullWidth
                  size="small"
                  color="primary"
                  variant="standard"
                  defaultValue={ScheduleCompany}
                  onChange={handleCliente}
                >
                  {repoClientes?.map(val => {
                    return (
                      <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                        {val.nm_company}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <TextField
              size="small"
              color="primary"
              variant="standard"
              multiline
              label={<p>Descrição</p>}
              maxRows={2}
              rows={2}
              fullWidth
              defaultValue={ScheduleDescription}
              error={ScheduleError === 1}
              onChange={handleScheduleDescription}
            />
            <ThemeProvider theme={theme}>
              <Button
                size="small"
                onClick={updateSchedule}
                disableRipple
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: "10px",
                  boxShadow: 5,
                  textTransform: "none",
                  padding: "0.25em 5em",
                }}
              >
                <p id="ModalSchedule-pButton">Inserir agendamento</p>
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </Box>
      <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
        <Alert severity="error" variant="filled">
          {dsMsgError}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

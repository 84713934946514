import React, { useCallback, useContext, useState } from "react";
import "./Login.css";
import {
  Button,
  Box,
  TextField,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { createTheme, styled } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "../../contexts/ContextProvider";
import axios from "axios";
import shajs from "sha.js";

export default function Login(): JSX.Element {
  const Users = {
    Login: "Adm",
    Password: "1234",
    Permission: 1,
    Company: "Administrador",
  };
  const blue = "rgba(53, 68, 145, 1)";
  const [isLoading, setIsLoading] = useState(false);

  const [LoginText, setLoginText] = useState<string>("");
  const [Password, setPassword] = useState<string>("");
  const { setCurrentUser, setCurrentUserId, setCurrentUserPermission } =
    useContext(CurrentUserContext);
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickDelete = useCallback(() => {
    setOpenDelete(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenDelete(false);
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "rgba(53, 68, 145, 1)",
      },
      secondary: {
        main: "#ffffff",
      },
    },
  });

  const LoginButton = styled(Button)({
    textTransform: "none",
    color: "white",
    borderRadius: "15px",
    fontSize: "20px",
    fontWeight: "600",
    fontFamily: "Trebuchet MS, sans-serif",
  });

  const handleLoginText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLoginText(e.target.value);
    },
    [LoginText, setLoginText]
  );

  const handlePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    [Password, setPassword]
  );

  const handleButtonInput = useCallback(async () => {
    setIsLoading(true);

    const Login = {
      username: LoginText,
      password: shajs("sha256").update(Password).digest("hex"),
    };

    axios
      .post("https://api.incortec.com.br:8080/registrationservice/login", Login)
      .then(response => {
        const userFound = response.data;
        if (Users.Password === Password && Users.Login === LoginText) {
          setCurrentUser("Admin");
          setCurrentUserId(0);
          setCurrentUserPermission([1]);
          localStorage.setItem("currentUser", "Admin");
          localStorage.setItem("currentUserId", "0");
          localStorage.setItem("currentUserPermission", "1");
          setIsLoading(false);
          navigate("/Portal/Activity");
        } else if (response.data === "") {
          setIsLoading(false);
          handleClickDelete();
        } else {
          setCurrentUser(userFound.nm_person ?? null);
          setCurrentUserId(userFound.nr_sequency ?? -1);
          setCurrentUserPermission(
            userFound.profile.split(",").map((item: string) => {
              return parseInt(item, 10);
            }) ?? 0
          );
          localStorage.setItem("currentUser", userFound.nm_person ?? "");
          localStorage.setItem(
            "currentUserId",
            userFound.nr_sequency.toString() ?? "-1"
          );
          localStorage.setItem(
            "currentUserPermission",
            userFound.profile ?? "0"
          );
          setIsLoading(false);
          navigate("/Portal/Activity");
        }
      });
  }, [LoginText, Password]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
          onKeyDown={event => {
            if (event.key === "Enter") {
              handleButtonInput();
            }
          }}
        >
          {isLoading ? (
            <Box className="LoadingBox">
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <Box className="ModalLogin-Main">
              <Box className="ModalLogin-Wrapper">
                <img
                  src="Images/Logos/LogoIncortecDegrade.png"
                  alt="placeholder"
                  id="Image-login"
                />
                <TextField
                  fullWidth
                  variant="standard"
                  label={<p>Login</p>}
                  type="text"
                  color="primary"
                  onChange={handleLoginText}
                />
                <TextField
                  fullWidth
                  variant="standard"
                  label={<p>Password</p>}
                  type="password"
                  color="primary"
                  onChange={handlePassword}
                />
                <LoginButton
                  id="ModalLogin-Button"
                  variant="contained"
                  disableRipple
                  size="small"
                  onClick={handleButtonInput}
                  sx={{
                    color: "white",
                    backgroundColor: blue,
                    "&:hover": { backgroundColor: "white", color: blue },
                    borderRadius: 2,
                    boxShadow: 5,
                    gap: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p id="ModalLogin-pButton">Login</p>
                </LoginButton>
              </Box>
            </Box>
          )}
          <Snackbar
            open={openDelete}
            onClick={handleClose}
            onClose={handleClose}
            autoHideDuration={3000}
          >
            <Alert severity="error" variant="filled">
              Usuário e/ou senha incorreto
            </Alert>
          </Snackbar>
        </Box>
      </ThemeProvider>
    </>
  );
}

import { Logout } from "@mui/icons-material";
import {
  Avatar,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import React, { useCallback, useContext } from "react";
import { CurrentUserContext } from "../../contexts/ContextProvider";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useProSidebar } from "react-pro-sidebar";

export default function SidebarMenu() {
  const { collapsed } = useProSidebar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickLogout = useCallback(() => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserId");
    localStorage.removeItem("permission");
    window.location.href = "/login";
  }, []);

  const handleClickHomepage = useCallback(() => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserId");
    localStorage.removeItem("permission");
    window.location.href = "/";
  }, []);

  const { currentUser } = useContext(CurrentUserContext);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "auto",
        }}
      >
        {collapsed ? (
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={{
              padding: "0.5em",
              "&:hover": {
                boxShadow: "rgba(0, 0, 0, 0.5) 2.5px 2.5px 10px",
              },
            }}
          >
            <Avatar
              src="../Images/Logos/Incortec_logo_branco.png"
              sx={{
                width: 40,
                height: 40,
              }}
            />
          </IconButton>
        ) : (
          <Button
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={{
              padding: "0.5em",
              width: "100%",
              borderRadius: 0,
              "&:hover": {
                boxShadow: "rgba(0, 0, 0, 0.5) 2.5px 2.5px 10px",
              },
            }}
          >
            <img
              src="../Images/Logos/Incortec_nome_brancos.png"
              alt="logo"
              id="Sidebar-Image"
            />
          </Button>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: "22.5vw",
            maxWidth: "22.5vw",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Box
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0.5em 1em",
            gap: "0.5em",
          }}
        >
          <Avatar />
          <p id="Header-pName">{currentUser}</p>
        </Box>
        <Divider sx={{ margin: "8px 0px" }} />
        <MenuItem onClick={handleClickHomepage} sx={{ padding: "0.5em 1em" }}>
          <ListItemIcon>
            <HomeOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <p>Home page</p>
        </MenuItem>
        <MenuItem onClick={handleClickLogout} sx={{ padding: "0.5em 1em" }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <p>Sair</p>
        </MenuItem>
      </Menu>
    </>
  );
}

import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  useMediaQuery,
} from "@mui/material";
import React, { SyntheticEvent } from "react";
import "./Navbar.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu, MenuItem, ProSidebarProvider } from "react-pro-sidebar";
import { useHref } from "react-router-dom";

type Anchor = "top";

export default function Navbar() {
  const mobile = useMediaQuery("(min-width: 901px)");

  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "auto",
        borderRadius: "0px 0px 2px 2px",
      }}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Button
        sx={{ width: "100%", height: "10svh", color: "rgba(53, 68, 145, 1)" }}
        disableRipple
        href="#About"
      >
        <p id="Navbar-pButton">SOBRE</p>
      </Button>
      <Button
        sx={{ width: "100%", height: "10svh", color: "rgba(53, 68, 145, 1)" }}
        disableRipple
        href="#Solutions"
      >
        <p id="Navbar-pButton">SOLUÇÕES</p>
      </Button>
      <Button
        sx={{ width: "100%", height: "10svh", color: "rgba(53, 68, 145, 1)" }}
        disableRipple
        href="#News"
      >
        <p id="Navbar-pButton">NOVIDADES</p>
      </Button>
      <Button
        sx={{ width: "100%", height: "10svh", color: "rgba(53, 68, 145, 1)" }}
        disableRipple
        href="#Contact"
      >
        <p id="Navbar-pButton">CONTATO</p>
      </Button>
      <Button
        className="Navbar-LoginButton"
        sx={{ width: "100%", height: "10svh", color: "white" }}
        disableRipple
        href="/Login"
      >
        <p id="Navbar-pLoginButton">LOGIN DO PORTAL</p>
      </Button>
    </Box>
  );

  return (
    <>
      {mobile ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "20% 60% 20%",
            gridTemplateRows: "100%",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            minHeight: "9svh",
            maxHeight: "9svh",
            backgroundColor: "white",
            position: "fixed",
            zIndex: 100,
            padding: "8px",
            boxShadow:
              "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
          }}
        >
          <IconButton href="#Top" sx={{ justifySelf: "flex-start" }}>
            <Avatar
              src="../Images/Logos/Incortec_logo_colorido.png"
              sx={{
                width: 35,
                height: 35,
              }}
            />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "2.5%",
              height: "100%",
            }}
          >
            <Button
              className="Navbar-Button"
              sx={{
                color: "rgba(53, 68, 145, 1)",
                "&:hover": {
                  backgroundColor: "rgb(215, 215, 215)",
                },
              }}
              disableRipple
              href="#About"
            >
              <p id="Navbar-pButton">SOBRE</p>
            </Button>
            <Button
              className="Navbar-Button"
              sx={{
                color: "rgba(53, 68, 145, 1)",
                "&:hover": {
                  backgroundColor: "rgb(215, 215, 215)",
                },
              }}
              disableRipple
              href="#Solutions"
            >
              <p id="Navbar-pButton">SOLUÇÕES</p>
            </Button>
            <Button
              className="Navbar-Button"
              sx={{
                color: "rgba(53, 68, 145, 1)",
                "&:hover": {
                  backgroundColor: "rgb(215, 215, 215)",
                },
              }}
              disableRipple
              href="#News"
            >
              <p id="Navbar-pButton">NOVIDADES</p>
            </Button>
            <Button
              className="Navbar-Button"
              sx={{
                color: "rgba(53, 68, 145, 1)",
                "&:hover": {
                  backgroundColor: "rgb(215, 215, 215)",
                },
              }}
              disableRipple
              href="#Newsletter"
            >
              <p id="Navbar-pButton">CONTATO</p>
            </Button>
          </Box>
          <Button
            className="Navbar-Button"
            sx={{
              color: "rgba(53, 68, 145, 1)",
              "&:hover": {
                backgroundColor: "rgb(215, 215, 215)",
              },
              justifySelf: "flex-end",
            }}
            disableRipple
            href="/Login"
          >
            <p id="Navbar-pButton">PORTAL</p>
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            minHeight: "10svh",
            maxHeight: "10svh",
            position: "fixed",
            zIndex: 100,
          }}
        >
          {(["top"] as const).map(anchor => (
            <React.Fragment key={anchor}>
              <Box
                sx={{
                  width: "auto",
                  height: "100%",
                  backgroundColor: "white",
                  boxShadow:
                    "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
                  borderRadius: "2px",
                }}
              >
                <Button
                  className="Navbar-Button"
                  disableRipple
                  sx={{ color: "rgba(53, 68, 145, 1)" }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <KeyboardArrowDownIcon fontSize="large" />
                </Button>
              </Box>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </Box>
      )}
    </>
  );
}

import React, {
  SyntheticEvent,
  useCallback,
  useReducer,
  useState,
} from "react";
import Navbar from "./Navbar/Navbar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  SelectChangeEvent,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeIcon from "@mui/icons-material/Mode";
import axios from "axios";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from "react-query";
import ProfileUserEditModal from "./ProfileUserEditModal";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import ProfileInterface from "../../types/ProfileInterface";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function CollapsibleTable() {
  const mobile = useMediaQuery("(min-width: 901px)");
  const [idSelecionado, setIdSelecionado] = useState<number>(0);
  const [showQuery, setShowQuery] = useState<string>("");
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const [FilterCompany, setFilterCompany] = useState(0);

  const handleFilterCompany = useCallback(
    (event: SelectChangeEvent<number>) => {
      setFilterCompany(parseInt(event.target.value.toString(), 10) ?? 0);
    },
    []
  );

  const handleShowQueryState = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setShowQuery(e.target.value);
    },
    []
  );

  const [semRegistros, setSemRegistros] = useState(true);

  const { data, refetch: profileUserRefetch } = useQuery<ProfileInterface[]>(
    "ProfileUser",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/profileuser/list"
      );
      setSemRegistros(false);
      return response.data;
    },
    {
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  const textoPesquisa = showQuery ? showQuery : "";

  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const handleClickAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleClickDelete = useCallback(() => {
    setOpenDelete(true);
  }, []);

  const handleClickUpdate = useCallback(() => {
    setOpenUpdate(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenAdd(false);
    setOpenDelete(false);
    setOpenUpdate(false);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: mobile ? "100svh" : "92.5svh",
          padding: "0.5em",
          gap: "1em",
        }}
      >
        <Navbar
          handleShowQueryState={handleShowQueryState}
          profileUserRefetch={profileUserRefetch}
          handleClickAdd={handleClickAdd}
          handleFilterCompany={handleFilterCompany}
        />
        <Box className="ProfileUser-Main">
          {semRegistros ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress sx={{ color: "white" }} size="3em" />
            </Box>
          ) : (
            <Box className="ProfileUser-Wrapper">
              {data?.map(val => {
                if (
                  val.nm_canal
                    .toLowerCase()
                    .includes(textoPesquisa.toLowerCase())
                ) {
                  if (val.company.nr_sequency === FilterCompany) {
                    return (
                      <Row
                        key={val.nr_sequency}
                        idSelecionado={idSelecionado}
                        setIdSelecionado={setIdSelecionado}
                        row={val}
                        profileUserRefetch={profileUserRefetch}
                        handleClickDelete={handleClickDelete}
                        handleClickUpdate={handleClickUpdate}
                      />
                    );
                  } else if (FilterCompany === 0) {
                    return (
                      <Row
                        key={val.nr_sequency}
                        idSelecionado={idSelecionado}
                        setIdSelecionado={setIdSelecionado}
                        row={val}
                        profileUserRefetch={profileUserRefetch}
                        handleClickDelete={handleClickDelete}
                        handleClickUpdate={handleClickUpdate}
                      />
                    );
                  } else {
                    return;
                  }
                }
                return "";
              })}
            </Box>
          )}
        </Box>
      </Box>
      <Snackbar
        open={openAdd}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity="success" variant="filled">
          Perfil adicionado com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openDelete}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<DeleteOutlineOutlinedIcon />}
          severity="success"
          variant="filled"
          color="error"
        >
          Perfil excluído com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openUpdate}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<TaskOutlinedIcon />}
          severity="success"
          variant="filled"
          color="info"
        >
          Perfil atualizado com sucesso
        </Alert>
      </Snackbar>
    </>
  );
}

function Row(props: {
  idSelecionado: number;
  setIdSelecionado: React.Dispatch<React.SetStateAction<number>>;
  row: ProfileInterface;
  handleClickDelete: () => void;
  handleClickUpdate: () => void;
  profileUserRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ProfileInterface[]>>;
}) {
  const {
    idSelecionado,
    setIdSelecionado,
    row,
    profileUserRefetch,
    handleClickDelete,
    handleClickUpdate,
  } = props;
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [openModal, setOpenModal] = useState(false);
  const blue = "rgba(53, 68, 145, 1)";

  const handleRowClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    const idAntigo = idSelecionado;
    const idNovo = row.nr_sequency;
    if (idNovo === idAntigo) {
      setIdSelecionado(0);
    } else {
      setIdSelecionado(idNovo);
    }
  }, []);

  const handleDelete = useCallback(() => {
    fetch(
      "https://api.incortec.com.br:8080/registrationservice/profileuser/remove/" +
        row.nr_sequency,
      {
        method: "delete",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(retorno => retorno.json())
      .then(() => {
        forceUpdate();
        profileUserRefetch();
        handleClickDelete();
      });
  }, [row.nr_sequency]);

  const handleCloseEdit = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenEdit = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setOpenModal(true);
  }, []);

  return (
    <>
      <Accordion
        key={row.nr_sequency}
        sx={{
          width: "100%",
          borderRadius: "10px 10px 10px 10px !important",
          boxShadow: 10,
          backgroundColor: "white",
        }}
        disableGutters
        onClick={handleRowClick}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: blue }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            boxShadow: "0px 0px 15px -5px rgba(0,0,0,0.75)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "auto",
              gap: "0.5em",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                textAlign: "left",
                color: blue,
              }}
            >
              <p id="ProfileUser-pTitle">Nome</p>
              <p id="ProfileUser-pName">{row.nm_canal}</p>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "auto",
              gap: "0.75vw",
              color: blue,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                textAlign: "left",
                marginRight: "auto",
                color: blue,
              }}
            >
              <p id="ProfileUser-pTitle">Descrição</p>
              <p id="ProfileUser-pDesc">{row.ds_profile}</p>
            </Box>
            <IconButton size="small">
              <ModeIcon
                sx={{
                  color: blue,
                }}
                onClick={handleOpenEdit}
              />
              <Modal open={openModal} onClose={handleCloseEdit}>
                <ProfileUserEditModal
                  row={row}
                  handleCloseEdit={handleCloseEdit}
                  profileUserRefetch={profileUserRefetch}
                  handleClickUpdate={handleClickUpdate}
                />
              </Modal>
            </IconButton>
            <IconButton size="small">
              <DeleteIcon
                sx={{
                  color: blue,
                }}
                onClick={handleDelete}
              />
            </IconButton>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

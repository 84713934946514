import { ThemeProvider } from "@emotion/react";
import {
  Menu,
  MenuItem,
  Sidebar,
  sidebarClasses,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import React, { useCallback } from "react";
import "./Sidebar.css";
import { createTheme } from "@mui/material/styles";
import {
  SidebarDashboardMenu,
  SidebarData,
  SidebarHomePageLancamentos,
  SidebarHomePageMenu,
  SidebarRegisterMenu,
} from "./SidebarData";
import { Box, Button } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SidebarMenu from "./SidebarMenu";
import axios from "axios";
import { useQuery } from "react-query";
import ViewBIInterface from "../../types/ViewBIInterface";

export const SidebarNew = () => {
  const blue = "rgba(53, 68, 145, 1)";
  const white = "#ffffff";
  const theme = createTheme({
    palette: {
      primary: {
        main: blue,
      },
      secondary: {
        main: "#000000",
      },
    },
  });
  const permission = localStorage.getItem("currentUserPermission") ?? "-1";

  const currentPermission =
    permission.split(",").map(item => {
      return parseInt(item, 10);
    }) ?? 0;

  const { collapseSidebar } = useProSidebar();

  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const { data } = useQuery<ViewBIInterface[]>(
    "ViewBI",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/viewbi/list"
      );
      return response.data;
    },
    {
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <ThemeProvider theme={theme}>
      <Sidebar
        className="Sidebar"
        width="15.5vw"
        defaultCollapsed
        rootStyles={{
          height: "100%",
          [`.${sidebarClasses.container}`]: {
            backgroundColor: "transparent",
            borderRadius: "0px 15px 15px 0px",
            borderColor: "white",
          },
          [`.${sidebarClasses.root}`]: {
            borderRadius: "0px 15px 15px 0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "auto",
              width: "100%",
              justifyContet: "space-between",
            }}
          >
            <Button
              onClick={useCallback(() => collapseSidebar(), [])}
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                  boxShadow: "rgba(0, 0, 0, 0.5) 2.5px 2.5px 10px",
                },
                backgroundColor: "transparent",
                color: white,
                width: "100%",
              }}
            >
              <MenuOutlinedIcon />
            </Button>
          </Box>
          <Box
            sx={{
              height: "100%",
              maxHeight: "100%",
              overflow: "auto",
              overflowX: "hidden",
              "&::-webkit-scrollbar": {
                width: "0.3em",
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(255, 255, 255)",
                boxShadow: "rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px",
                borderRadius: "150px",
              },
            }}
          >
            <Menu
              menuItemStyles={{
                suffix: () => {
                  return {
                    color: white,
                  };
                },
                label: () => {
                  return {
                    color: white,
                  };
                },
                prefix: () => {
                  return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "100%",
                    maxWidth: "100%",
                  };
                },
                button: ({ level }) => {
                  if (level === 0) {
                    return {
                      color: white,
                      transition: "0.25s linear",
                      "&:hover": {
                        backgroundColor: white,
                        color: blue,
                        boxShadow: "rgba(0, 0, 0, 0.5) 2.5px 2.5px 10px",
                      },
                      alignItems: "center",
                    };
                  } else {
                    return {
                      color: blue,
                      transition: "0.25s linear",
                      "&:hover": {
                        boxShadow: "rgba(0, 0, 0, 0.5) 2.5px 2.5px 10px",
                      },
                      alignItems: "center",
                    };
                  }
                },
                SubMenuExpandIcon: () => {
                  return {
                    display: "flex",
                    alignItems: "center",
                  };
                },
              }}
            >
              {SidebarData.map(val => {
                if (
                  val.permission.some(value => {
                    return currentPermission?.includes(value);
                  })
                ) {
                  if (val.title === "Lançamentos") {
                    return (
                      <>
                        <SubMenu
                          key={val.id}
                          prefix={val.title}
                          icon={val.icon}
                        >
                          {SidebarHomePageLancamentos.map(subVal => (
                            <MenuItem
                              key={subVal.id}
                              onClick={() =>
                                (window.location.pathname = subVal.link)
                              }
                              prefix={<p>{subVal.title}</p>}
                            />
                          ))}
                        </SubMenu>
                      </>
                    );
                  }
                  // if (val.title === "HomePage") {
                  //   return (
                  //     <>
                  //       <SubMenu
                  //         key={val.id}
                  //         prefix={val.title}
                  //         icon={val.icon}
                  //       >
                  //         {SidebarHomePageMenu.map(subVal => (
                  //           <MenuItem
                  //             key={subVal.id}
                  //             onClick={() =>
                  //               (window.location.pathname = subVal.link)
                  //             }
                  //             prefix={<p>{subVal.title}</p>}
                  //           />
                  //         ))}
                  //       </SubMenu>
                  //     </>
                  //   );
                  // } else
                  else if (val.title === "Cadastros") {
                    return (
                      <>
                        <SubMenu
                          key={val.id}
                          prefix={val.title}
                          icon={val.icon}
                        >
                          {SidebarRegisterMenu.map(subVal => {
                            if (
                              subVal.permission.some(value => {
                                return currentPermission?.includes(value);
                              })
                            ) {
                              return (
                                <MenuItem
                                  key={subVal.id}
                                  onClick={() =>
                                    (window.location.pathname = subVal.link)
                                  }
                                  prefix={<p>{subVal.title}</p>}
                                />
                              );
                            }
                          })}
                        </SubMenu>
                      </>
                    );
                  } else if (val.title === "Views" && data?.length !== 0) {
                    return (
                      <>
                        <SubMenu
                          key={val.id}
                          prefix={val.title}
                          icon={val.icon}
                        >
                          {data?.map(subVal => {
                            if (
                              subVal.profile.split(",").some(value => {
                                return currentPermission?.includes(
                                  parseInt(value, 10)
                                );
                              })
                            ) {
                              return (
                                <MenuItem
                                  key={subVal.nr_sequency}
                                  onClick={() =>
                                    (window.location.pathname = `/Portal/View-${subVal.nm_view}`)
                                  }
                                >
                                  <p id="Sidebar-pText">{subVal.nm_view}</p>
                                </MenuItem>
                              );
                            } else {
                              return;
                            }
                          })}
                        </SubMenu>
                      </>
                    );
                  } else if (val.title === "Dashboard") {
                    return (
                      <>
                        <SubMenu
                          key={val.id}
                          prefix={val.title}
                          icon={val.icon}
                        >
                          {SidebarDashboardMenu.map(subVal => {
                            if (
                              subVal.permission.some(value => {
                                return currentPermission?.includes(value);
                              })
                            ) {
                              return (
                                <MenuItem
                                  key={subVal.id}
                                  onClick={() =>
                                    (window.location.pathname = subVal.link)
                                  }
                                  prefix={<p>{subVal.title}</p>}
                                />
                              );
                            }
                          })}
                        </SubMenu>
                      </>
                    );
                  }
                }
                return "";
              })}
            </Menu>
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "auto",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
            }}
          >
            <SidebarMenu />
          </Box>
        </Box>
      </Sidebar>
    </ThemeProvider>
  );
};

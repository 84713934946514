import React, { useEffect } from "react";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import axios from "axios";
import { useQuery } from "react-query";
import ProfileInterface from "../../types/ProfileInterface";

const permAdministrador = 1;
const permCoordenador = 2;
const permGeral = 3;
const permDev = 4;
const permCliente = 5;

export const SidebarData = [
  {
    id: 1,
    title: "Lançamentos",
    icon: <WorkHistoryOutlinedIcon />,
    link: "",
    permission: [permAdministrador, permCoordenador, permGeral, permDev],
  },
  {
    id: 2,
    title: "Cadastros",
    icon: <AccountBoxOutlinedIcon />,
    link: "",
    permission: [permAdministrador, permCoordenador, permDev],
  },
  {
    id: 3,
    title: "HomePage",
    icon: <NoteAltOutlinedIcon />,
    link: "",
    permission: [permAdministrador, permCoordenador, permDev],
  },
  {
    id: 4,
    title: "Views",
    icon: <DashboardCustomizeOutlinedIcon />,
    link: "/Portal/",
    permission: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
    ],
  },
  {
    id: 5,
    title: "Dashboard",
    icon: <DashboardOutlinedIcon />,
    link: "/Portal/Dashboard",
    permission: [permAdministrador, permCoordenador, permGeral, permDev],
  },
];

export const SidebarHomePageLancamentos = [
  {
    id: 1,
    title: "Atividades",
    icon: <WorkHistoryOutlinedIcon />,
    link: "/Portal/Activity",
    permission: [permAdministrador, permCoordenador, permGeral, permDev],
  },
  {
    id: 2,
    title: "Agendamento",
    icon: <CalendarMonthOutlinedIcon />,
    link: "/Portal/Schedule",
    permission: [permAdministrador, permCoordenador, permGeral, permDev],
  },
  {
    id: 3,
    title: "Despesas",
    icon: <WorkHistoryOutlinedIcon />,
    link: "/Portal/Despesas",
    permission: [permAdministrador, permCoordenador, permGeral, permDev],
  },
];

export const SidebarHomePageMenu = [
  {
    id: 1,
    title: "Novidades",
    icon: <DashboardCustomizeOutlinedIcon />,
    link: "/Portal/HomePageNews",
    permission: [permAdministrador, permCoordenador, permDev],
  },
  // {
  //   id: 2,
  //   title: "Newsletter",
  //   icon: <DashboardCustomizeOutlinedIcon />,
  //   link: "/Portal/HomePageNewsletter",
  //   permission: [permAdministrador, permCoordenador, permDev],
  // },
];

export const SidebarRegisterMenu = [
  {
    id: 1,
    title: "Usuários",
    icon: <GroupOutlinedIcon />,
    link: "/Portal/Users",
    permission: [permAdministrador],
  },
  {
    id: 2,
    title: "Clientes",
    icon: <ApartmentOutlinedIcon />,
    link: "/Portal/Company",
    permission: [permAdministrador],
  },
  {
    id: 3,
    title: "Perfil",
    icon: <AccountBoxOutlinedIcon />,
    link: "/Portal/ProfileUser",
    permission: [permAdministrador],
  },
  {
    id: 4,
    title: "View BI",
    icon: <AccountBoxOutlinedIcon />,
    link: "/Portal/ViewBI",
    permission: [permAdministrador, permCoordenador, permDev],
  },
];

export const SidebarDashboardMenu = [
  {
    id: 1,
    title: "Resumo usuário",
    icon: <GroupOutlinedIcon />,
    link: "/Portal/ResumoUsuario",
    permission: [permAdministrador, permDev],
  },
  {
    id: 2,
    title: "Faturamento",
    icon: <ApartmentOutlinedIcon />,
    link: "/Portal/Faturamento",
    permission: [permAdministrador, permCoordenador, permDev],
  },
  // {
  //   id: 3,
  //   title: "Cirurgias",
  //   icon: <AccountBoxOutlinedIcon />,
  //   link: "/Portal/Dashboard",
  //   permission: [permAdministrador],
  // },
  // {
  //   id: 4,
  //   title: "Censo Paciente",
  //   icon: <AccountBoxOutlinedIcon />,
  //   link: "/Portal/Dashboard",
  //   permission: [permAdministrador, permCoordenador, permDev],
  // },
  // {
  //   id: 5,
  //   title: "Urgências",
  //   icon: <GroupOutlinedIcon />,
  //   link: "/Portal/Dashboard",
  //   permission: [permAdministrador],
  // },
  // {
  //   id: 6,
  //   title: "Compras",
  //   icon: <ApartmentOutlinedIcon />,
  //   link: "/Portal/Dashboard",
  //   permission: [permAdministrador],
  // },
  // {
  //   id: 7,
  //   title: "Estoque",
  //   icon: <AccountBoxOutlinedIcon />,
  //   link: "/Portal/Dashboard",
  //   permission: [permAdministrador],
  // },
  // {
  //   id: 8,
  //   title: "Auditoria de contas",
  //   icon: <AccountBoxOutlinedIcon />,
  //   link: "/Portal/Dashboard",
  //   permission: [permAdministrador, permCoordenador, permDev],
  // },
  // {
  //   id: 9,
  //   title: "Contas a receber",
  //   icon: <GroupOutlinedIcon />,
  //   link: "/Portal/Dashboard",
  //   permission: [permAdministrador],
  // },
  // {
  //   id: 10,
  //   title: "Contas a pagar",
  //   icon: <ApartmentOutlinedIcon />,
  //   link: "/Portal/Dashboard",
  //   permission: [permAdministrador],
  // },
];

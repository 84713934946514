import React, { useState } from "react";
import "./App.css";
import HomePage from "./components/HomePage/HomePage";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

export default function App() {
  return (
    <div className="App">
      <Outlet/>
    </div>
  );
}

export const permAdministrador = 1;
export const permCoordenador = 2;
export const permGeral = 3;

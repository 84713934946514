import { Box, Card, Divider, Icon, useMediaQuery } from "@mui/material";
import React from "react";
import "./About.css";

export default function About() {
  const mobile = useMediaQuery("(min-width: 901px)");
  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <Box className="About-OutsideCard">
        <Box className="About-Box">
          <p id="About-pTitle">SOBRE NÓS</p>
          <Box
            sx={{
              backgroundColor: "rgba(53, 68, 145, 1)",
              height: "0.25em",
              width: "100%",
            }}
          />
        </Box>
      </Box>
      <Box className="About-TextBox">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            gap: mobile ? "2.5em" : "1.5em",
          }}
        >
          <p id="About-pText">
          Somos uma empresa de Tecnologia da Informação, atendemos clientes no território Nacional e Internacional, atuamos em dois seguimentos com objetivo de atender melhor a cada dia nossos clientes.
          Com o passar do tempo e com o conhecimento que adquirimos, observamos que muitas Instituições da área de saúde necessitavam de algo mais que os ajustes dos seus sistemas ERM e processos. Observamos que BI estratégicos iriam ajudar nossos clientes em suas todas de decisões. Mapeamos diversos processos e criamos dentro da nossa fábrica, BI´s que auxiliam nossos clientes em suas tomadas de decisões.
          
          </p>
          <p id="About-pTextFooter">
            ‟Integridade, confiança e respeito são os valores que nos guiam.”
          </p>
        </Box>
      </Box>
    </Box>
  );
}

import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import React from "react";
import "../Sidebar/Sidebar.css";
import {
  SidebarDashboardMenu,
  SidebarData,
  SidebarHomePageLancamentos,
  SidebarHomePageMenu,
  SidebarRegisterMenu,
} from "../Sidebar/SidebarData";
import axios from "axios";
import { useQuery } from "react-query";
import ViewBIInterface from "../../types/ViewBIInterface";

export const ProfileDrawer = () => {
  const blue = "rgba(53, 68, 145, 1)";

  const permission = localStorage.getItem("currentUserPermission") ?? "-1";

  const currentPermission =
    permission.split(",").map(item => {
      return parseInt(item, 10);
    }) ?? 0;

  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  return (
    <Menu
      menuItemStyles={{
        suffix: () => {
          return {
            color: blue,
          };
        },
        label: () => {
          return {
            color: blue,
          };
        },
        button: () => {
          return {
            color: blue,
          };
        },
        icon: () => {
          return {
            color: blue,
          };
        },
        SubMenuExpandIcon: () => {
          return {
            position: "relative",
            bottom: "5%",
          };
        },
      }}
    >
      {SidebarData.map(val => {
        if (
          val.permission.some(value => {
            return currentPermission?.includes(value);
          })
        ) {
          if (val.title === "Lançamentos") {
            return (
              <>
                <SubMenu
                  key={val.id}
                  prefix={val.title}
                  icon={val.icon}
                >
                  {SidebarHomePageLancamentos.map(subVal => (
                    <MenuItem
                      key={subVal.id}
                      onClick={() =>
                        (window.location.pathname = subVal.link)
                      }
                      prefix={<p>{subVal.title}</p>}
                    />
                  ))}
                </SubMenu>
              </>
            );
          } else if (val.title === "HomePage") {
            return (
              <>
                <SubMenu key={val.id} prefix={val.title} icon={val.icon}>
                  {SidebarHomePageMenu.map(subVal => (
                    <MenuItem
                      key={subVal.id}
                      onClick={() => (window.location.pathname = subVal.link)}
                      prefix={<p>{subVal.title}</p>}
                    />
                  ))}
                </SubMenu>
              </>
            );
          } else if (val.title === "Cadastros") {
            return (
              <>
                <SubMenu key={val.id} prefix={val.title} icon={val.icon}>
                  {SidebarRegisterMenu.map(subVal => {
                    if (
                      subVal.permission.some(value => {
                        return currentPermission?.includes(value);
                      })
                    ) {
                      return (
                        <MenuItem
                          key={subVal.id}
                          onClick={() =>
                            (window.location.pathname = subVal.link)
                          }
                          prefix={<p>{subVal.title}</p>}
                        />
                      );
                    }
                  })}
                </SubMenu>
              </>
            );
          } else if (val.title === "Dashboard") {
            return (
              <>
                <SubMenu key={val.id} prefix={val.title} icon={val.icon}>
                  {SidebarDashboardMenu.map(subVal => {
                    if (
                      subVal.permission.some(value => {
                        return currentPermission?.includes(value);
                      })
                    ) {
                      return (
                        <MenuItem
                          key={subVal.id}
                          onClick={() =>
                            (window.location.pathname = subVal.link)
                          }
                          prefix={<p>{subVal.title}</p>}
                        />
                      );
                    }
                  })}
                </SubMenu>
              </>
            );
          }
        }
        return "";
      })}
    </Menu>
  );
};

/* eslint-disable @typescript-eslint/no-var-requires */
// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  Select,
} from "@chakra-ui/react";
// Custom Components
import PropTypes from "prop-types";
import React, { ChangeEventHandler, useCallback } from "react";
// Assets
const navImage = require("./Navbar.png");
import { MdNotificationsNone, MdInfoOutline } from "react-icons/md";
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import { FaEthereum } from "react-icons/fa";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import "./Navbar.css";
import { useQuery } from "react-query";
import axios from "axios";
import UserInterface from "../../../../../types/UserInterface";
import {
  Select as MUISelect,
  MenuItem as MUIMenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { CurrentUserContext } from "../../../../../../../contexts/ContextProvider";

export default function HeaderLinks(props: {
  secondary: boolean;
  userContextId: any;
  setUserContextId: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { secondary, userContextId, setUserContextId, setIsLoading } = props;
  const { colorMode, toggleColorMode } = useColorMode();

  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  const menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");

  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");

  const permission = localStorage.getItem("currentUserPermission") ?? "-1";
  const currentPermission =
    permission.split(",").map(item => {
      return parseInt(item, 10);
    }) ?? 0;

  const handleUserCompany = useCallback((event: any) => {
    if (parseInt(event.target.value.toString()) == 0) {
      setUserContextId(
        parseInt(localStorage.getItem("currentUserId") ?? "-1") ?? -1
      );
      setIsLoading(true);
    } else {
      setUserContextId(parseInt(event.target.value.toString(), 10) ?? 0);
      setIsLoading(true);
    }
  }, []);

  const { data: repoUsers, refetch: userRefetch } = useQuery<UserInterface[]>(
    "User",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/user/list"
      );
      return response.data;
    },
    {
      staleTime: 1000 * 8,
    }
  );
  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <Select
        borderRadius="30px"
        me="10px"
        width="17.5em"
        onChange={handleUserCompany}
        disabled={
          !currentPermission.includes(1) || !currentPermission.includes(2)
        }
      >
        <option selected key={0} value={0}>
          Usuário Logado
        </option>
        {repoUsers?.map(item => {
          return (
            <option key={item.nr_sequency} value={item.nr_sequency}>
              {item.nm_person}
            </option>
          );
        })}
      </Select>
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={toggleColorMode}
      >
        <Icon
          me="10px"
          h="18px"
          w="18px"
          color={navbarIcon}
          as={colorMode === "light" ? IoMdMoon : IoMdSunny}
        />
      </Button>
      <Menu>
        <MenuButton
          p="0px"
          sx={{
            transition: "1.5s",
            "&:hover": { transform: "rotateZ(359deg)" },
          }}
        >
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
            icon={<SettingsOutlinedIcon />}
          />
        </MenuButton>
        <MenuList
          boxShadow="rgba(0, 0, 0, 0.25) 2.5px 2.5px 10px"
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              Configurações
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <Flex
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              flexDirection="column"
              alignItems="flex-start"
              borderRadius="8px"
              p="6px 14px"
            >
              <Text fontSize="sm">Estabelecimento</Text>
              <Select placeholder="Todos" w="100%">
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Flex>
            <Flex
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              flexDirection="column"
              alignItems="flex-start"
              borderRadius="8px"
              p="6px 14px"
            >
              <Text fontSize="sm">Convênio</Text>
              <Select placeholder="Todos" w="100%">
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Flex>
            <Flex
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              flexDirection="column"
              alignItems="flex-start"
              borderRadius="8px"
              p="12px 14px"
            >
              <Text fontSize="sm">Procedimento</Text>
              <Select placeholder="Todos" w="100%">
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Flex>
            <MenuItem
              _hover={{ bg: "secondaryGray.900" }}
              _focus={{ bg: "secondaryGray.900" }}
              color="white"
              backgroundColor="secondaryGray.900"
              borderRadius="8px"
              p="6px 14px"
              alignItems="center"
            >
              <Text fontSize="sm">Aplicar filtros</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};

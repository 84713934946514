import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useState } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from "react-query";
import ProfileInterface from "../../types/ProfileInterface";
import ViewBIInterface from "../../types/ViewBIInterface";
import "./ViewBI.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CompanyInterface from "../../types/CompanyInterface";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";

export default function ViewBIEditModal(props: {
  row: ViewBIInterface;
  viewBIRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ViewBIInterface[]>>;
  handleCloseEdit: () => void;
  handleClickUpdate: () => void;
}) {

  const { row, viewBIRefetch, handleCloseEdit, handleClickUpdate } = props;
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const [openPerfil, setOpenPerfil] = useState(false);

  const handlePerfil = useCallback((): void => {
    setOpenPerfil(true);
  }, []);

  const handlePerfilClose = useCallback((): void => {
    setOpenPerfil(false);
  }, []);

  const [ViewBIId] = useState(row.nr_sequency);
  const [ViewBINome, setViewBINome] = useState(row.nm_view);
  const [ViewBIDescricao, setViewBIDescricao] = useState(row.ds_view);
  const [ViewBILink, setViewBILink] = useState(row.ds_link);
  const [ViewBIKey, setViewBIKey] = useState(row.ds_key);
  const [ViewBIDetail, setViewBIDetail] = useState(row.ds_detail);
  const [UserCompany, setUserCompany] = useState(row.company.nr_sequency);  
  const selectedCards = React.useRef<string[]>(row.profile.split(","));

  const [ViewBIError, setViewBIError] = useState(0);
  const [dsMsgError, setDsMsgError] = useState("");

  const handleViewBINome = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setViewBIError(0);
      }
      setViewBINome(event.target.value);
    },
    []
  );

  const handleViewBIDescricao = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setViewBIError(0);
      }
      setViewBIDescricao(event.target.value);
    },
    []
  );

  const handleViewBILink = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setViewBIError(0);
      }
      setViewBILink(event.target.value);
    },
    []
  );

  const handleViewBIKey = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setViewBIError(0);
      }
      setViewBIKey(event.target.value);
    },
    []
  );

  const handleViewBIDetail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setViewBIError(0);
      }
      setViewBIDetail(event.target.value);
    },
    []
  );

  const handleUserCompany = useCallback((event: SelectChangeEvent<number>) => {
    if (event.target.value !== 0) {
      setViewBIError(0);
    }
    setUserCompany(parseInt(event.target.value.toString(), 10) ?? 0);
    if (row.company.nr_sequency !== event.target.value) {
      selectedCards.current.length = 0;
    }
  }, []);

  const updateViewBI = useCallback(() => {
    const ViewBI = {
      nr_sequency: ViewBIId,
      nm_view: ViewBINome,
      ds_view: ViewBIDescricao,
      ds_link: ViewBIDetail,
      ds_key: ViewBIKey,
      ds_detail: ViewBILink,
      profile: selectedCards.current.toString(),
      company: {
        nr_sequency: UserCompany,
      },
    };

    axios
      .post("https://api.incortec.com.br:8080/registrationservice/viewbi/update", ViewBI)
      .then(() => {
        viewBIRefetch();
        handleCloseEdit();
        handleClickUpdate();
      });
  }, [
    ViewBIId,
    ViewBINome,
    ViewBILink,
    ViewBIKey,
    row,
    ViewBIDescricao,
    ViewBIDetail,
    selectedCards,
  ]);

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (ViewBINome === "") {
      setViewBIError(1);
      setDsMsgError("Favor informar o nome do view");
      handleClick();
    } else if (ViewBIDescricao === "") {
      setViewBIError(2);
      setDsMsgError("Favor informar o email");
      handleClick();
    } else if (ViewBILink === "") {
      setViewBIError(3);
      setDsMsgError("Favor informar a senha");
      handleClick();
    } else if (ViewBIKey === "") {
      setViewBIError(4);
      setDsMsgError("Favor informar a senha");
      handleClick();
    } else if (ViewBIDetail === "") {
      setViewBIError(5);
      setDsMsgError("Favor informar a senha");
      handleClick();
    } else if (UserCompany === 0) {
      setViewBIError(6);
      setDsMsgError("Favor informar o cliente");
      handleClick();
    } else if (selectedCards.current.length === 0) {
      setViewBIError(7);
      setDsMsgError("Favor escolher ao menos 1 perfil");
      handleClick();
    } else {
      updateViewBI();
    }
  }, [
    ViewBINome,
    ViewBIDescricao,
    ViewBILink,
    ViewBIKey,
    ViewBIDetail,
    row,
    selectedCards,
    UserCompany,
  ]);

  const { data: repoCompany } = useQuery<CompanyInterface[]>(
    "Company",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/company/list"
      );
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
      onKeyDown={event => {
        if (event.key === "Enter") {
          multiFunctions();
        }
      }}
    >
      <Box className="ModalViewBI-Main">
        <Box className="ModalViewBI-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalViewBI-pTitle">Alterar view</p>
            <IconButton onClick={handleCloseEdit}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          <TextField
            error={ViewBIError === 1}
            fullWidth
            onChange={handleViewBINome}
            size="small"
            label={<p>Nome</p>}
            defaultValue={ViewBINome}
            variant="standard"
          ></TextField>
          <TextField
            error={ViewBIError === 2}
            fullWidth
            onChange={handleViewBIDescricao}
            size="small"
            label={<p>Descricao</p>}
            defaultValue={ViewBIDescricao}
            variant="standard"
          ></TextField>
          <TextField
            error={ViewBIError === 3}
            fullWidth
            onChange={handleViewBILink}
            size="small"
            label={<p>Link</p>}
            defaultValue={ViewBILink}
            variant="standard"
          ></TextField>
          <TextField
            error={ViewBIError === 4}
            defaultValue={ViewBIKey}
            value={ViewBIKey}
            onChange={handleViewBIKey}
            size="small"
            label={<p>Key</p>}
            fullWidth
            variant="standard"
          ></TextField>
          <TextField
            error={ViewBIError === 5}
            defaultValue={ViewBIDetail}
            value={ViewBIDetail}
            onChange={handleViewBIDetail}
            size="small"
            label={<p>Telefone</p>}
            variant="standard"
            fullWidth
          ></TextField>
          <FormControl fullWidth sx={{ textAlign: "left" }}>
            <InputLabel>{<p>Cliente</p>}</InputLabel>
            <Select
              error={ViewBIError === 6}
              label={<p>Cliente</p>}
              onChange={handleUserCompany}
              defaultValue={UserCompany}
              fullWidth
              color="primary"
              variant="standard"
            >
              {repoCompany?.map(val => {
                return (
                  <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                    {val.nm_company}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            disabled={UserCompany === 0}
            onClick={handlePerfil}
            size="small"
            disableRipple
            variant="outlined"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
              color: ViewBIError === 7 ? "red" : blue,
              borderColor: ViewBIError === 7 ? "red" : blue,
              "&:hover": { backgroundColor: blue, color: "white" },
              borderRadius: "10px",
              textTransform: "none",
              padding: "0.25em 5em",
            }}
            fullWidth
          >
            <PostAddOutlinedIcon />
            <p id="ModalHomePageNovidades-pButton">Alterar perfil</p>
          </Button>
          <Modal open={openPerfil} onClose={handlePerfilClose}>
            <PerfilModal
              handlePerfilClose={handlePerfilClose}
              selectedCards={selectedCards}
              UserCompany={UserCompany}
            />
          </Modal>
          <Button
            size="small"
            onClick={multiFunctions}
            disableRipple
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: blue,
              "&:hover": { backgroundColor: "white", color: blue },
              borderRadius: "10px",
              boxShadow: 5,
              textTransform: "none",
              padding: "0.25em 5em",
            }}
          >
            <p id="ModalViewBI-pButton">Alterar view</p>
          </Button>
        </Box>
        <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
          <Alert severity="error" variant="filled">
            {dsMsgError}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

function PerfilModal(props: {
  handlePerfilClose: () => void;
  selectedCards: React.MutableRefObject<string[]>;
  UserCompany: number;
}) {
  const { handlePerfilClose, selectedCards, UserCompany } = props;
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const handleCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        selectedCards.current = [...selectedCards.current, e.target.value];
      } else {
        const buscar = e.target.value;
        let indice = selectedCards.current.indexOf(buscar);
        while (indice >= 0) {
          selectedCards.current.splice(indice, 1);
          indice = selectedCards.current.indexOf(buscar);
        }
      }
    },
    [selectedCards]
  );

  const { data: repoProfile } = useQuery<ProfileInterface[]>(
    "profile",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/profileuser/list"
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
    >
      <Box className="ModalHomePageNovidades-Main">
        <Box className="ModalHomePageNovidades-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalHomePageNovidades-pTitle">Perfil</p>
            <IconButton onClick={handlePerfilClose}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          {repoProfile?.map(val => {
            if (val.company.nr_sequency === UserCompany) {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    gap: "0.5em",
                  }}
                  key={val.nr_sequency}
                >
                  <Checkbox
                    value={val.nr_sequency}
                    onChange={handleCheckbox}
                    defaultChecked={selectedCards.current.includes(
                      val.nr_sequency.toString()
                    )}
                  />
                  <p>{val.ds_profile}</p>
                </Box>
              );
            } else {
              return;
            }
          })}
        </Box>
      </Box>
    </Box>
  );
}

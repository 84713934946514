import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  Menu,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  ThemeProvider,
  Tooltip,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import "./Navbar.css";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ScheduleModal from "../ScheduleModal";
import axios from "axios";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from "react-query";
import UserInterface from "../../../types/UserInterface";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Calendar, DateObject } from "react-multi-date-picker";
import "./BlueCalendar.css";
import ScheduleInterface from "../../../types/ScheduleInterface";

interface NavbarInterface {
  handleClickAdd: () => void;
  handleUser: (event: SelectChangeEvent<number>) => void;
  ScheduleUser: number;
  handleCalendarMonth: (newScheduleMonthPicker: DateObject) => void;
  ScheduleMonthPicker: DateObject | undefined;
  ScheduleRefetch: (ScheduleMonthPicker: DateObject, ScheduleUser: number) => Promise<any>;
  setSemRegistros: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

export default function Navbar({
  handleClickAdd,
  handleUser,
  ScheduleUser,
  handleCalendarMonth,
  ScheduleMonthPicker,
  ScheduleRefetch,
  setSemRegistros,
  setIsLoading,
  isLoading
}: NavbarInterface) {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const permission = localStorage.getItem("currentUserPermission") ?? "-1";

  const currentPermission =
    permission.split(",").map(item => {
      return parseInt(item, 10);
    }) ?? 0;

  const Mobile = useMediaQuery("(min-width: 901px)");
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [dataUsers, setDataUsers] = useState<UserInterface[]>();
  const [loadRegisters, setLoadRegisters] = useState(true);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (dataUsers === undefined) {
      Promise.all([
        axios.get(
          "https://api.incortec.com.br:8080/registrationservice/user/list"
        ),
      ])
        .then(response => {
          setDataUsers(response[0].data);
          setLoadRegisters(false);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [dataUsers]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const AddSchedule = useCallback((): void => {
    setOpenCriar(true);
  }, []);

  const handleCriarClose = useCallback((): void => {
    setOpenCriar(false);
  }, []);

  const [openCriar, setOpenCriar] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: blue,
      },
      secondary: {
        main: "#ffffff",
      },
    },
  });

  return (
    <>
      {Mobile ? (
        <Box className="NavbarSchedule-Default">
          <p id="NavbarSchedule-pTitle">Agendamento</p>
          <ThemeProvider theme={theme}>
            <Box className="NavbarSchedule-Box">
              <IconButton
                onClick={handleClick}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  "&:disabled": { backgroundColor: "#232F6A", color: "gray" },
                  borderRadius: 2,
                  boxShadow: 5,
                  padding: "0em 0.75em",
                  fontSize: "1.5rem",
                  fontWeight: "700",
                }}
                disabled={isLoading}
                size="small"
              >
                <FilterAltOutlinedIcon />
                <p>{ScheduleMonthPicker === undefined 
                  ? "Selecionar mês" 
                  : months[ScheduleMonthPicker.month.index]}
                </p>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    padding: "0.5em",
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    "& .MuiAvatar-root": {
                      width: 30,
                      height: 30,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{ top: "8%" }}
              >
                {currentPermission.includes(1) ||
                  currentPermission.includes(2) ? (
                    <FormControl
                      fullWidth
                      sx={{
                        p: "2px 4px",
                      }}
                    >
                      <InputLabel>
                        <p>Usuário</p>
                      </InputLabel>
                      <Select
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          textAlign: "left",
                        }}
                        fullWidth
                        size="small"
                        color="primary"
                        variant="standard"
                        onChange={handleUser}
                        label={<p>Usuário</p>}
                        defaultValue={ScheduleUser}
                      >
                        {dataUsers?.map(val => {
                          return (
                            <MenuItem
                              key={val.nr_sequency}
                              value={val.nr_sequency}
                            >
                              {val.nm_person}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {loadRegisters ? <LinearProgress sx={{color: blue, width: "100%", height: "0.1em"}} /> : ""}
                    </FormControl>
                  ) : (
                    ""
                  )}
                <Calendar
                  onlyMonthPicker
                  highlightToday={false}
                  months={months}
                  hideMonth={true}
                  className="blue"
                  value={ScheduleMonthPicker}
                  onChange={handleCalendarMonth}
                />
              </Menu>
              <IconButton
                onClick={AddSchedule}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },                 
                  "&:disabled": { backgroundColor: "#232F6A", color: "gray" },

                  borderRadius: 2,
                  boxShadow: 5,
                  gap: 1,
                  fontSize: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                disabled={isLoading}
              >
                <AddCircleOutlineOutlinedIcon />
                <p id="NavbarSchedule-pButton">Novo agendamento</p>
              </IconButton>
              <Modal open={openCriar} onClose={handleCriarClose}>
                <ScheduleModal
                  handleCriarClose={handleCriarClose}
                  handleClickAdd={handleClickAdd}
                  ScheduleUser={ScheduleUser}
                  ScheduleRefetch={ScheduleRefetch}
                  setSemRegistros={setSemRegistros}
                  setIsLoading={setIsLoading}
                />
              </Modal>
            </Box>
          </ThemeProvider>
        </Box>
      ) : (
        <Box className="NavbarSchedule-Default">
          <p id="NavbarSchedule-pTitle">Agendamento</p>
          <Box className="NavbarSchedule-Box">
            <ThemeProvider theme={theme}>
              <Tooltip title="Account settings">
                <IconButton
                  className="CreateButton-Schedule"
                  onClick={handleClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={{
                    color: "white",
                    backgroundColor: blue,
                    "&:hover": { backgroundColor: "white", color: blue },
                    borderRadius: 2,
                    boxShadow: 5,
                    gap: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  size="small"
                >
                  <MoreVertOutlinedIcon />
                </IconButton>
              </Tooltip>
            </ThemeProvider>
          </Box>
          <ThemeProvider theme={theme}>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  padding: "4px 8px",
                  "& .MuiAvatar-root": {
                    width: 30,
                    height: 30,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {currentPermission.includes(1) ||
                currentPermission.includes(2) ? (
                  <>
                    <FormControl
                      fullWidth
                      sx={{
                        p: "2px 4px",
                      }}
                    >
                      <InputLabel>
                        <p>Usuário</p>
                      </InputLabel>
                      <Select
                        sx={{
                          bgcolor: "white",
                          borderWidth: "0px",
                          borderColor: "transparent",
                          p: "2px 4px",
                          borderRadius: 2,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        fullWidth
                        size="small"
                        color="primary"
                        variant="standard"
                        onChange={handleUser}
                        label={<p>Usuário</p>}
                        defaultValue={ScheduleUser}
                      >
                        {dataUsers?.map(val => {
                          return (
                            <MenuItem
                              key={val.nr_sequency}
                              value={val.nr_sequency}
                            >
                              {val.nm_person}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {loadRegisters ? <LinearProgress sx={{color: blue, width: "100%", height: "0.1em"}} /> : ""}
                    </FormControl>
                    <Divider sx={{ margin: "8px 0px" }} />
                  </>
                ) : (
                  ""
                )}
              <Calendar
                onlyMonthPicker
                highlightToday={false}
                months={months}
                buttons={false}
                hideYear={true}
                hideMonth={true}
                format="M"
                className="blue"
                value={ScheduleMonthPicker}
                onChange={handleCalendarMonth}
              />
              <Divider sx={{ margin: "8px 0px" }} />
              <MenuItem sx={{ gap: 1 }} onClick={AddSchedule}>
                <AddCircleOutlineIcon color="primary" sx={{ fontSize: 22.5 }} />
                <p id="NavbarSchedule-pMenu">Novo agendamento</p>
              </MenuItem>
              <Modal open={openCriar} onClose={handleCriarClose}>
                <ScheduleModal
                  handleCriarClose={handleCriarClose}
                  handleClickAdd={handleClickAdd}
                  ScheduleUser={ScheduleUser}
                  ScheduleRefetch={ScheduleRefetch}
                  setSemRegistros={setSemRegistros}
                  setIsLoading={setIsLoading}
                />
              </Modal>
            </Menu>
          </ThemeProvider>
        </Box>
      )}
    </>
  );
}

import { Box, Card, useMediaQuery } from "@mui/material";
import React from "react";
import "./Solutions.css";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import DeveloperModeOutlinedIcon from "@mui/icons-material/DeveloperModeOutlined";

export default function Solutions() {
  const mobile = useMediaQuery("(min-width: 901px)");
  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <Box className="Solutions-TitleBox">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            height: "100%",
            width: "100vw",
            gap: "0.5em",
          }}
        >
          <p id="Solutions-pTitle">O QUE FAZEMOS</p>
          <Box
            sx={{
              backgroundColor: "rgba(82, 169, 167, 1)",
              height: "0.25em",
              width: "40%",
              marginBottom: "1.5em",
            }}
          />
          {mobile ? (
            ""
          ) : (
            <Box className="Solutions-GroupCard">
              <Box className="Solutions-CardBox">
                <DeveloperModeOutlinedIcon id="Solutions-Icon" />
                <p id="Solutions-pTitleCard">Fábrica de Software</p>
                <p id="Solutions-pText">
                  Na parte de desenvolvimento de soluções de TI, como fábrica de
                  software, desenvolvemos soluções WEB e Mobile. Também
                  desenvolvemos integrações, BI, Indicadores de Gestão e
                  relatórios voltados a área da Saúde.
                </p>
              </Box>
              <Box className="Solutions-CardBox">
                <GroupsOutlinedIcon id="Solutions-Icon" />
                <p id="Solutions-pTitleCard">Alocação de Recursos</p>
                <p id="Solutions-pText">
                  Alocação de profissionais de TI, para atuar junto ao cliente,
                  apoiando nas atividades diárias, como gerente de projetos,
                  analista de sistema, desenvolvedores, arquiteto de software,
                  entre outros.
                </p>
              </Box>
              <Box className="Solutions-CardBox">
                <DevicesOutlinedIcon id="Solutions-Icon" />
                <p id="Solutions-pTitleCard">Especialização</p>
                <p id="Solutions-pText">
                  Nossa equipe de Dev e Consultoria, são conhecidas pelo alto
                  grau de especialização e conhecimento em suas áreas. Com
                  nossas soluções tecnológicas atendemos nossos clientes com
                  alta capacidade e performance no mundo digital.
                </p>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box className="Solutions-OutsideCard">
        {mobile ? (
          <Box className="Solutions-GroupCard">
            <Box className="Solutions-CardBox">
              <DeveloperModeOutlinedIcon id="Solutions-Icon" />
              <p id="Solutions-pTitleCard">Fábrica de Software</p>
              <p id="Solutions-pText">
                Na parte de desenvolvimento de soluções de TI, como fábrica de
                software, desenvolvemos soluções WEB e Mobile. Também
                desenvolvemos integrações, BI, Indicadores de Gestão e
                relatórios voltados a área da Saúde.
              </p>
            </Box>
            <Box className="Solutions-CardBox">
              <GroupsOutlinedIcon id="Solutions-Icon" />
              <p id="Solutions-pTitleCard">Alocação de Recursos</p>
              <p id="Solutions-pText">
                Alocação de profissionais de TI, para atuar junto ao cliente,
                apoiando nas atividades diárias, como gerente de projetos,
                analista de sistema, desenvolvedores, arquiteto de software,
                entre outros.
              </p>
            </Box>
            <Box className="Solutions-CardBox">
              <DevicesOutlinedIcon id="Solutions-Icon" />
              <p id="Solutions-pTitleCard">Especialização</p>
              <p id="Solutions-pText">
                Nossa equipe de Dev e Consultoria, são conhecidas pelo alto grau
                de especialização e conhecimento em suas áreas. Com nossas
                soluções tecnológicas atendemos nossos clientes com alta
                capacidade e performance no mundo digital.
              </p>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}

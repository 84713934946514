import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react";
interface Props {
    children?: React.ReactNode;
}
const initialState = {
  currentUser: null,
  currentUserId: null,
  permission: null,

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentUser: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentUserId: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentUserPermission: () => {}
};

type CurrentUserContextType = {
  currentUser : string | null,
  setCurrentUser: Dispatch<SetStateAction<string|null>>,
  currentUserId : number | null,
  setCurrentUserId: Dispatch<SetStateAction<number|null>>,
  permission : number[] | null,
  setCurrentUserPermission: Dispatch<SetStateAction<number[]|null>>
};

export const CurrentUserContext = createContext<CurrentUserContextType>(initialState);

export function ContextProvider({ children } : Props) : JSX.Element {
  const [ currentUser, setCurrentUser] = useState<string | null>(initialState.currentUser);
  const [ currentUserId, setCurrentUserId] = useState<number | null>(initialState.currentUser);
  const [ permission, setCurrentUserPermission] = useState<number[] | null>(initialState.permission);
  return(
    <CurrentUserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        currentUserId,
        setCurrentUserId,
        permission,
        setCurrentUserPermission
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

export function useUserContext() {
  return(useContext(CurrentUserContext));
}

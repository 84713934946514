import { ThemeProvider } from "@emotion/react";
import {
  createTheme,
  Button,
  Box,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useContext, useState } from "react";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
  useQuery,
} from "react-query";
import { CurrentUserContext } from "../../contexts/ContextProvider";
import ScheduleInterface from "../../types/ScheduleInterface";
import CompanyInterface from "../../types/CompanyInterface";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DatePicker, { DateObject } from "react-multi-date-picker";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

interface ModalProps {
  handleCriarClose: () => void;
  handleClickAdd: () => void;
  ScheduleUser: number;
  ScheduleRefetch: (ScheduleMonthPicker: DateObject, ScheduleUser: number) => Promise<any>;
  setSemRegistros: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ScheduleModal({
  handleCriarClose,
  handleClickAdd,
  ScheduleUser,
  ScheduleRefetch,
  setSemRegistros,
  setIsLoading,
}: ModalProps) {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const days = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  const mobile = useMediaQuery("(min-width: 901px)");
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const { currentUserId } = useContext(CurrentUserContext);
  const [ScheduleDescription, setScheduleDescription] = useState("");
  const [ScheduleCompany, setScheduleCompany] = useState(0);
  const [scheduleDate, setScheduleDate] = useState([
    new DateObject(),
    new DateObject().add(1, "days"),
  ]);

  const [ScheduleDescriptionError, setScheduleDescriptionError] =
    useState(false);
  const [ScheduleIniError, setScheduleIniError] = useState(false);
  const [ScheduleFinError, setScheduleFinError] = useState(false);
  const [ScheduleCompanyError, setScheduleCompanyError] = useState(false);
  const [dsMsgError, setDsMsgError] = useState("");

  const handleScheduleDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setScheduleDescriptionError(false);
      }
      setScheduleDescription(event.target.value);
    },
    []
  );

  const handleCliente = useCallback((event: SelectChangeEvent<number>) => {
    if (event.target.value !== 0) {
      setScheduleCompanyError(false);
    }
    setScheduleCompany(parseInt(event.target.value.toString(), 10) ?? 0);
  }, []);

  function handleDateChange(value: any) {
    setScheduleDate(value);
  }

  const addSchedule = () => {
    const Schedule = {
      ds_schedule: ScheduleDescription,
      dt_ini: scheduleDate[0]?.format("DD/MM/YYYY"),
      dt_fin: scheduleDate[1]?.format("DD/MM/YYYY"),
      user: {
        nr_sequency: currentUserId,
      },
      company: {
        nr_sequency: ScheduleCompany,
      },
    };

    axios
      .post(
        "https://api.incortec.com.br:8080/registrationservice/schedule/add",
        Schedule
      )
      .then(() => {
        handleClickAdd();
        ScheduleRefetch(scheduleDate[0], currentUserId!);
        setIsLoading(true);
        setSemRegistros(true);
        handleCriarClose();
      });
  };

  const { data: repoClientes } = useQuery<CompanyInterface[]>(
    "company",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/company/list"
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}
        onKeyDown={event => {
          if (event.key === "Enter") {
            addSchedule();
          }
        }}
      >
        <Box className="ModalSchedule-Main">
          <Box className="ModalSchedule-Wrapper">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p id="ModalSchedule-pTitle">Inserir novo agendamento</p>
              <IconButton onClick={handleCriarClose}>
                <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: mobile ? "row" : "column",
                alignItems: "center",
                width: "100%",
                gap: "1em",
              }}
            >
              <DatePicker
                className="rmdp-container blue"
                months={months}
                weekDays={days}
                value={scheduleDate}
                onChange={handleDateChange}
                range
                mapDays={({ date }) => {
                  const isWeekend = [0, 6].includes(date.weekDay.index);
                  if (isWeekend)
                    return {
                      disabled: true,
                    };
                }}
                format="DD/MM/YYYY"
                render={
                  <TextField
                    label={<p>Tempo da jornada</p>}
                    variant="standard"
                    color="primary"
                    fullWidth
                  />
                }
              />
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <InputLabel>
                  <p>Cliente</p>
                </InputLabel>
                <Select
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    textAlign: "left",
                  }}
                  fullWidth
                  size="small"
                  color="primary"
                  variant="standard"
                  onChange={handleCliente}
                >
                  {repoClientes?.map(val => {
                    return (
                      <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                        {val.nm_company}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <TextField
              size="small"
              color="primary"
              variant="standard"
              multiline
              label={<p>Descrição</p>}
              maxRows={2}
              rows={2}
              fullWidth
              onChange={handleScheduleDescription}
            />
            <ThemeProvider theme={theme}>
              <Button
                size="small"
                onClick={addSchedule}
                disableRipple
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: "10px",
                  boxShadow: 5,
                  textTransform: "none",
                  padding: "0.25em 5em",
                }}
              >
                <p id="ModalSchedule-pButton">Inserir agendamento</p>
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </Box>
      <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
        <Alert severity="error" variant="filled">
          {dsMsgError}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

import React, { useCallback, useContext, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ThemeProvider,
  styled,
  createTheme,
  Button,
  Modal,
  Alert,
  Snackbar,
  useStepContext,
  IconButton,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  LinearProgress,
} from "@mui/material";
import axios from "axios";
import { useQuery } from "react-query";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import Loading from "../../../layout/Loader/Loading";
import UserInterface from "../../../types/UserInterface";
import { CurrentUserContext } from "../../../contexts/ContextProvider";
import { permCoordenador, permGeral } from "../../../../../App";
import "./RD.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { permAdministrador } from "../../../../..";
import { Calendar, DateObject } from "react-multi-date-picker";
import CompanyInterface from "../../../types/CompanyInterface";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

const ReportButton = styled(Button)({
  textTransform: "none",
  color: "white",
  fontFamily: "Trebuchet MS, sans-serif",
});

interface InterfaceRD {
  handleRDClose: () => void;
  repoCompany: CompanyInterface[] | undefined;
  repoUsers: UserInterface[] | undefined;
  loadRegisters: boolean;
}

export default function RD({ handleRDClose, repoCompany, repoUsers, loadRegisters }: InterfaceRD) {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const days = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const [isLoading, setIsloading] = useState(false);

  const { currentUserId, currentUser } = useContext(CurrentUserContext);

  const permission = localStorage.getItem("currentUserPermission") ?? "-1";

  const currentPermission =
    permission.split(",").map(item => {
      return parseInt(item, 10);
    }) ?? 0;

  const [reportIdUser, setReportIdUser] = useState(0);
  const [reportIdCompany, setReportIdCompany] = useState(0);
  const [userName, setUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [reportDateIni, setReportDateIni] = useState<Dayjs | null>(
    null
  );
  const [reportDateFin, setReportDateFin] = useState<Dayjs | null>(
    null
  );

  const [reportIdError, setReportIdError] = useState(false);
  const [reportDateIniError, setReportDateIniError] = useState(false);
  const [reportDateFinError, setReportDateFinError] = useState(false);
  const [scheduleDate, setScheduleDate] = useState([
    new DateObject(),
    new DateObject().add(1, "days"),
  ]);

  const [dsMsgError, setDsMsgError] = useState("");

  const handleReportIdUser = useCallback(
    (event: SelectChangeEvent<number>) => {
      if (event.target.value !== 0) {
        setReportIdError(false);
      }
      setReportIdUser(parseInt(event.target.value.toString(), 10) ?? 0);
      repoUsers?.map(val => {
        if (val.nr_sequency === event.target.value) {
          setUserName(val.nm_person);
        }
      });
    },
    [reportIdUser, userName, repoUsers]
  );

  const handleReportIdCompany = useCallback(
    (event: SelectChangeEvent<number>) => {
      if (event.target.value !== 0) {
        setReportIdError(false);
      }
      setReportIdCompany(parseInt(event.target.value.toString(), 10) ?? 0);
      repoCompany?.map(val => {
        if (val.nr_sequency === event.target.value) {
          setCompanyName(val.nm_company);
        }
      });
    },
    [reportIdCompany, companyName, repoCompany]
  );

  function handleDateChange(value: any) {
    setScheduleDate(value);
  }

  const loadClose = useCallback(() => {
    setIsloading(false);
  }, []);

  const [open, setOpen] = useState(false);
  const [openRD, setOpenRD] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCloseRD = useCallback(() => {
    setOpenRD(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (
      !currentPermission.includes(permCoordenador) ||
      !currentPermission.includes(permAdministrador)
    ) {
      criarRelatorioUser();
    } else {
      if (value === "usuario") {
        if (reportIdUser === 0) {
          setReportIdError(true);
          setDsMsgError("Favor informar o usuário");
          handleClick();
        } else {
          criarRelatorioUser();
        }
      } else {
        if (reportIdCompany === 0) {
          setReportIdError(true);
          setDsMsgError("Favor informar o cliente");
          handleClick();
        } else {
          criarRelatorioCompany();
        }
      }
    }
  }, [reportIdCompany, reportIdUser, scheduleDate]);

  const criarRelatorioUser = useCallback(() => {
    setIsloading(true);
    const relatorio = {
      usuario:
        currentPermission.includes(permCoordenador) ||
        currentPermission.includes(permAdministrador)
          ? reportIdUser
          : currentUserId,
      dataInicial: scheduleDate[0]?.format("YYYY-MM-DD"),
      dataFinal: scheduleDate[1]?.format("YYYY-MM-DD"),
    };
    if (
      (relatorio.usuario && relatorio.dataInicial && relatorio.dataFinal) !=
      null
    ) {
      axios
        .get(
          `https://api.incortec.com.br:8080/registrationservice/RDUser?userId=${relatorio.usuario?.toString()}&startDate=${
            relatorio.dataInicial
          }&endDate=${relatorio.dataFinal}`,
          { responseType: "blob" }
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `RD - ${
              currentPermission.includes(permCoordenador) ||
              currentPermission.includes(permAdministrador)
                ? userName
                : currentUser
            } - Período de ${scheduleDate[0]
              ?.format("DD-MM-YYYY")
              .toString()} até ${scheduleDate[1]
              ?.format("DD-MM-YYYY")
              .toString()}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          loadClose();
          setOpenRD(true);
        });
    }
  }, [reportIdCompany, reportIdUser, scheduleDate]);

  const criarRelatorioCompany = useCallback(() => {
    setIsloading(true);
    const relatorio = {
      company: reportIdCompany,
      dataInicial: scheduleDate[0]?.format("YYYY-MM-DD"),
      dataFinal: scheduleDate[1]?.format("YYYY-MM-DD"),
    };
    if (
      (relatorio.company && relatorio.dataInicial && relatorio.dataFinal) !=
      null
    ) {
      axios
        .get(
          `https://api.incortec.com.br:8080/registrationservice/RDCompany?companyId=${relatorio.company?.toString()}&startDate=${
            relatorio.dataInicial
          }&endDate=${relatorio.dataFinal}`,
          { responseType: "blob" }
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `RD - ${companyName} - Período de ${scheduleDate[0]
              ?.format("DD-MM-YYYY")
              .toString()} até ${scheduleDate[1]
              ?.format("DD-MM-YYYY")
              .toString()}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          loadClose();
          setOpenRD(true);
        });
    }
  }, [reportIdCompany, reportIdUser, scheduleDate]);

  const [value, setValue] = useState("usuario");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100svw",
            height: "100svh",
            alignItems: "center",
          }}
        >
          <Box className="ModalRD-Main">
            <Box className="ModalRD-Wrapper">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <p id="ModalRD-pTitle">Gerar RD</p>
                <IconButton onClick={handleRDClose}>
                  <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
                </IconButton>
              </Box>
              {!currentPermission.includes(permCoordenador) &&
              !currentPermission.includes(permAdministrador) ? (
                  ""
                ) : (
                  <>
                    <FormControl>
                      <RadioGroup
                        value={value}
                        onChange={handleChange}
                        defaultValue="usuario"
                        row
                      >
                        <FormControlLabel
                          value="usuario"
                          control={<Radio />}
                          label="Usuário"
                        />
                        <FormControlLabel
                          value="cliente"
                          control={<Radio />}
                          label="Cliente"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      {value === "usuario" ? (
                        <>
                          <InputLabel>Usuário</InputLabel>
                          <Select
                            variant="standard"
                            error={reportIdError}
                            label={<p>Usuário</p>}
                            onChange={handleReportIdUser}
                            value={reportIdUser}
                            disabled={loadRegisters}
                          >
                            {repoUsers?.map(val => {
                              return (
                                <MenuItem
                                  key={val.nr_sequency}
                                  value={val.nr_sequency}
                                >
                                  {val.nm_person}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </>
                      ) : (
                        <>
                          <InputLabel>Cliente</InputLabel>
                          <Select
                            variant="standard"
                            error={reportIdError}
                            label={<p>Cliente</p>}
                            onChange={handleReportIdCompany}
                            value={reportIdCompany}
                            disabled={loadRegisters}
                          >
                            {repoCompany?.map(val => {
                              return (
                                <MenuItem
                                  key={val.nr_sequency}
                                  value={val.nr_sequency}
                                >
                                  {val.nm_company}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </>
                      )}
                      {loadRegisters ? <LinearProgress sx={{color: blue, width: "100%", height: "0.1em"}} /> : ""}
                    </FormControl>
                  </>
                )}
              <Calendar
                className="blue"
                months={months}
                weekDays={days}
                value={scheduleDate}
                onChange={handleDateChange}
                format="YYYY-MM-DD"
                range
              />
              <ReportButton
                disableRipple
                id="ModalRD-Button"
                variant="contained"
                onClick={multiFunctions}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: 2,
                  boxShadow: 5,
                  gap: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p id="ModalRD-pButton">Imprimir RD</p>
              </ReportButton>
              <Modal open={isLoading} onClose={loadClose}>
                <Loading />
              </Modal>
            </Box>
          </Box>
          <Snackbar
            open={openRD}
            onClick={handleCloseRD}
            autoHideDuration={2000}
          >
            <Alert severity="success" variant="filled">
              arquivo de RD criado com sucesso
            </Alert>
          </Snackbar>
          <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
            <Alert severity="error" variant="filled">
              {dsMsgError}
            </Alert>
          </Snackbar>
        </Box>
      </ThemeProvider>
    </>
  );
}

import React, { useCallback, useRef, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
// Custom components
import Card from "../../../Components/card/Card";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdBarChart, MdOutlineCalendarToday } from "react-icons/md";
// Assets
import { RiArrowUpSFill } from "react-icons/ri";
import { lineChartDataTotalSpent, lineChartOptionsTotalSpent } from "../charts";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import LineChart from "../../../Components/charts/LineChart";
import axios from "axios";
import CompanyInterface from "../../../../../types/CompanyInterface";
import { useQuery } from "react-query";
import ScheduleInterface from "../../../../../types/ScheduleInterface";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import _ from "lodash";

dayjs.extend(localeData);

export default function GraficoLinhaPorcentagemAgendamento(props: {
  graphReady: boolean;
  porcentagemSchedule: React.MutableRefObject<
    {
      name: string;
      data: number[];
    }[]
  >;
  [x: string]: any;
}) {
  const { graphReady, porcentagemSchedule, ...rest } = props;
  const currentUserId = localStorage.getItem("currentUserId") ?? "-1";

  // Chakra Color Mode

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  const bgList = useColorModeValue("white", "whiteAlpha.100");

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      mb="0px"
      boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
      p="10px"
      {...rest}
    >
      <Flex
        align="center"
        justify="space-between"
        w="100%"
        h="auto"
        px="15px"
        py="10px"
      >
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Agendamento mês
        </Text>
        <Menu isOpen={isOpen1} onClose={onClose1}>
          <MenuButton
            alignItems="center"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            w="37px"
            h="37px"
            lineHeight="100%"
            borderRadius="10px"
            onClick={onOpen1}
            {...rest}
            boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
          >
            <Icon
              as={MoreVertOutlinedIcon}
              color={iconColor}
              w="24px"
              h="24px"
            />
          </MenuButton>
          <MenuList
            w="150px"
            minW="unset"
            maxW="150px !important"
            border="transparent"
            backdropFilter="blur(63px)"
            bg={bgList}
            boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
            borderRadius="20px"
            p="15px"
          >
            <MenuItem
              transition="0.2s linear"
              color={textColor}
              _hover={textHover}
              p="0px"
              borderRadius="8px"
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              mb="10px"
              onClick={onOpen2}
            >
              <Flex alignItems="center" flexDirection="row">
                <Icon as={ZoomOutMapOutlinedIcon} h="16px" w="16px" me="8px" />
                <Text fontSize="sm" fontWeight="400">
                  Maximizar
                </Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Drawer isOpen={isOpen2} onClose={onClose2}>
        <DrawerOverlay />
        <DrawerContent minH="100svh" maxH="100svh" minW="95svw" maxW="95svw">
          <DrawerHeader>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <p>Agendamento mês</p>
              <Menu isOpen={isOpen1} onClose={onClose1}>
                <MenuButton
                  alignItems="center"
                  justifyContent="center"
                  bg={bgButton}
                  _hover={bgHover}
                  _focus={bgFocus}
                  _active={bgFocus}
                  w="37px"
                  h="37px"
                  lineHeight="100%"
                  borderRadius="10px"
                  onClick={onOpen1}
                  {...rest}
                  boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                >
                  <Icon
                    as={MoreVertOutlinedIcon}
                    color={iconColor}
                    w="24px"
                    h="24px"
                  />
                </MenuButton>
                <MenuList
                  w="150px"
                  minW="unset"
                  maxW="150px !important"
                  border="transparent"
                  backdropFilter="blur(63px)"
                  bg={bgList}
                  boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                  borderRadius="20px"
                  p="15px"
                >
                  <MenuItem
                    transition="0.2s linear"
                    color={textColor}
                    _hover={textHover}
                    p="0px"
                    borderRadius="8px"
                    _active={{
                      bg: "transparent",
                    }}
                    _focus={{
                      bg: "transparent",
                    }}
                    mb="10px"
                    onClick={onClose2}
                  >
                    <Flex alignItems="center" flexDirection="row">
                      <Icon
                        as={ZoomInMapOutlinedIcon}
                        h="16px"
                        w="16px"
                        me="8px"
                      />
                      <Text fontSize="sm" fontWeight="400">
                        Minimizar
                      </Text>
                    </Flex>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </DrawerHeader>
          <DrawerBody overflow="hidden">
            {graphReady && (
              <LineChart
                chartData={porcentagemSchedule.current}
                chartOptions={lineChartOptionsTotalSpent}
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Box minH="260px" minW="100%" mt="auto">
        {graphReady && (
          <LineChart
            chartData={porcentagemSchedule.current}
            chartOptions={lineChartOptionsTotalSpent}
          />
        )}
      </Box>
    </Card>
  );
}

import {
  Box,
  Button,
  Card,
  Divider,
  Icon,
  TextField,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import "./Contact.css";
import styled from "@emotion/styled";
import { ThemeProvider } from "@emotion/react";

const textField = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

const button = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

export default function Contact() {
  const mobile = useMediaQuery("(min-width: 901px)");

  const maskPhone = (value: string) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+$/, "$1");
  };

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [conteudo, setConteudo] = useState("");
  const [link, setLink] = useState("");

  const [contactError, setContactError] = useState(0);

  const handleNome = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setContactError(0);
      }
      setNome(event.target.value);
    },
    []
  );

  const handleEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setContactError(0);
      }
      setEmail(event.target.value);
    },
    []
  );

  const handleConteudo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setContactError(0);
      }
      setConteudo(event.target.value);
    },
    []
  );

  const verifyContact = useCallback(() => {
    if (nome === "") {
      setContactError(1);
      setLink("");
    } else if (email === "") {
      setContactError(2);
      setLink("");
    } else if (telefone.match(/^(\S{4})(\s{1})(\S{10})$/) === null) {
      setContactError(3);
      setLink("");
    } else if (conteudo === "") {
      setContactError(4);
      setLink("");
    } else {
      setLink(
        `mailto:contato@incortec.com.br?subject=Contato feito por ${nome}&body=${nome}%0D%0A%0D%0A${email}%0D%0A%0D%0A${telefone}%0D%0A%0D%0A${conteudo}`
      );
    }
  }, [nome, email, telefone, conteudo]);

  return (
    <Box
      sx={{
        height: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {mobile ? (
        <Box className="Contact-OutsideCard">
          <Box className="Contact-Box">
            <Box className="Contact-TitleBox">
              <p id="Contact-pTitle">PODEMOS AJUDÁ-LO! ENTRE EM CONTATO.</p>
              <p id="Contact-pSubTitle">
                Colocaremos um especialista em contato com você.
              </p>
            </Box>
            <Box className="Contact-Divider" />
            <Box className="Contact-InputBox">
              <ThemeProvider theme={textField}>
                <TextField
                  label={<p id="Contact-pInput">Nome</p>}
                  fullWidth
                  variant="filled"
                  color="primary"
                  error={contactError === 1}
                  value={nome}
                  onChange={handleNome}
                ></TextField>
                <Box className="Contact-InsideInputBox">
                  <TextField
                    label={<p id="Contact-pInput">Email</p>}
                    fullWidth
                    variant="filled"
                    color="primary"
                    error={contactError === 2}
                    value={email}
                    onChange={handleEmail}
                  ></TextField>
                  <TextField
                    label={<p id="Contact-pInput">Telefone</p>}
                    fullWidth
                    variant="filled"
                    color="primary"
                    error={contactError === 3}
                    value={telefone}
                    onChange={useCallback(
                      (e: { target: { value: string } }) => {
                        if (telefone !== "") {
                          setContactError(0);
                        }
                        setTelefone(maskPhone(e.target.value));
                      },
                      [telefone]
                    )}
                  ></TextField>
                </Box>
                <TextField
                  label={<p id="Contact-pInput">Como podemos te ajudar?</p>}
                  fullWidth
                  multiline
                  rows={3}
                  maxRows={3}
                  variant="filled"
                  color="primary"
                  error={contactError === 4}
                  value={conteudo}
                  onChange={handleConteudo}
                ></TextField>
              </ThemeProvider>
              <Button
                variant="contained"
                className="Contact-Button"
                sx={{
                  fontFamily: "Montserrat, sans-serif",
                  textTransform: "none",
                  fontSize: "1em",
                  fontWeight: "700",
                  backgroundColor: "rgba(53, 68, 145, 1)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "rgba(53, 68, 145, 1)",
                  },
                }}
                href={link}
                onClick={verifyContact}
              >
                Enviar email
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box className="Contact-Box">
            <Box className="Contact-TitleBox">
              <p id="Contact-pTitle">PODEMOS AJUDÁ-LO! ENTRE EM CONTATO.</p>
              <p id="Contact-pSubTitle">
                Colocaremos um especialista em contato com você.
              </p>
            </Box>
            <Box className="Contact-Divider" />
            <Box className="Contact-InputBox">
              <ThemeProvider theme={textField}>
                <TextField
                  label={<p id="Contact-pInput">Nome</p>}
                  fullWidth
                  variant="filled"
                  color="primary"
                  error={contactError === 1}
                  value={nome}
                  onChange={handleNome}
                ></TextField>
                <TextField
                  label={<p id="Contact-pInput">Email</p>}
                  fullWidth
                  variant="filled"
                  color="primary"
                  error={contactError === 2}
                  value={email}
                  onChange={handleEmail}
                ></TextField>
                <TextField
                  label={<p id="Contact-pInput">Telefone</p>}
                  fullWidth
                  variant="filled"
                  color="primary"
                  error={contactError === 3}
                  value={telefone}
                  onChange={useCallback(
                    (e: { target: { value: string } }) => {
                      if (telefone !== "") {
                        setContactError(0);
                      }
                      setTelefone(maskPhone(e.target.value));
                    },
                    [telefone]
                  )}
                ></TextField>
                <TextField
                  label={<p id="Contact-pInput">Como podemos te ajudar?</p>}
                  fullWidth
                  multiline
                  rows={3}
                  maxRows={3}
                  variant="filled"
                  color="primary"
                  error={contactError === 4}
                  value={conteudo}
                  onChange={handleConteudo}
                ></TextField>
              </ThemeProvider>
              <Button
                variant="contained"
                className="Contact-Button"
                sx={{
                  fontFamily: "Montserrat, sans-serif",
                  textTransform: "none",
                  fontSize: "1em",
                  fontWeight: "700",
                  backgroundColor: "rgba(53, 68, 145, 1)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "rgba(53, 68, 145, 1)",
                  },
                }}
                href={link}
                onClick={verifyContact}
              >
                Enviar email
              </Button>
            </Box>
          </Box>
          <Box className="Contact-OutsideCard"></Box>
        </>
      )}
    </Box>
  );
}

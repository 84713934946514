import React, { useRef, useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Text,
  Select,
  useColorModeValue,
  Button,
  Icon,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
// Custom components
import Card from "../../../Components/card/Card";
import PieChart from "../../../Components/charts/PieChart";
import { VSeparator } from "../../../Components/separator/Separator";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ActivityInterface from "../../../../../types/ActivityInterface";
import axios from "axios";
import _, { rest } from "lodash";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import CompanyInterface from "../../../../../types/CompanyInterface";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import { lineChartDataTotalSpent, lineChartOptionsTotalSpent } from "../charts";
import LineChart from "../../../Components/charts/LineChart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface pieCardInterface {
  graphReady: boolean;
  horaFinalCliente: number[];
  clientes: string[];
}

export default function GraficoPizzaHorasCliente({
  graphReady,
  horaFinalCliente,
  clientes,
}: pieCardInterface) {
  const [mobile] = useMediaQuery("(min-width: 901px)");
  const colorArray: string[] = [
    "rgb(53, 68, 145)",
    "rgb(82, 169, 167)",
    "rgb(216, 178, 210)",
    "rgb(75, 144, 162)",
    "rgb(176, 151, 194)",
    "rgb(68, 119, 156)",
    "rgb(135, 123, 178)",
    "rgb(61, 94, 151)",
    "rgb(94, 96, 162)",
  ];

  const ValorTotal = horaFinalCliente.reduce((sum, value) => sum + value, 0);
  const porcentagens = horaFinalCliente.map(value =>
    ((value / ValorTotal) * 100).toFixed(2)
  );

  const grupo = clientes
    .map((val, index) => {
      return {
        cliente: val,
        horas: horaFinalCliente[index],
        porcentagem: porcentagens[index],
      };
    })
    .sort((a, b) => parseFloat(b.porcentagem) - parseFloat(a.porcentagem))
    .filter(val => val.horas !== 0);

  const grupoClientes = grupo.map(val => {
    return val.cliente;
  });

  const pieChartOptions: any = {
    labels: grupoClientes,
    colors: colorArray,
    chart: {
      width: "50px",
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 1,
        left: 2,
        blur: 4,
        color: "#000000",
        opacity: 0.15,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: true,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: colorArray,
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const bgList = useColorModeValue("white", "whiteAlpha.100");

  const iconColor = useColorModeValue("brand.500", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    "unset"
  );

  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  return (
    <Card
      display="flex"
      alignItems="center"
      flexDirection="column"
      w="100%"
      height="100%"
      boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
      p="10px"
    >
      <Flex align="center" justify="space-between" w="100%" h="auto" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Horas por cliente
        </Text>
        <Menu isOpen={isOpen1} onClose={onClose1}>
          <MenuButton
            alignItems="center"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            w="37px"
            h="37px"
            lineHeight="100%"
            borderRadius="10px"
            onClick={onOpen1}
            {...rest}
            boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
          >
            <Icon
              as={MoreVertOutlinedIcon}
              color={iconColor}
              w="24px"
              h="24px"
            />
          </MenuButton>
          <MenuList
            w="150px"
            minW="unset"
            maxW="150px !important"
            border="transparent"
            backdropFilter="blur(63px)"
            bg={bgList}
            boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
            borderRadius="20px"
            p="15px"
          >
            <MenuItem
              transition="0.2s linear"
              color={textColor}
              _hover={textHover}
              p="0px"
              borderRadius="8px"
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              mb="10px"
              onClick={onOpen2}
            >
              <Flex alignItems="center" flexDirection="row">
                <Icon as={ZoomOutMapOutlinedIcon} h="16px" w="16px" me="8px" />
                <Text fontSize="sm" fontWeight="400">
                  Maximizar
                </Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Drawer isOpen={isOpen2} onClose={onClose2}>
        <DrawerOverlay />
        <DrawerContent
          minH="100svh"
          maxH="100svh"
          minW="95svw"
          maxW="95svw"
          p="15px"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <p>Horas por cliente</p>
            <Menu isOpen={isOpen1} onClose={onClose1}>
              <MenuButton
                alignItems="center"
                justifyContent="center"
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w="37px"
                h="37px"
                lineHeight="100%"
                borderRadius="10px"
                onClick={onOpen1}
                {...rest}
                boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
              >
                <Icon
                  as={MoreVertOutlinedIcon}
                  color={iconColor}
                  w="24px"
                  h="24px"
                />
              </MenuButton>
              <MenuList
                w="150px"
                minW="unset"
                maxW="150px !important"
                border="transparent"
                backdropFilter="blur(63px)"
                bg={bgList}
                boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                borderRadius="20px"
                p="15px"
              >
                <MenuItem
                  transition="0.2s linear"
                  color={textColor}
                  _hover={textHover}
                  p="0px"
                  borderRadius="8px"
                  _active={{
                    bg: "transparent",
                  }}
                  _focus={{
                    bg: "transparent",
                  }}
                  mb="10px"
                  onClick={onClose2}
                >
                  <Flex alignItems="center" flexDirection="row">
                    <Icon
                      as={ZoomInMapOutlinedIcon}
                      h="16px"
                      w="16px"
                      me="8px"
                    />
                    <Text fontSize="sm" fontWeight="400">
                      Minimizar
                    </Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
          <Box h={mobile ? "100%" : "50%"} w="100%">
            {graphReady && (
              <PieChart
                chartData={grupo.map(val => {
                  return val.horas;
                })}
                chartOptions={pieChartOptions}
              />
            )}
          </Box>
          <Card
            bg={cardColor}
            flexDirection="row"
            alignItems="center"
            boxShadow={cardShadow}
            w="100%"
            maxW="100%"
            p="10px"
            px="20px"
            mx="auto"
          >
            {grupo.map((val, i) => {
              const color = colorArray[i];
              if (i < (mobile ? 5 : 2)) {
                return (
                  <>
                    <Flex
                      direction="column"
                      py="5px"
                      w={mobile ? "30%" : "40%"}
                      key={i}
                      maxW={mobile ? "30%" : "40%"}
                      borderRightWidth="0.1em"
                      borderRightColor="secondaryGray.600"
                      marginRight="0.5em"
                    >
                      <Flex
                        alignItems="center"
                        flexDirection="row"
                        w="100%"
                        maxW="100%"
                      >
                        <Box
                          h="8px"
                          w="8px"
                          sx={{ backgroundColor: color }}
                          borderRadius="50%"
                          mr="4px"
                        />
                        <Tooltip
                          bg={cardColor}
                          label={
                            <Text
                              fontSize="xs"
                              color="secondaryGray.600"
                              fontWeight="700"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              overflow="hidden"
                            >
                              {val.cliente}
                            </Text>
                          }
                        >
                          <Text
                            fontSize="xs"
                            color="secondaryGray.600"
                            fontWeight="700"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            maxW={mobile ? "5vw" : "20vw"}
                          >
                            {val.cliente}
                          </Text>
                        </Tooltip>
                      </Flex>
                      <Text fontSize="sm" color={textColor} fontWeight="700">
                        {val.porcentagem}%
                      </Text>
                    </Flex>
                  </>
                );
              }
            })}
            <Menu>
              <MenuButton
                p="0px"
                sx={{
                  transition: "0.5s",
                  "&:hover": { transform: "scale(1.15)" },
                }}
              >
                <Icon w="32px" h="32px" as={ExpandMoreIcon} color={textColor} />
              </MenuButton>
              <MenuList
                boxShadow="rgba(0, 0, 0, 0.25) 2.5px 2.5px 10px"
                p="0.5em 1em"
                borderRadius="20px"
                bg={cardColor}
                border="none"
                display="grid"
                gridTemplateRows={mobile ? "repeat(7, 1fr)" : "repeat(10, 1fr)"}
                gridGap="0.25em 1.5em"
                gridAutoFlow="column"
              >
                {grupo.map((val, i) => {
                  const color = colorArray[i];
                  return (
                    <Flex
                      direction="row"
                      py="5px"
                      key={i}
                      w="auto"
                      justifyContent="space-between"
                      gap="0.5em"
                      borderBottomWidth="0.1em"
                      borderBottomColor="secondaryGray.600"
                    >
                      <Flex alignItems="center" flexDirection="row">
                        <Box
                          h="8px"
                          w="8px"
                          sx={{ backgroundColor: color }}
                          borderRadius="50%"
                          mr="4px"
                        />
                        <Text
                          fontSize="xs"
                          color="secondaryGray.600"
                          fontWeight="700"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          maxW={mobile ? "5vw" : "20vw"}
                        >
                          {val.cliente}
                        </Text>
                      </Flex>
                      <Text fontSize="xs" color={textColor} fontWeight="700">
                        {val.porcentagem}%
                      </Text>
                    </Flex>
                  );
                })}
              </MenuList>
            </Menu>
          </Card>
        </DrawerContent>
      </Drawer>
      <Box display="flex" flexDirection="column" h="100%" mt="auto" w="100%">
        {graphReady && (
          <PieChart
            chartData={grupo.map(val => {
              return val.horas;
            })}
            chartOptions={pieChartOptions}
          />
        )}
      </Box>
      <Card
        bg={cardColor}
        flexDirection="row"
        alignItems="center"
        boxShadow={cardShadow}
        w="100%"
        maxW="100%"
        p="10px"
        px="20px"
        mx="auto"
      >
        {grupo.map((val, i) => {
          const color = colorArray[i];
          if (i < (mobile ? 3 : 2)) {
            return (
              <>
                <Flex
                  direction="column"
                  py="5px"
                  w={mobile ? "30%" : "40%"}
                  key={i}
                  maxW={mobile ? "30%" : "40%"}
                  borderRightWidth="0.1em"
                  borderRightColor="secondaryGray.600"
                  marginRight="0.5em"
                >
                  <Flex
                    alignItems="center"
                    flexDirection="row"
                    w="100%"
                    maxW="100%"
                  >
                    <Box
                      h="8px"
                      w="8px"
                      sx={{ backgroundColor: color }}
                      borderRadius="50%"
                      mr="4px"
                    />
                    <Tooltip
                      bg={cardColor}
                      label={
                        <Text
                          fontSize="xs"
                          color="secondaryGray.600"
                          fontWeight="700"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          overflow="hidden"
                        >
                          {val.cliente}
                        </Text>
                      }
                    >
                      <Text
                        fontSize="xs"
                        color="secondaryGray.600"
                        fontWeight="700"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        maxW={mobile ? "5vw" : "20vw"}
                      >
                        {val.cliente}
                      </Text>
                    </Tooltip>
                  </Flex>
                  <Text fontSize="sm" color={textColor} fontWeight="700">
                    {val.porcentagem}%
                  </Text>
                </Flex>
              </>
            );
          }
        })}
        <Menu>
          <MenuButton
            p="0px"
            sx={{
              transition: "0.5s",
              "&:hover": { transform: "scale(1.15)" },
            }}
          >
            <Icon w="32px" h="32px" as={ExpandMoreIcon} color={textColor} />
          </MenuButton>
          <MenuList
            boxShadow="rgba(0, 0, 0, 0.25) 2.5px 2.5px 10px"
            p="0.5em 1em"
            borderRadius="20px"
            bg={cardColor}
            border="none"
            display="grid"
            zIndex="100"
            gridTemplateRows={mobile ? "repeat(7, 1fr)" : "repeat(10, 1fr)"}
            gridGap="0.25em 1.5em"
            gridAutoFlow="column"
          >
            {grupo.map((val, i) => {
              const color = colorArray[i];
              return (
                <Flex
                  direction="row"
                  py="5px"
                  key={i}
                  w="auto"
                  justifyContent="space-between"
                  gap="0.5em"
                  borderBottomWidth="0.1em"
                  borderBottomColor="secondaryGray.600"
                >
                  <Flex alignItems="center" flexDirection="row">
                    <Box
                      h="8px"
                      w="8px"
                      sx={{ backgroundColor: color }}
                      borderRadius="50%"
                      mr="4px"
                    />
                    <Text
                      fontSize="xs"
                      color="secondaryGray.600"
                      fontWeight="700"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      maxW={mobile ? "5vw" : "20vw"}
                    >
                      {val.cliente}
                    </Text>
                  </Flex>
                  <Text fontSize="xs" color={textColor} fontWeight="700">
                    {val.porcentagem}%
                  </Text>
                </Flex>
              );
            })}
          </MenuList>
        </Menu>
      </Card>
    </Card>
  );
}

import {
  IconButton,
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Modal,
  InputBase,
  Paper,
  useMediaQuery,
  Divider,
  Menu,
  Tooltip,
  FormControl,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import React, { useCallback, useState } from "react";
import "./Navbar.css";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
  useQuery,
} from "react-query";
import ViewBIInterface from "../../../types/ViewBIInterface";
import ViewBIModal from "../ViewBIModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import axios from "axios";
import CompanyInterface from "../../../types/CompanyInterface";

interface NavbarProps {
  handleShowQueryState: (e: React.ChangeEvent<HTMLInputElement>) => void;
  viewBIRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ViewBIInterface[]>>;
  handleClickAdd: () => void;
  handleFilterCompany: (event: SelectChangeEvent<number>) => void;
}

export default function Navbar({
  handleShowQueryState,
  viewBIRefetch,
  handleClickAdd,
  handleFilterCompany,
}: NavbarProps) {
  const Mobile = useMediaQuery("(min-width: 901px)");
  const blue = "rgba(53, 68, 145, 1)";
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const AddViewBI = useCallback((): void => {
    setOpenCriar(true);
  }, []);

  const handleCriarClose = useCallback((): void => {
    setOpenCriar(false);
  }, []);

  const [openCriar, setOpenCriar] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: blue,
      },
      secondary: {
        main: "#ffffff",
      },
    },
  });

  const { data: repoCompany } = useQuery<CompanyInterface[]>(
    "Company",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/company/list"
      );
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <>
      {Mobile ? (
        <Box className="NavbarViewBI-Default">
          <p id="NavbarViewBI-pTitle">Views</p>
          <ThemeProvider theme={theme}>
            <Box className="NavbarViewBI-Box">
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <Select
                  placeholder="Cliente"
                  onChange={handleFilterCompany}
                  defaultValue={0}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  sx={{
                    bgcolor: "white",
                    borderWidth: "0px",
                    borderColor: "transparent",
                    p: "2px 4px",
                    boxShadow: 5,
                    borderRadius: 2,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value={0}>
                    <p>Todos</p>
                  </MenuItem>
                  {repoCompany?.map(val => {
                    return (
                      <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                        <p>{val.nm_company}</p>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Paper
                sx={{
                  bgcolor: "white",
                  p: "2px 4px",
                  boxShadow: 5,
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "75%"
                }}
              >
                <InputBase
                  sx={{
                    ml: 1,
                    flex: 1,
                    fontWeight: 500,
                  }}
                  placeholder="Pesquisar"
                  onChange={handleShowQueryState}
                  fullWidth
                />
                <SearchOutlinedIcon color="primary" />
              </Paper>
              <IconButton
                onClick={AddViewBI}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: 2,
                  boxShadow: 5,
                  gap: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <AddCircleOutlineOutlinedIcon />
                <p id="NavbarViewBI-pButton">Novo view</p>
              </IconButton>
              <Modal open={openCriar} onClose={handleCriarClose}>
                <ViewBIModal
                  viewBIRefetch={viewBIRefetch}
                  handleCriarClose={handleCriarClose}
                  handleClickAdd={handleClickAdd}
                />
              </Modal>
            </Box>
          </ThemeProvider>
        </Box>
      ) : (
        <Box className="NavbarViewBI-Default">
          <p id="NavbarViewBI-pTitle">Views</p>
          <ThemeProvider theme={theme}>
            <Tooltip title="Account settings">
              <IconButton
                className="CreateButton-ViewBI"
                onClick={handleClick}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: 2,
                  boxShadow: 5,
                  gap: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                size="small"
              >
                <MoreVertOutlinedIcon />
              </IconButton>
            </Tooltip>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  padding: "4px 8px",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  "& .MuiAvatar-root": {
                    width: 30,
                    height: 30,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&& .Mui-selected": {
                    backgroundColor: "white",
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <Select
                  placeholder="Cliente"
                  onChange={handleFilterCompany}
                  defaultValue={0}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  sx={{
                    bgcolor: "white",
                    borderWidth: "0px",
                    borderColor: "transparent",
                    p: "2px 4px",
                    borderRadius: 2,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value={0}>
                    <p>Todos</p>
                  </MenuItem>
                  {repoCompany?.map(val => {
                    return (
                      <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                        <p>{val.nm_company}</p>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <Divider sx={{ margin: "8px 0px" }} />
              <Paper
                elevation={0}
                sx={{
                  bgcolor: "white",
                  p: "2px 4px",
                  display: "flex",
                  "&& .Mui-selected": {
                    backgroundColor: "white",
                  },
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <InputBase
                  sx={{
                    ml: 1,
                    flex: 1,
                    fontFamily: "Montserrat, sans-serif",
                    color: "rgba(53, 68, 145, 1",
                    "&& .Mui-selected": {
                      backgroundColor: "white",
                    },
                  }}
                  placeholder="Pesquisar"
                  onChange={handleShowQueryState}
                />
                <SearchOutlinedIcon color="primary" />
              </Paper>
              <Divider sx={{ margin: "8px 0px" }} />
              <MenuItem onClick={AddViewBI} sx={{  gap: 1 }}>
                <AddCircleOutlineIcon color="primary" sx={{ fontSize: 22.5 }} />
                <p id="NavbarViewBI-pMenu">Adicionar novo view</p>
              </MenuItem>
              <Modal open={openCriar} onClose={handleCriarClose}>
                <ViewBIModal
                  viewBIRefetch={viewBIRefetch}
                  handleCriarClose={handleCriarClose}
                  handleClickAdd={handleClickAdd}
                />
              </Modal>
            </Menu>
          </ThemeProvider>
        </Box>
      )}
    </>
  );
}

import {
  IconButton,
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Modal,
  InputBase,
  Paper,
  useMediaQuery,
  Divider,
  Menu,
  Tooltip,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import React, { useCallback, useState } from "react";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from "react-query";
import "./Navbar.css";
import HomePageInterface from "../../../types/HomePageInterface";
import HomePageNewsletterModal from "../HomePageNewsletterModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

interface NavbarProps {
  handleShowQueryState: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickAdd: () => void;
  HomePageNewsletterRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<HomePageInterface[]>>;
}

export default function Navbar({
  handleShowQueryState,
  HomePageNewsletterRefetch,
  handleClickAdd,
}: NavbarProps) {
  const Mobile = useMediaQuery("(min-width: 901px)");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const blue = "rgba(53, 68, 145, 1)";
  const lightBlue = "rgb(59 130 246)";

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const criarHomePageNewsletter = useCallback((): void => {
    setOpenCriar(true);
  }, []);

  const handleCriarClose = useCallback((): void => {
    setOpenCriar(false);
  }, []);

  const [openCriar, setOpenCriar] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: blue,
      },
      secondary: {
        main: "#ffffff",
      },
    },
  });

  return (
    <>
      {Mobile ? (
        <Box className="NavbarHomePageNewsletter-Default">
          <p id="NavbarHomePageNewsletter-pTitle">Newsletter</p>
          <ThemeProvider theme={theme}>
            <Box className="NavbarHomePageNewsletter-Box">
              <Paper
                sx={{
                  bgcolor: "white",
                  p: "2px 4px",
                  boxShadow: 5,
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <InputBase
                  sx={{
                    ml: 1,
                    flex: 1,
                    fontWeight: 500,
                  }}
                  placeholder="Pesquisar"
                  onChange={handleShowQueryState}
                />
                <SearchOutlinedIcon color="primary" />
              </Paper>
              <IconButton
                onClick={criarHomePageNewsletter}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: 2,
                  boxShadow: 5,
                  gap: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <AddCircleOutlineOutlinedIcon />
                <p id="NavbarHomePageNewsletter-pButton">Nova newsletter</p>
              </IconButton>
              <Modal open={openCriar} onClose={handleCriarClose}>
                <HomePageNewsletterModal
                  HomePageNewsletterRefetch={HomePageNewsletterRefetch}
                  handleCriarClose={handleCriarClose}
                  handleClickAdd={handleClickAdd}
                />
              </Modal>
            </Box>
          </ThemeProvider>
        </Box>
      ) : (
        <Box className="NavbarHomePageNewsletter-Default">
          <p id="NavbarHomePageNewsletter-pTitle">Newsletter</p>
          <Box className="NavbarHomePageNewsletter-Box">
            <ThemeProvider theme={theme}>
              <Tooltip title="Account settings">
                <IconButton
                  className="CreateButton-HomePageNewsletter"
                  onClick={handleClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={{
                    color: "white",
                    backgroundColor: blue,
                    "&:hover": { backgroundColor: "white", color: blue },
                    borderRadius: 2,
                    boxShadow: 5,
                    gap: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  size="small"
                >
                  <MoreVertOutlinedIcon />
                </IconButton>
              </Tooltip>
            </ThemeProvider>
          </Box>
          <ThemeProvider theme={theme}>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  padding: "4px 8px",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  "& .MuiAvatar-root": {
                    width: 30,
                    height: 30,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&& .Mui-selected": {
                    backgroundColor: "white",
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Paper
                elevation={0}
                sx={{
                  bgcolor: "white",
                  p: "2px 4px",
                  display: "flex",
                  "&& .Mui-selected": {
                    backgroundColor: "white",
                  },
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <InputBase
                  sx={{
                    ml: 1,
                    flex: 1,
                    fontFamily: "Montserrat, sans-serif",
                    color: "rgba(53, 68, 145, 1",
                    "&& .Mui-selected": {
                      backgroundColor: "white",
                    },
                  }}
                  placeholder="Pesquisar"
                  onChange={handleShowQueryState}
                />
                <SearchOutlinedIcon color="primary" />
              </Paper>
              <Divider sx={{ margin: "8px 0px" }} />
              <MenuItem
                onClick={criarHomePageNewsletter}
                sx={{  gap: 1 }}
              >
                <AddCircleOutlineIcon color="primary" sx={{ fontSize: 22.5 }} />{" "}
                <p id="NavbarHomePageNewsletter-pMenu">
                  Adicionar nova newsletter
                </p>
              </MenuItem>
              <Modal open={openCriar} onClose={handleCriarClose}>
                <HomePageNewsletterModal
                  HomePageNewsletterRefetch={HomePageNewsletterRefetch}
                  handleCriarClose={handleCriarClose}
                  handleClickAdd={handleClickAdd}
                />
              </Modal>
            </Menu>
          </ThemeProvider>
        </Box>
      )}
    </>
  );
}

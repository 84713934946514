import { Box, Card, CardMedia, IconButton } from "@mui/material";
import React from "react";
import "./HeaderNewsPage.css";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import axios from "axios";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import HomePageInterface from "../../../../Portal/types/HomePageInterface";

export default function HeaderNewsPage() {
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  
  const { id } = useParams();

  const data1 = [
    { nr_sequency: 1, dsTitulo: "Noticia 1" },
    { nr_sequency: 2, dsTitulo: "Noticia 2" },
    { nr_sequency: 3, dsTitulo: "Noticia 3" },
  ];

  return (
    <Box className="HeaderNewsPage-OutsideCard">
      <Box className="HeaderNewsPage-Box">
        {data1?.map(val => {
          if (val.nr_sequency === parseInt(id ?? "", 10)) {
            return (
              <p id="HeaderNewsPage-pTitle" key={val.nr_sequency}>
                {val.dsTitulo}
              </p>
            );
          }
        })}
        <Box
          sx={{
            backgroundColor: "rgba(53, 68, 145, 1)",
            height: "0.25em",
            width: "100%",
          }}
        />
      </Box>
    </Box>
  );
}

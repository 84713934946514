import React, { useEffect } from "react";
import "./AppPortal.css";
import { Outlet } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import HeaderMobile from "./layout/Header/HeaderMobile";
import { SidebarNew } from "./layout/Sidebar/SidebarNew";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import { useQuery } from "react-query";
import { SidebarData } from "./layout/Sidebar/SidebarData";
import ProfileInterface from "./types/ProfileInterface";

export default function AppPortal() {
  const mobile = useMediaQuery("(min-width: 901px)");
 
  return (
    <div className="AppPortal">
      {mobile ? (
        <ProSidebarProvider>
          <div className="wrapper">
            <SidebarNew />
            <Outlet />
          </div>
        </ProSidebarProvider>
      ) : (
        <ProSidebarProvider>
          <HeaderMobile />
          <div className="wrapper">
            <Outlet />
          </div>
        </ProSidebarProvider>
      )}
    </div>
  );
}

import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useCallback, useState } from "react";
import {
  Button,
  Box,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
  Alert,
  Snackbar,
  IconButton,
} from "@mui/material";
import { createTheme, styled } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from "react-query";
import CompanyInterface from "../../types/CompanyInterface";
import "./Company.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

interface CompanyModalProps {
  companyRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<CompanyInterface[]>>;
  handleCriarClose(): void;
  handleClickAdd: () => void;
}

export default function CompanyModal({
  companyRefetch,
  handleCriarClose,
  handleClickAdd,
}: CompanyModalProps) {
  const blue = "rgba(53, 68, 145, 1)";
  const [CompanyNomeInput, setCompanyNomeInput] = useState("");
  const [CompanyRazaoInput, setCompanyRazaoInput] = useState("");
  const [CompanyCNPJInput, setCompanyCNPJInput] = useState("");
  const [CompanyStatusInput, setCompanyStatusInput] = useState(1);

  const [CompanyNomeInputError, setCompanyNomeInputError] = useState(false);
  const [CompanyRazaoInputError, setCompanyRazaoInputError] = useState(false);
  const [CompanyCNPJInputError, setCompanyCNPJInputError] = useState(false);
  const [dsMsgInputError, setDsMsgInputError] = useState("");

  const handleCompanyNomeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setCompanyNomeInputError(false);
      }
      setCompanyNomeInput(event.target.value);
    },
    []
  );

  const handleCompanyRazaoInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setCompanyRazaoInputError(false);
      }
      setCompanyRazaoInput(event.target.value);
    },
    []
  );

  const handleCompanyStatusInput = useCallback(
    (event: SelectChangeEvent<number>) => {
      setCompanyStatusInput(parseInt(event.target.value.toString(), 10) ?? 0);
    },
    []
  );

  const maskCNPJ = (value: string) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+$/, "$1");
  };

  const dateFormat = "DD/MM/yyyy HH:mm";
  const daysAdd = 15;
  const scheduleDate = moment().add(daysAdd, "days").format(dateFormat);
  const daysAddVig = 180;

  const addCompany = () => {
    const Company = {
      nm_company: CompanyNomeInput,
      nm_razao_social: CompanyRazaoInput,
      ds_cnpj: CompanyCNPJInput,
      nr_status: CompanyStatusInput,
    };

    axios
      .post("https://api.incortec.com.br:8080/registrationservice/company/add", Company)
      .then(res => {
        companyRefetch();
        handleCriarClose();
        handleClickAdd();
      });
  };

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (CompanyNomeInput === "") {
      setCompanyNomeInputError(true);
      setDsMsgInputError("Favor informar o nome do cliente");
      handleClick();
    } else if (CompanyRazaoInput === "") {
      setCompanyRazaoInputError(true);
      setDsMsgInputError("Favor informar a razão social");
      handleClick();
    } else if (CompanyCNPJInput.match(/^\S{18}$/) === null) {
      setCompanyCNPJInputError(true);
      setDsMsgInputError("Favor informar o CNPJ");
      handleClick();
    } else {
      addCompany();
    }
  }, [
    CompanyNomeInput,
    CompanyRazaoInput,
    CompanyCNPJInput,
    scheduleDate,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
      onKeyDown={event => {
        if (event.key === "Enter") {
          multiFunctions();
        }
      }}
    >
      <Box className="ModalCompany-Main">
        <Box className="ModalCompany-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalCompany-pTitle">Inserir novo cliente</p>
            <IconButton onClick={handleCriarClose}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          <TextField
            error={CompanyNomeInputError}
            fullWidth
            onChange={handleCompanyNomeInput}
            size="small"
            label={<p>Nome fantasia</p>}
            variant="standard"
          ></TextField>
          <TextField
            error={CompanyRazaoInputError}
            fullWidth
            onChange={handleCompanyRazaoInput}
            size="small"
            label={<p>Razão social</p>}
            variant="standard"
          ></TextField>
          <TextField
            error={CompanyCNPJInputError}
            fullWidth
            value={CompanyCNPJInput}
            onChange={useCallback(
              (e: { target: { value: string } }) => {
                if (CompanyCNPJInput !== "") {
                  setCompanyCNPJInputError(false);
                }
                setCompanyCNPJInput(maskCNPJ(e.target.value));
              },
              [CompanyCNPJInput]
            )}
            size="small"
            label={<p>CNPJ</p>}
            variant="standard"
          ></TextField>
          <FormControl fullWidth sx={{ textAlign: "left" }}>
            <InputLabel>
              <p>Status</p>
            </InputLabel>
            <Select
              label={<p>Status</p>}
              defaultValue={CompanyStatusInput}
              onChange={handleCompanyStatusInput}
              fullWidth
              size="small"
              variant="standard"
            >
              <MenuItem value={1}>
                <p>Ativo</p>
              </MenuItem>
              <MenuItem value={0}>
                <p>Inativo</p>
              </MenuItem>
            </Select>
          </FormControl>
          <ThemeProvider theme={theme}>
            <Button
              size="small"
              onClick={multiFunctions}
              disableRipple
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: blue,
                "&:hover": { backgroundColor: "white", color: blue },
                borderRadius: "10px",
                boxShadow: 5,
                textTransform: "none",
                padding: "0.25em 5em"
              }}
            >
              <p id="ModalCompany-pButton">Inserir cliente</p>
            </Button>
          </ThemeProvider>
        </Box>
        <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
          <Alert severity="error" variant="filled">
            {dsMsgInputError}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

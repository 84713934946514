import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useCallback, useState } from "react";
import {
  Button,
  Box,
  Alert,
  Snackbar,
  IconButton,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import axios from "axios";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
  useQuery,
} from "react-query";
import ProfileInterface from "../../types/ProfileInterface";
import "./ProfileUser.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CompanyInterface from "../../types/CompanyInterface";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

interface ProfileUserModalProps {
  profileUserRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ProfileInterface[]>>;
  handleCriarClose: () => void;
  handleClickAdd: () => void;
}

export default function ProfileUserModal({
  profileUserRefetch,
  handleCriarClose,
  handleClickAdd,
}: ProfileUserModalProps) {
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const [ProfileUserName, setProfileUserName] = useState("");
  const [ProfileUserDescr, setProfileUserDescr] = useState("");
  const [ProfileUserCompany, setProfileUserCompany] = useState(0);

  const [ProfileUserNameError, setProfileUserNameError] = useState(false);
  const [ProfileUserDescrError, setProfileUserDescrError] = useState(false);
  const [ProfileUserCompanyError, setProfileUserCompanyError] = useState(false);

  const [dsMsgError, setDsMsgError] = useState("");

  const handleProfileUserName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setProfileUserNameError(false);
      }
      setProfileUserName(event.target.value);
    },
    []
  );

  const handleProfileUserDescr = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setProfileUserDescrError(false);
      }
      setProfileUserDescr(event.target.value);
    },
    []
  );

  const handleProfileUserCompany = useCallback((event: SelectChangeEvent<number>) => {
    if (event.target.value !== 0) {
      setProfileUserCompanyError(false);
    }
    setProfileUserCompany(parseInt(event.target.value.toString(), 10) ?? 0);
  }, []);


  const addProfileUser = useCallback(() => {
    const ProfileUser = {
      nm_canal: ProfileUserName,
      ds_profile: ProfileUserDescr,
      company: {
        nr_sequency: ProfileUserCompany,
      },
    };

    axios
      .post(
        "https://api.incortec.com.br:8080/registrationservice/profileuser/add",
        ProfileUser
      )
      .then(() => {
        profileUserRefetch();
        handleCriarClose();
        handleClickAdd();
      });
  }, [ProfileUserName, ProfileUserDescr, ProfileUserCompany]);

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (ProfileUserName === "") {
      setProfileUserNameError(true);
      setDsMsgError("Favor informar o nome do Perfil");
      handleClick();
    } else if (ProfileUserDescr === "") {
      setProfileUserDescrError(true);
      setDsMsgError("Favor informar a descrição");
      handleClick();
    } else if (ProfileUserCompany === 0) {
      setProfileUserCompanyError(true);
      setDsMsgError("Favor informar o cliente");
      handleClick();
    } else {
      addProfileUser();
    }
  }, [ProfileUserName, ProfileUserDescr, ProfileUserCompany]);

  const { data: repoCompany } = useQuery<CompanyInterface[]>(
    "Company",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/company/list"
      );
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
      onKeyDown={event => {
        if (event.key === "Enter") {
          multiFunctions();
        }
      }}
    >
      <Box className="ModalProfileUser-Main">
        <Box className="ModalProfileUser-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalProfileUser-pTitle">Inserir novo perfil</p>
            <IconButton onClick={handleCriarClose}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          <TextField
            error={ProfileUserNameError}
            fullWidth
            onChange={handleProfileUserName}
            size="small"
            label={<p>Nome do perfil</p>}
            variant="standard"
          ></TextField>
          <TextField
            error={ProfileUserDescrError}
            fullWidth
            onChange={handleProfileUserDescr}
            size="small"
            label={<p>Descrição do perfil</p>}
            variant="standard"
          ></TextField>
          <FormControl fullWidth sx={{ textAlign: "left" }}>
            <InputLabel>{<p>Cliente</p>}</InputLabel>
            <Select
              error={ProfileUserCompanyError}
              label={<p>Cliente</p>}
              onChange={handleProfileUserCompany}
              fullWidth
              color="primary"
              variant="standard"
            >
              {repoCompany?.map(val => {
                return (
                  <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                    {val.nm_company}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <ThemeProvider theme={theme}>
            <Button
              size="small"
              onClick={multiFunctions}
              disableRipple
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: blue,
                "&:hover": { backgroundColor: "white", color: blue },
                borderRadius: "10px",
                boxShadow: 5,
                textTransform: "none",
                padding: "0.25em 5em",
              }}
            >
              <p id="ModalProfileUser-pButton">Inserir perfil</p>
            </Button>
          </ThemeProvider>
        </Box>
        <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
          <Alert severity="error" variant="filled">
            {dsMsgError}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
} from "@mui/material";
import React from "react";
import "./Newsletter.css";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Footer from "../Footer/Footer";
import HomePageInterface from "../../../Portal/types/HomePageInterface";
import axios from "axios";
import { useQuery } from "react-query";
import { useHref } from "react-router-dom";

export default function Newsletter() {
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;


  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <Box className="Newsletter-OutsideCard">
        <Box className="Newsletter-Box">
          <p id="Newsletter-pTitle">CONTATO</p>
          <Box
            sx={{
              backgroundColor: "rgba(53, 68, 145, 1)",
              height: "0.25em",
              width: "100%",
            }}
          />
        </Box>
      </Box>
      {/* <Box className="Newsletter-GroupCard">
        {data?.map(val => {
          if (val.nr_sequency === data.length) {
            return (
              <Card className="Newsletter-MainCard" key={val.nr_sequency}>
                <CardActionArea
                  href={"NewsletterPage/" + val.nr_sequency}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box className="Newsletter-MainCardBox">
                    <p id="Newsletter-pTitleCard">{val.dsTitulo}</p>
                    <IconButton href={"NewsletterPage/" + val.nr_sequency}>
                      <KeyboardArrowRightOutlinedIcon id="Newsletter-ArrowIcon" />
                    </IconButton>
                  </Box>
                </CardActionArea>
              </Card>
            );
          }
          return "";
        })}
        <Box className="Newsletter-SubGroupCard">
          {data?.map(val => {
            if (
              val.nr_sequency === data.length - 1 ||
              val.nr_sequency === data.length - 2 ||
              val.nr_sequency === data.length - 3
            ) {
              return (
                <Card className="Newsletter-Card" key={val.nr_sequency}>
                  <CardActionArea
                    href={"NewsletterPage/" + val.nr_sequency}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box className="Newsletter-CardBox">
                      <p id="Newsletter-pTitleCard">{val.dsTitulo}</p>
                      <KeyboardArrowRightOutlinedIcon id="Newsletter-ArrowIcon" />
                    </Box>
                  </CardActionArea>
                </Card>
              );
            }
            return "";
          })}
        </Box>
      </Box> */}
    </Box>
  );
}

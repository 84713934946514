import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  Accordion,
  AccordionDetails,
  Alert,
  Box,
  CircularProgress,
  createTheme,
  IconButton,
  Modal,
  Snackbar,
  TextField,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import ExpensesInterface from "../../types/ExpensesInterface";
import axios from "axios";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { CurrentUserContext } from "../../contexts/ContextProvider";
import Navbar from "./Navbar/Navbar";
import "./Despesas.css";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from "react-query";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DespesasEditModal from "./DespesasEditModal";
import ModeIcon from "@mui/icons-material/Mode";
import CompanyInterface from "../../types/CompanyInterface";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import DespesasModal from "./DespesasModal";
import { DateObject } from "react-multi-date-picker";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

export default function Despesas() {
  const mobile = useMediaQuery("(min-width: 901px)");
  const [idSelecionado, setIdSelecionado] = useState<number>(0);
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const blue = "rgba(53, 68, 145, 1)";
  const white = "#ffffff";

  const loggedUserId =
    parseInt(localStorage.getItem("currentUserId") ?? "-1", 10) ?? -1;

  const [despesasDate, setdespesasDate] = useState<DateObject>(
    new DateObject()
  );
  const [dataExpenses, setDataExpenses] = useState<ExpensesInterface[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const consultas = async (despesasDate: DateObject) =>
    Promise.all([
      axios.get(
        "https://api.incortec.com.br:8080/registrationservice/expenses/listByDay/" +
        despesasDate.format("YYYY-MM-DD")
      ),
    ])
      .then(response => {
        if (response[0].data === undefined) {
          setSemRegistros(false);
        }
        const validarRegistros = response[0].data?.some((val: any) => {
          return loggedUserId === val.user.nr_sequency;
        });
        setDataExpenses(response[0].data);
        setSemRegistros(validarRegistros!);
        setIsLoading(false);
        return response[0].data;
      })
      .catch(err => {
        console.log(err);
      });

  useEffect(() => {
    consultas(despesasDate);
  }, []);

  const [semRegistros, setSemRegistros] = useState(false);

  const handleCalendar = (value: DateObject) => {
    consultas(value);
    setdespesasDate(value);
    const validarRegistros = dataExpenses?.some(val => {
      return (
        value!.format("DD/MM/YYYY") === val.dt_expenses &&
        loggedUserId === val.user.nr_sequency
      );
    });
    setSemRegistros(validarRegistros!);
  };

  // --- Modal --- //

  const [openCriar, setOpenCriar] = useState(false);
  const AddDespesas = useCallback((): void => {
    setOpenCriar(true);
  }, []);

  const handleCriarClose = useCallback((): void => {
    setOpenCriar(false);
  }, []);

  // --- Snackbar --- //

  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const handleClickAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleClickDelete = useCallback(() => {
    setOpenDelete(true);
  }, []);

  const handleClickUpdate = useCallback(() => {
    setOpenUpdate(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenAdd(false);
    setOpenDelete(false);
    setOpenUpdate(false);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: mobile ? "100svh" : "92.5svh",
          gap: "1em",
          padding: "0.5em",
        }}
      >
        <Navbar
          despesasDate={despesasDate}
          DespesasRefetch={consultas}
          handleCalendar={handleCalendar}
          handleClickAdd={handleClickAdd}
          setSemRegistros={setSemRegistros}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
        <Box className="Despesas-Main">
          {semRegistros === false ? (
            <Box
              sx={{
                color: "white",
                gap: 1,
                fontSize: "1em",
                fontWeight: 500,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {despesasDate === undefined ? (
                <>
                  <IconButton
                    onClick={AddDespesas}
                    sx={{
                      color: "white",
                      backgroundColor: blue,
                      "&:hover": { backgroundColor: "white", color: blue },
                      borderRadius: 5,
                      boxShadow: 5,
                      fontWeight: 500,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    size="large"
                  >
                    <AddToPhotosOutlinedIcon sx={{ fontSize: "4em" }} />
                  </IconButton>
                  <p>Nenhuma data selecionada</p>
                </>
              ) : (
                <>
                  <IconButton
                    onClick={AddDespesas}
                    sx={{
                      color: "white",
                      backgroundColor: blue,
                      "&:hover": { backgroundColor: "white", color: blue },
                      borderRadius: 5,
                      boxShadow: 5,
                      fontWeight: 500,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    size="large"
                  >
                    <AddToPhotosOutlinedIcon sx={{ fontSize: "4em" }} />
                  </IconButton>
                  <Modal open={openCriar} onClose={handleCriarClose}>
                    <DespesasModal
                      despesasDate={despesasDate}
                      handleCriarClose={handleCriarClose}
                      handleClickAdd={handleClickAdd}
                      DespesasRefetch={consultas}
                      setSemRegistros={setSemRegistros}
                      setIsLoading={setIsLoading}
                    />
                  </Modal>
                  <p>Sem registros</p>
                </>
              )}
            </Box>
          ) : (
            <Box className="Despesas-Wrapper">
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <CircularProgress sx={{ color: "white" }} size="3em" />
                </Box>
              ) : (
                dataExpenses?.map(val => {
                  if (
                    despesasDate!.format("DD/MM/YYYY") === val.dt_expenses &&
                    loggedUserId === val.user.nr_sequency
                  ) {
                    return (
                      <Row
                        key={val.id}
                        idSelecionado={idSelecionado}
                        setIdSelecionado={setIdSelecionado}
                        row={val}
                        DespesasRefetch={consultas}
                        handleClickDelete={handleClickDelete}
                        handleClickUpdate={handleClickUpdate}
                        despesasDate={despesasDate}
                        setSemRegistros={setSemRegistros}
                        setIsLoading={setIsLoading}
                      />
                    );
                  }
                  return "";
                })
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Snackbar
        open={openAdd}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity="success" variant="filled">
          Despesa adicionada com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openDelete}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<DeleteOutlineOutlinedIcon />}
          severity="success"
          variant="filled"
          color="error"
        >
          Despesa excluída com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openUpdate}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<TaskOutlinedIcon />}
          severity="success"
          variant="filled"
          color="info"
        >
          Despesa atualizada com sucesso
        </Alert>
      </Snackbar>
    </>
  );
}

function Row(props: {
  idSelecionado: number;
  setIdSelecionado: React.Dispatch<React.SetStateAction<number>>;
  row: ExpensesInterface;
  handleClickDelete: () => void;
  handleClickUpdate: () => void;
  DespesasRefetch: (despesasDate: DateObject) => void;
  despesasDate: DateObject;
  setSemRegistros: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    idSelecionado,
    setIdSelecionado,
    row,
    DespesasRefetch,
    handleClickDelete,
    handleClickUpdate,
    despesasDate,
    setSemRegistros,
    setIsLoading,
  } = props;
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const mobile = useMediaQuery("(min-width: 901px)");
  const [openModal, setOpenModal] = useState(false);
  const blue = "rgba(53, 68, 145, 1)";
  const white = "#ffffff";

  const handleDelete = useCallback(() => {
    fetch(
      "https://api.incortec.com.br:8080/registrationservice/expenses/remove/" + row.id,
      {
        method: "delete",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(retorno => retorno.json())
      .then(() => {
        forceUpdate();
        DespesasRefetch(despesasDate);
        setIsLoading(true);
        handleClickDelete();
      });
  }, [row.id]);

  const handleCloseEdit = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenEdit = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setOpenModal(true);
  }, []);

  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const { data: dataCompany } = useQuery<CompanyInterface[]>(
    "Company",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/company/list"
      );

      return response.data;
    },
    {
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );
  const splitCurrency = row.vl_expenses.toString().split(".");
  const formatCurrency =
    splitCurrency[1] === undefined
      ? `${splitCurrency[0].replace(/(\d)()(?=(\d{3})+(?!\d))/g, "$1.$2")}`
      : `${splitCurrency[0].replace(/(\d)()(?=(\d{3})+(?!\d))/g, "$1.$2")},${splitCurrency[1]
      }`;

  return (
    <>
      <Accordion
        key={row.id}
        sx={{
          width: "100%",
          borderRadius: "10px 10px 10px 10px !important",
          boxShadow: 5,
          backgroundColor: "white",
        }}
        disableGutters
      >
        <AccordionDetails
          sx={{
            padding: "12.5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
              color: blue,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                maxWidth: "90%",
                height: "100%",
                justifyContent: "space-between",
                gap: "1em",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  height: "100%",
                  width: "100%",
                  gap: mobile ? "2.5em" : "0.75em",
                }}
              >
                <Box className="Despesas-Layout">
                  <p id="Despesas-pTitle">Valor</p>
                  <p id="Despesas-pName">{`R$ ${formatCurrency}`}</p>
                </Box>
                <Box className="Despesas-Layout">
                  <p id="Despesas-pTitle">Cliente</p>
                  {dataCompany?.map(val => {
                    if (row.company.nr_sequency === val.nr_sequency) {
                      return (
                        <p id="Despesas-pName" key={val.nr_sequency}>
                          {val.nm_company}
                        </p>
                      );
                    }
                    return "";
                  })}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  height: "100%",
                  width: "100%",
                  gap: mobile ? "2.5em" : "0.75em",
                }}
              >
                <Box className="Despesas-Layout">
                  <p id="Despesas-pTitle">Descrição</p>
                  <p id="Despesas-pName">{row.ds_expenses}</p>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                height: "100%",
              }}
            >
              <IconButton size="small">
                <ModeIcon
                  sx={{
                    color: blue,
                  }}
                  onClick={handleOpenEdit}
                />
                <Modal open={openModal} onClose={handleCloseEdit}>
                  <DespesasEditModal
                    row={row}
                    handleCloseEdit={handleCloseEdit}
                    DespesasRefetch={DespesasRefetch}
                    despesasDate={despesasDate}
                    handleClickUpdate={handleClickUpdate}
                    setSemRegistros={setSemRegistros}
                    setIsLoading={setIsLoading}
                  />
                </Modal>
              </IconButton>
              <IconButton size="small">
                <DeleteIcon
                  sx={{
                    color: blue,
                  }}
                  onClick={handleDelete}
                />
              </IconButton>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "../../../Components/card/Card";
// Custom components
import BarChart from "../../../Components/charts/BarChart";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { MdOutlinePerson } from "react-icons/md";
import CenterFocusStrongOutlinedIcon from "@mui/icons-material/CenterFocusStrongOutlined";
import React, { useState, useRef } from "react";
import {
  barChartOptionsConsumption,
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "../charts";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import { useQuery } from "react-query";
import ActivityInterface from "../../../../../types/ActivityInterface";
import axios from "axios";
import { CurrentUserContext } from "../../../../../../../contexts/ContextProvider";
import _ from "lodash";
import dayjs from "dayjs";
import LineChart from "../../../Components/charts/LineChart";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";

interface BarChartInterface {
  graphReady: boolean;
  dataFinal: React.MutableRefObject<
    {
      name: string;
      data: number[];
    }[]
  >;
}

export default function GraficoBarraDiagnostico({
  graphReady,
  dataFinal,
}: BarChartInterface) {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  return (
    <Card
      alignItems="center"
      flexDirection="column"
      w="100%"
      boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
    >
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Diagnóstico
        </Text>
        <Menu isOpen={isOpen1} onClose={onClose1}>
          <MenuButton
            alignItems="center"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            w="37px"
            h="37px"
            lineHeight="100%"
            borderRadius="10px"
            onClick={onOpen1}
            boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
          >
            <Icon
              as={MoreVertOutlinedIcon}
              color={iconColor}
              w="24px"
              h="24px"
            />
          </MenuButton>
          <MenuList
            w="150px"
            minW="unset"
            maxW="150px !important"
            border="transparent"
            backdropFilter="blur(63px)"
            bg={bgList}
            boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
            borderRadius="20px"
            p="15px"
          >
            <MenuItem
              transition="0.2s linear"
              color={textColor}
              _hover={textHover}
              p="0px"
              borderRadius="8px"
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              mb="10px"
              onClick={onOpen2}
            >
              <Flex alignItems="center" flexDirection="row">
                <Icon as={ZoomOutMapOutlinedIcon} h="16px" w="16px" me="8px" />
                <Text fontSize="sm" fontWeight="400">
                  Maximizar
                </Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Drawer isOpen={isOpen2} onClose={onClose2}>
        <DrawerOverlay />
        <DrawerContent minH="100svh" maxH="100svh" minW="95svw" maxW="95svw">
          <DrawerHeader>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <p>Diagnóstico</p>
              <Menu isOpen={isOpen1} onClose={onClose1}>
                <MenuButton
                  alignItems="center"
                  justifyContent="center"
                  bg={bgButton}
                  _hover={bgHover}
                  _focus={bgFocus}
                  _active={bgFocus}
                  w="37px"
                  h="37px"
                  lineHeight="100%"
                  borderRadius="10px"
                  onClick={onOpen1}
                  boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                >
                  <Icon
                    as={MoreVertOutlinedIcon}
                    color={iconColor}
                    w="24px"
                    h="24px"
                  />
                </MenuButton>
                <MenuList
                  w="150px"
                  minW="unset"
                  maxW="150px !important"
                  border="transparent"
                  backdropFilter="blur(63px)"
                  bg={bgList}
                  boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                  borderRadius="20px"
                  p="15px"
                >
                  <MenuItem
                    transition="0.2s linear"
                    color={textColor}
                    _hover={textHover}
                    p="0px"
                    borderRadius="8px"
                    _active={{
                      bg: "transparent",
                    }}
                    _focus={{
                      bg: "transparent",
                    }}
                    mb="10px"
                    onClick={onClose2}
                  >
                    <Flex alignItems="center" flexDirection="row">
                      <Icon
                        as={ZoomInMapOutlinedIcon}
                        h="16px"
                        w="16px"
                        me="8px"
                      />
                      <Text fontSize="sm" fontWeight="400">
                        Minimizar
                      </Text>
                    </Flex>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </DrawerHeader>
          <DrawerBody overflow="hidden">
            {graphReady && (
              <BarChart
                chartData={dataFinal.current}
                chartOptions={barChartOptionsConsumption}
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box h="240px" mt="auto" w="100%">
        {graphReady && (
          <BarChart
            chartData={dataFinal.current}
            chartOptions={barChartOptionsConsumption}
          />
        )}
      </Box>
    </Card>
  );
}

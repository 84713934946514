import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  IconButton,
  Modal,
  Snackbar,
  TextField,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useState } from "react";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from "react-query";
import HomePageInterface from "../../types/HomePageInterface";
import "./HomePageNovidades.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";

export default function HomePageNovidadesEditModal(props: {
  row: HomePageInterface;
  handleCloseEdit: () => void;
  HomePageNovidadesRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<HomePageInterface[]>>;
  handleClickUpdate: () => void;
}) {
  const { row, handleCloseEdit, HomePageNovidadesRefetch, handleClickUpdate } =
    props;
  const blue = "rgba(53, 68, 145, 1)";

  const [openConteudo, setOpenConteudo] = useState(false);

  const handleConteudo = useCallback((): void => {
    setOpenConteudo(true);
  }, []);

  const handleConteudoClose = useCallback((): void => {
    setOpenConteudo(false);
  }, []);

  const [HomePageNovidadesSequence] = useState(row.nr_sequency);
  const [HomePageNovidadesTitulo, setHomePageNovidadesTitulo] = useState(
    row.dsTitulo
  );
  const [HomePageNovidadesConteudo1, setHomePageNovidadesConteudo1] = useState(
    row.dsConteudo
  );
  const [HomePageNovidadesConteudo2, setHomePageNovidadesConteudo2] = useState(
    row.dsConteudo2
  );
  const [HomePageNovidadesConteudo3, setHomePageNovidadesConteudo3] = useState(
    row.dsConteudo3
  );
  const [HomePageNovidadesConteudo4, setHomePageNovidadesConteudo4] = useState(
    row.dsConteudo4
  );
  const [HomePageNovidadesConteudo5, setHomePageNovidadesConteudo5] = useState(
    row.dsConteudo5
  );
  const [HomePageNovidadesAdicional, setHomePageNovidadesAdicional] = useState(
    row.dsAdicional
  );

  const [HomePageNovidadesTituloError, setHomePageNovidadesTituloError] =
    useState(false);
  const [HomePageNovidadesConteudoError, setHomePageNovidadesConteudoError] =
    useState(false);
  const [HomePageNovidadesAdicionalError, setHomePageNovidadesAdicionalError] =
    useState(false);
  const [dsMsgError, setDsMsgError] = useState("");

  const handleHomePageNovidadesTitulo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesTituloError(false);
      }
      setHomePageNovidadesTitulo(event.target.value);
    },
    []
  );

  const handleHomePageNovidadesConteudo1 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesConteudoError(false);
      }
      setHomePageNovidadesConteudo1(event.target.value);
    },
    []
  );

  const handleHomePageNovidadesConteudo2 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesConteudoError(false);
      }
      setHomePageNovidadesConteudo2(event.target.value);
    },
    []
  );

  const handleHomePageNovidadesConteudo3 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesConteudoError(false);
      }
      setHomePageNovidadesConteudo3(event.target.value);
    },
    []
  );

  const handleHomePageNovidadesConteudo4 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesConteudoError(false);
      }
      setHomePageNovidadesConteudo4(event.target.value);
    },
    []
  );

  const handleHomePageNovidadesConteudo5 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesConteudoError(false);
      }
      setHomePageNovidadesConteudo5(event.target.value);
    },
    []
  );

  const handleHomePageNovidadesAdicional = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesAdicionalError(false);
      }
      setHomePageNovidadesAdicional(event.target.value);
    },
    []
  );

  const updateHomePageNovidades = () => {
    const HomePageNovidades = {
      nr_sequency: HomePageNovidadesSequence,
      dsTitulo: HomePageNovidadesTitulo,
      dsConteudo: HomePageNovidadesConteudo1,
      dsConteudo2: HomePageNovidadesConteudo2,
      dsConteudo3: HomePageNovidadesConteudo3,
      dsConteudo4: HomePageNovidadesConteudo4,
      dsConteudo5: HomePageNovidadesConteudo5,
      dsAdicional: HomePageNovidadesAdicional,
    };

    axios
      .post(
        "https://api.incortec.com.br:8080/registrationservice/homepagenews/update",
        HomePageNovidades
      )
      .then(() => {
        HomePageNovidadesRefetch();
        handleCloseEdit();
        handleClickUpdate();
      });
  };

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (HomePageNovidadesTitulo === "") {
      setHomePageNovidadesTituloError(true);
      setDsMsgError("Favor informar o nome do HPS");
      handleClick();
    } else if (HomePageNovidadesConteudo1 === "") {
      setHomePageNovidadesConteudoError(true);
      setDsMsgError("Favor informar a descrição");
      handleClick();
    } else if (HomePageNovidadesAdicional === "") {
      setHomePageNovidadesAdicionalError(true);
      setDsMsgError("Favor informar a adicional");
      handleClick();
    } else {
      updateHomePageNovidades();
    }
  }, [
    HomePageNovidadesTitulo,
    HomePageNovidadesConteudo1,
    HomePageNovidadesConteudo2,
    HomePageNovidadesConteudo3,
    HomePageNovidadesConteudo4,
    HomePageNovidadesConteudo5,
    HomePageNovidadesAdicional,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
      onKeyDown={event => {
        if (event.key === "Enter") {
          multiFunctions();
        }
      }}
    >
      <Box className="ModalHomePageNovidades-Main">
        <Box className="ModalHomePageNovidades-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalHomePageNovidades-pTitle">Alterar notícia</p>
            <IconButton onClick={handleCloseEdit}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          <TextField
            error={HomePageNovidadesTituloError}
            fullWidth
            defaultValue={HomePageNovidadesTitulo}
            onChange={handleHomePageNovidadesTitulo}
            size="small"
            label={<p>Título da notícia</p>}
            variant="standard"
          />
          <Button
            onClick={handleConteudo}
            size="small"
            disableRipple
            variant="outlined"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
              color: HomePageNovidadesConteudoError ? "red" : blue,
              borderColor: HomePageNovidadesConteudoError ? "red" : blue,
              "&:hover": { backgroundColor: blue, color: "white" },
              borderRadius: "10px",
              textTransform: "none",
              padding: "0.25em 5em",
            }}
            fullWidth
          >
            <PostAddOutlinedIcon />
            <p id="ModalHomePageNovidades-pButton">Adicionar conteúdo</p>
          </Button>
          <Modal open={openConteudo} onClose={handleConteudoClose}>
            <ConteudoModal
              handleHomePageNovidadesConteudo1={
                handleHomePageNovidadesConteudo1
              }
              handleHomePageNovidadesConteudo2={
                handleHomePageNovidadesConteudo2
              }
              handleHomePageNovidadesConteudo3={
                handleHomePageNovidadesConteudo3
              }
              handleHomePageNovidadesConteudo4={
                handleHomePageNovidadesConteudo4
              }
              handleHomePageNovidadesConteudo5={
                handleHomePageNovidadesConteudo5
              }
              handleConteudoClose={handleConteudoClose}
              row={row}
            />
          </Modal>
          <TextField
            error={HomePageNovidadesAdicionalError}
            fullWidth
            defaultValue={HomePageNovidadesAdicional}
            onChange={handleHomePageNovidadesAdicional}
            size="small"
            label={<p>Adicional</p>}
            variant="standard"
            multiline
            rows={2}
            maxRows={2}
          />
          <Button
            size="small"
            onClick={multiFunctions}
            disableRipple
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: blue,
              "&:hover": { backgroundColor: "white", color: blue },
              borderRadius: "10px",
              boxShadow: 5,
              textTransform: "none",
              padding: "0.25em 5em",
            }}
          >
            <p id="ModalHomePageNovidades-pButton">Alterar notícia</p>
          </Button>
        </Box>
        <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
          <Alert severity="error" variant="filled">
            {dsMsgError}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

function ConteudoModal(props: {
  handleHomePageNovidadesConteudo1: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleHomePageNovidadesConteudo2: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleHomePageNovidadesConteudo3: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleHomePageNovidadesConteudo4: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleHomePageNovidadesConteudo5: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleConteudoClose: () => void;
  row: HomePageInterface;
}) {
  const {
    handleHomePageNovidadesConteudo1,
    handleHomePageNovidadesConteudo2,
    handleHomePageNovidadesConteudo3,
    handleHomePageNovidadesConteudo4,
    handleHomePageNovidadesConteudo5,
    handleConteudoClose,
    row,
  } = props;

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
    >
      <Box className="ModalHomePageNovidades-Main">
        <Box className="ModalHomePageNovidades-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalHomePageNovidades-pTitle">Conteúdo</p>
            <IconButton onClick={handleConteudoClose}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>1° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNovidadesConteudo1}
                defaultValue={row.dsConteudo}
                size="small"
                label={<p>Conteúdo da notícia</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>2° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNovidadesConteudo2}
                defaultValue={row.dsConteudo2}
                size="small"
                label={<p>Conteúdo da notícia</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>3° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNovidadesConteudo3}
                defaultValue={row.dsConteudo3}
                size="small"
                label={<p>Conteúdo da notícia</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>4° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNovidadesConteudo4}
                defaultValue={row.dsConteudo4}
                size="small"
                label={<p>Conteúdo da notícia</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>5° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNovidadesConteudo5}
                defaultValue={row.dsConteudo5}
                size="small"
                label={<p>Conteúdo da notícia</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
}

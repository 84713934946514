import React, {
  SyntheticEvent,
  useCallback,
  useReducer,
  useState,
} from "react";
import Navbar from "./Navbar/Navbar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  SelectChangeEvent,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeIcon from "@mui/icons-material/Mode";
import axios from "axios";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from "react-query";
import UserEditModal from "./UserEditModal";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import UserInterface from "../../types/UserInterface";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./User.css";
import ProfileInterface from "../../types/ProfileInterface";

export default function CollapsibleTable() {
  const mobile = useMediaQuery("(min-width: 901px)");
  const [idSelecionado, setIdSelecionado] = useState<number>(0);
  const [showQuery, setShowQuery] = useState<string>("");
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const handleShowQueryState = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setShowQuery(e.target.value);
    },
    []
  );

  const [semRegistros, setSemRegistros] = useState(true);

  const { data, refetch: userRefetch } = useQuery<UserInterface[]>(
    "User",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/user/list"
      );
      setSemRegistros(false);
      return response.data;
    },
    {
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  const textoPesquisa = showQuery ? showQuery : "";
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const [FilterCompany, setFilterCompany] = useState(0);

  const handleFilterCompany = useCallback(
    (event: SelectChangeEvent<number>) => {
      setFilterCompany(parseInt(event.target.value.toString(), 10) ?? 0);
    },
    []
  );

  const handleClickAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleClickDelete = useCallback(() => {
    setOpenDelete(true);
  }, []);

  const handleClickUpdate = useCallback(() => {
    setOpenUpdate(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenAdd(false);
    setOpenDelete(false);
    setOpenUpdate(false);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: mobile ? "100svh" : "92.5svh",
          gap: "1em",
          padding: "0.5em",
        }}
      >
        <Navbar
          handleShowQueryState={handleShowQueryState}
          userRefetch={userRefetch}
          handleClickAdd={handleClickAdd}
          handleFilterCompany={handleFilterCompany}
        />
        <Box className="User-Main">
          {semRegistros ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress sx={{ color: "white" }} size="3em" />
            </Box>
          ) : (
            <Box className="User-Wrapper">
              {data?.map(val => {
                if (
                  val.nm_person
                    .toLowerCase()
                    .includes(textoPesquisa.toLowerCase())
                ) {
                  if (val.company.nr_sequency === FilterCompany) {
                    return (
                      <Row
                        key={val.nr_sequency}
                        idSelecionado={idSelecionado}
                        setIdSelecionado={setIdSelecionado}
                        row={val}
                        userRefetch={userRefetch}
                        handleClickDelete={handleClickDelete}
                        handleClickUpdate={handleClickUpdate}
                      />
                    );
                  } else if (FilterCompany === 0) {
                    return (
                      <Row
                        key={val.nr_sequency}
                        idSelecionado={idSelecionado}
                        setIdSelecionado={setIdSelecionado}
                        row={val}
                        userRefetch={userRefetch}
                        handleClickDelete={handleClickDelete}
                        handleClickUpdate={handleClickUpdate}
                      />
                    );
                  } else {
                    return;
                  }
                }
                return "";
              })}
            </Box>
          )}
        </Box>
      </Box>
      <Snackbar
        open={openAdd}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity="success" variant="filled">
          Usuário adicionado com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openDelete}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<DeleteOutlineOutlinedIcon />}
          severity="success"
          variant="filled"
          color="error"
        >
          Usuário excluído com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openUpdate}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<TaskOutlinedIcon />}
          severity="success"
          variant="filled"
          color="info"
        >
          Usuário atualizado com sucesso
        </Alert>
      </Snackbar>
    </>
  );
}

function Row(props: {
  idSelecionado: number;
  setIdSelecionado: React.Dispatch<React.SetStateAction<number>>;
  row: UserInterface;
  handleClickDelete: () => void;
  handleClickUpdate: () => void;
  userRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<UserInterface[]>>;
}) {
  const {
    idSelecionado,
    setIdSelecionado,
    row,
    userRefetch,
    handleClickDelete,
    handleClickUpdate,
  } = props;
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [openModal, setOpenModal] = useState(false);
  const blue = "rgba(53, 68, 145, 1)";

  const handleRowClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    const idAntigo = idSelecionado;
    const idNovo = row.nr_sequency;
    if (idNovo === idAntigo) {
      setIdSelecionado(0);
    } else {
      setIdSelecionado(idNovo);
    }
  }, []);

  const handleDelete = useCallback(() => {
    fetch(
      "https://api.incortec.com.br:8080/registrationservice/user/remove/" +
        row.nr_sequency,
      {
        method: "delete",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(retorno => retorno.json())
      .then(() => {
        forceUpdate();
        handleClickDelete();
        userRefetch();
      });
  }, [row.nr_sequency]);

  const handleCloseEdit = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenEdit = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setOpenModal(true);
  }, []);

  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  
  const { data: dataProfile } = useQuery<ProfileInterface[]>(
    "ProfileUser",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/profileuser/list"
      );

      return response.data;
    },
    {
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <Accordion
      key={row.nr_sequency}
      sx={{
        width: "100%",
        borderRadius: "10px 10px 10px 10px !important",
        boxShadow: 5,
        backgroundColor: "white",
      }}
      disableGutters
      onClick={handleRowClick}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: blue }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          boxShadow: "0px 0px 15px -5px rgba(0,0,0,0.75)",
          borderRadius: "10px 10px 10px 10px !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              textAlign: "left",
              color: blue,
            }}
          >
            <p id="User-pTitle">Nome</p>
            <p id="User-pName">{row.nm_person}</p>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "8px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: blue,
            width: "50%",
            gap: "1em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              textAlign: "left",
              marginRight: "auto",
              color: blue,
            }}
          >
            <p id="User-pTitle">Email</p>
            <p id="User-pDesc">{row.ds_email}</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              textAlign: "left",
              marginRight: "auto",
              color: blue,
            }}
          >
            <p id="User-pTitle">Telefone</p>
            <p id="User-pDesc">{row.ds_phone}</p>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: blue,
            width: "50%",
            gap: "1em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              textAlign: "left",
              marginRight: "auto",
              color: blue,
            }}
          >
            <p id="User-pTitle">CPF</p>
            <p id="User-pDesc">{row.ds_cpf}</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              textAlign: "left",
              marginRight: "auto",
              color: blue,
            }}
          >
            <p id="User-pTitle">Perfil</p>
            {dataProfile?.map(val => {
              if (row.profile.split(",").includes(val.nr_sequency.toString())) {
                return (
                  <p id="User-pDesc" key={val.nr_sequency}>
                    {val.nm_canal}
                  </p>
                );
              }
              return "";
            })}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <IconButton size="small">
            <ModeIcon
              sx={{
                color: blue,
              }}
              onClick={handleOpenEdit}
            />
            <Modal open={openModal} onClose={handleCloseEdit}>
              <UserEditModal
                row={row}
                userRefetch={userRefetch}
                handleCloseEdit={handleCloseEdit}
                handleClickUpdate={handleClickUpdate}
              />
            </Modal>
          </IconButton>
          <IconButton size="small">
            <DeleteIcon
              sx={{
                color: blue,
              }}
              onClick={handleDelete}
            />
          </IconButton>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

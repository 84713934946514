/* eslint-disable @typescript-eslint/no-var-requires */
// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  Select,
} from "@chakra-ui/react";
// Custom Components
import PropTypes from "prop-types";
import React from "react";
// Assets
const navImage = require("./Navbar.png");
import { MdNotificationsNone, MdInfoOutline } from "react-icons/md";
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import { FaEthereum } from "react-icons/fa";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import "./Navbar.css";

export default function HeaderLinks(props: { secondary: boolean }) {
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  const menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");
  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <Select placeholder="Todos usuários" me="10px" borderRadius="30px">
        <option>Lucas Gabriel Cardoso</option>
        <option>Randolf Grassmann filho</option>
        <option>Liliane Moreira</option>
        <option>Arthur Martins</option>
        <option>Fábio Campigotto</option>
      </Select>
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={toggleColorMode}
      >
        <Icon
          me="10px"
          h="18px"
          w="18px"
          color={navbarIcon}
          as={colorMode === "light" ? IoMdMoon : IoMdSunny}
        />
      </Button>
      <Menu>
        <MenuButton
          p="0px"
          sx={{
            transition: "1.5s",
            "&:hover": { transform: "rotateZ(359deg)" },
          }}
        >
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
            icon={<SettingsOutlinedIcon />}
          />
        </MenuButton>
        <MenuList
          boxShadow="rgba(0, 0, 0, 0.25) 2.5px 2.5px 10px"
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              Configurações
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            {/* <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Profile Settings</Text>
            </MenuItem> */}
            <Flex
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              flexDirection="column"
              alignItems="flex-start"
              borderRadius="8px"
              p="6px 14px"
            >
              <Text fontSize="sm">Estabelecimento</Text>
              <Select placeholder="Todos" w="100%">
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Flex>
            <Flex
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              flexDirection="column"
              alignItems="flex-start"
              borderRadius="8px"
              p="6px 14px"
            >
              <Text fontSize="sm">Convênio</Text>
              <Select placeholder="Todos" w="100%">
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Flex>
            <Flex
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              flexDirection="column"
              alignItems="flex-start"
              borderRadius="8px"
              p="12px 14px"
            >
              <Text fontSize="sm">Procedimento</Text>
              <Select placeholder="Todos" w="100%">
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Flex>
            <MenuItem
              _hover={{ bg: "secondaryGray.900" }}
              _focus={{ bg: "secondaryGray.900" }}
              color="white"
              backgroundColor="secondaryGray.900"
              borderRadius="8px"
              p="6px 14px"
              alignItems="center"
            >
              <Text fontSize="sm">Aplicar filtros</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};

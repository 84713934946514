import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  Accordion,
  AccordionDetails,
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  SelectChangeEvent,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import axios from "axios";
import "./Schedule.css";
import Navbar from "./Navbar/Navbar";
import dayjs from "dayjs";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from "react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import CompanyInterface from "../../types/CompanyInterface";
import ModeIcon from "@mui/icons-material/Mode";
import ScheduleInterface from "../../types/ScheduleInterface";
import ScheduleEditModal from "./ScheduleEditModal";
import { DateObject } from "react-multi-date-picker";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import ScheduleModal from "./ScheduleModal";

export default function Schedule() {
  const mobile = useMediaQuery("(min-width: 901px)");
  const [idSelecionado, setIdSelecionado] = useState<number>(0);

  const blue = "rgba(53, 68, 145, 1)";
  const white = "#ffffff";

  const permission = localStorage.getItem("currentUserPermission") ?? "-1";
  const currentPermission =
    permission.split(",").map(item => {
      return parseInt(item, 10);
    }) ?? 0;

  const loggedUserId =
    parseInt(localStorage.getItem("currentUserId") ?? "-1", 10) ?? -1;
  const [ScheduleMonthPicker, setScheduleMonthPicker] = useState<DateObject>(
    new DateObject()
  );
  const [isLoading, setIsLoading] = useState(true);

  const [ScheduleUser, setScheduleUser] = useState(loggedUserId);

  const [dataSchedule, setDataSchedule] = useState<ScheduleInterface[]>([]);

  const consultas = async (
    ScheduleMonthPicker: DateObject,
    ScheduleUser: number
  ) =>
    Promise.all([
      axios.get(
        "https://api.incortec.com.br:8080/registrationservice/schedule/listByMonthAndId/" +
          ScheduleMonthPicker.year +
          "/" +
          ScheduleMonthPicker.month +
          "/" +
          ScheduleUser
      ),
    ])
      .then(response => {
        setSemRegistros(true);
        if (response[0].data === undefined || response[0].data.length === 0) {
          setSemRegistros(false);
        }
        setScheduleMonthPicker(ScheduleMonthPicker);
        setDataSchedule(response[0].data);
        setIsLoading(false);
        return response[0].data;
      })
      .catch(err => {
        console.log(err);
      });

  useEffect(() => {
    consultas(ScheduleMonthPicker, ScheduleUser);
  }, []);

  const [semRegistros, setSemRegistros] = useState(true);

  const handleUser = useCallback(
    (event: SelectChangeEvent<number>) => {
      setScheduleUser(parseInt(event.target.value.toString(), 10) ?? 0);
      consultas(ScheduleMonthPicker, parseInt(event.target.value.toString(), 10));
    },
    [ScheduleMonthPicker]
  );

  const handleCalendarMonth = (newScheduleMonthPicker: DateObject) => {
    setScheduleMonthPicker(newScheduleMonthPicker!);
    consultas(newScheduleMonthPicker, ScheduleUser);
  };

  // --- Modal --- //

  const AddSchedule = useCallback((): void => {
    setOpenCriar(true);
  }, []);

  const handleCriarClose = useCallback((): void => {
    setOpenCriar(false);
  }, []);

  const [openCriar, setOpenCriar] = useState(false);

  // --- Snackbar --- //

  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const handleClickAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleClickDelete = useCallback(() => {
    setOpenDelete(true);
  }, []);

  const handleClickUpdate = useCallback(() => {
    setOpenUpdate(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenAdd(false);
    setOpenDelete(false);
    setOpenUpdate(false);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: mobile ? "100svh" : "92.5svh",
          padding: "0.5em",
          gap: "1em",
        }}
      >
        <Navbar
          handleClickAdd={handleClickAdd}
          handleCalendarMonth={handleCalendarMonth}
          ScheduleMonthPicker={ScheduleMonthPicker}
          ScheduleRefetch={consultas}
          handleUser={handleUser}
          ScheduleUser={ScheduleUser}
          setSemRegistros={setSemRegistros}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
        <Box className="Schedule-Main">
          {semRegistros === false ? (
            <Box
              sx={{
                color: "white",
                gap: 1,
                fontSize: "1em",
                fontWeight: 500,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={AddSchedule}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: 5,
                  boxShadow: 5,
                  fontWeight: 500,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                size="large"
              >
                <AddToPhotosOutlinedIcon sx={{ fontSize: "4em" }} />
              </IconButton>
              <Modal open={openCriar} onClose={handleCriarClose}>
                <ScheduleModal
                  handleCriarClose={handleCriarClose}
                  handleClickAdd={handleClickAdd}
                  ScheduleUser={ScheduleUser}
                  ScheduleRefetch={consultas}
                  setSemRegistros={setSemRegistros}
                  setIsLoading={setIsLoading}
                />
              </Modal>
              <p>Sem registros</p>
            </Box>
          ) : (
            <Box className="Schedule-Wrapper">
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <CircularProgress sx={{ color: "white" }} size="3em" />
                </Box>
              ) : (
                dataSchedule?.map(val => {
                  return (
                    <Row
                      key={val.id}
                      idSelecionado={idSelecionado}
                      ScheduleMonthPicker={ScheduleMonthPicker}
                      setIdSelecionado={setIdSelecionado}
                      row={val}
                      ScheduleRefetch={consultas}
                      handleClickDelete={handleClickDelete}
                      handleClickUpdate={handleClickUpdate}
                      setSemRegistros={setSemRegistros}
                      setIsLoading={setIsLoading}
                    />
                  );
                })
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Snackbar
        open={openAdd}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity="success" variant="filled">
          Agendamento adicionado com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openDelete}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<DeleteOutlineOutlinedIcon />}
          severity="success"
          variant="filled"
          color="error"
        >
          Agendamento excluído com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openUpdate}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<TaskOutlinedIcon />}
          severity="success"
          variant="filled"
          color="info"
        >
          Agendamento atualizado com sucesso
        </Alert>
      </Snackbar>
    </>
  );
}

function Row(props: {
  idSelecionado: number;
  ScheduleMonthPicker: DateObject;
  setIdSelecionado: React.Dispatch<React.SetStateAction<number>>;
  row: ScheduleInterface;
  handleClickDelete: () => void;
  handleClickUpdate: () => void;
  ScheduleRefetch: (ScheduleMonthPicker: DateObject, ScheduleUser: number) => Promise<any>;
  setSemRegistros: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    row,
    ScheduleMonthPicker,
    ScheduleRefetch,
    handleClickDelete,
    handleClickUpdate,
    setSemRegistros,
    setIsLoading,
  } = props;
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const mobile = useMediaQuery("(min-width: 901px)");
  const [openModal, setOpenModal] = useState(false);
  const blue = "rgba(53, 68, 145, 1)";

  const handleDelete = useCallback(() => {
    fetch(
      "https://api.incortec.com.br:8080/registrationservice/schedule/remove/" + row.id,
      {
        method: "delete",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(retorno => retorno.json())
      .then(() => {
        forceUpdate();
        ScheduleRefetch(ScheduleMonthPicker, row.user.nr_sequency);
        setIsLoading(true);
        handleClickDelete();
      });
  }, [row, ScheduleMonthPicker]);

  const handleCloseEdit = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenEdit = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setOpenModal(true);
  }, []);

  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const { data: dataCompany } = useQuery<CompanyInterface[]>(
    "Company",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/company/list"
      );

      return response.data;
    },
    {
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <>
      <Accordion
        key={row.id}
        sx={{
          width: "100%",
          borderRadius: "10px 10px 10px 10px !important",
          boxShadow: 5,
          backgroundColor: "white",
        }}
        disableGutters
      >
        <AccordionDetails sx={{ padding: "12.5px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
              color: blue,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                maxWidth: "90%",
                height: "100%",
                justifyContent: "space-between",
                gap: "1em",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  height: "100%",
                  width: "100%",
                  gap: mobile ? "2.5em" : "0.75em",
                }}
              >
                <Box className="Schedule-Layout">
                  <p id="Schedule-pTitle">Data início</p>
                  <p id="Schedule-pName">{row.dt_ini}</p>
                </Box>
                <Box className="Schedule-Layout">
                  <p id="Schedule-pTitle">Data final</p>
                  <p id="Schedule-pName">{row.dt_fin}</p>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  height: "100%",
                  width: "100%",
                  gap: mobile ? "2.5em" : "0.75em",
                }}
              >
                <Box className="Schedule-Layout">
                  <p id="Schedule-pTitle">Descrição</p>
                  <p id="Schedule-pName">{row.ds_schedule}</p>
                </Box>
                <Box className="Schedule-Layout">
                  <p id="Schedule-pTitle">Cliente</p>
                  {dataCompany?.map(val => {
                    if (row.company.nr_sequency === val.nr_sequency) {
                      return (
                        <p id="Schedule-pName" key={val.nr_sequency}>
                          {val.nm_company}
                        </p>
                      );
                    }
                    return "";
                  })}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                height: "100%",
              }}
            >
              <IconButton size="small">
                <ModeIcon
                  sx={{
                    color: blue,
                  }}
                  onClick={handleOpenEdit}
                />
                <Modal open={openModal} onClose={handleCloseEdit}>
                  <ScheduleEditModal
                    row={row}
                    handleCloseEdit={handleCloseEdit}
                    ScheduleRefetch={ScheduleRefetch}
                    handleClickUpdate={handleClickUpdate}
                    setSemRegistros={setSemRegistros}
                    setIsLoading={setIsLoading}
                  />
                </Modal>
              </IconButton>
              <IconButton size="small">
                <DeleteIcon
                  sx={{
                    color: blue,
                  }}
                  onClick={handleDelete}
                />
              </IconButton>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

import {
  Box,
  Divider,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Modal,
  Paper,
  TextField,
  ThemeProvider,
  Tooltip,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import "./Navbar.css";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import dayjs, { Dayjs } from "dayjs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ActivityModal from "../ActivityModal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import RAT from "../RAT/RAT";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
  useQuery,
} from "react-query";
import ActivityInterface from "../../../types/ActivityInterface";
import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import UserInterface from "../../../types/UserInterface";
import CompanyInterface from "../../../types/CompanyInterface";
import axios from "axios";

interface NavbarProps {
  activityDate: DateObject;
  handleClickAdd: () => void;
  handleCalendar: (value: any) => void;
  ActivityRefetch: (activityDate: DateObject) => void;
  setSemRegistros: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

export default function Navbar({
  activityDate,
  handleClickAdd,
  handleCalendar,
  ActivityRefetch,
  setSemRegistros,
  setIsLoading,
  isLoading,
}: NavbarProps) {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const days = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  const Mobile = useMediaQuery("(min-width: 901px)");
  const blue = "rgba(53, 68, 145, 1)";
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const AddActivity = useCallback((): void => {
    setOpenCriar(true);
  }, []);

  const handleCriarClose = useCallback((): void => {
    setOpenCriar(false);
  }, []);

  const [openCriar, setOpenCriar] = useState(false);

  const [dataUsers, setDataUsers] = useState<UserInterface[]>();
  const [dataCompany, setDataCompany] = useState<CompanyInterface[]>();
  const [loadRegisters, setLoadRegisters] = useState(true);

  const PrintRAT = useCallback((): void => {
    setOpenRAT(true);
    if (dataUsers === undefined && dataCompany === undefined) {
      Promise.all([
        axios.get("https://api.incortec.com.br:8080/registrationservice/user/list"),
        axios.get("https://api.incortec.com.br:8080/registrationservice/company/list"),
      ])
        .then(response => {
          setDataUsers(response[0].data);
          setDataCompany(response[1].data);
          setLoadRegisters(false);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [dataUsers, dataCompany]);

  const handleRATClose = useCallback((): void => {
    setOpenRAT(false);
  }, []);

  const [openRAT, setOpenRAT] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: blue,
      },
      secondary: {
        main: "#ffffff",
      },
    },
  });

  return (
    <>
      {Mobile ? (
        <Box className="NavbarActivity-Default">
          <p id="NavbarActivity-pTitle">Atividades</p>
          <ThemeProvider theme={theme}>
            <Box className="NavbarActivity-Box">
              <IconButton
                onClick={PrintRAT}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  "&:disabled": { backgroundColor: "#232F6A", color: "gray" },
                  borderRadius: 2,
                  boxShadow: 5,
                  gap: 1,
                  fontSize: "1.25rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                disabled={isLoading}
                size="small"
              >
                <PrintOutlinedIcon />
                <p id="NavbarActivity-pButton">Gerar RAT</p>
              </IconButton>
              <Modal open={openRAT} onClose={handleRATClose}>
                <RAT
                  handleRATClose={handleRATClose}
                  repoCompany={dataCompany}
                  repoUsers={dataUsers}
                  loadRegisters={loadRegisters}
                />
              </Modal>
              <IconButton
                onClick={handleClick}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  "&:disabled": { backgroundColor: "#232F6A", color: "gray" },

                  borderRadius: 2,
                  boxShadow: 5,
                  gap: 1,
                  fontSize: "1.25rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                disabled={isLoading}
                size="small"
              >
                <CalendarMonthOutlinedIcon />
                <p id="NavbarActivity-pButton">
                  {activityDate === undefined
                    ? "Selecionar data"
                    : activityDate.format("DD/MM/YYYY")}
                </p>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    "& .MuiAvatar-root": {
                      width: 30,
                      height: 30,
                      ml: -0.5,
                      mr: 1,
                    },
                    p: "0.5em 0.75em",
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Calendar
                  months={months}
                  weekDays={days}
                  className="rmdp-container blue"
                  value={activityDate}
                  onChange={handleCalendar}
                  format="DD/MM/YYYY"
                />
              </Menu>
              <IconButton
                onClick={AddActivity}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  "&:disabled": { backgroundColor: "#232F6A", color: "gray" },

                  borderRadius: 2,
                  boxShadow: 5,
                  gap: 1,
                  fontSize: "1.25rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                disabled={isLoading}
              >
                <AddCircleOutlineOutlinedIcon />
                <p id="NavbarActivity-pButton">Nova atividade</p>
              </IconButton>
            </Box>
          </ThemeProvider>
        </Box>
      ) : (
        <Box className="NavbarActivity-Default">
          <p id="NavbarActivity-pTitle">Atividades</p>
          <Box className="NavbarActivity-Box">
            <ThemeProvider theme={theme}>
              <Tooltip title="Account settings">
                <IconButton
                  className="CreateButton-Activity"
                  onClick={handleClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? true : undefined}
                  sx={{
                    color: "white",
                    backgroundColor: blue,
                    "&:hover": { backgroundColor: "white", color: blue },
                    borderRadius: 2,
                    boxShadow: 5,
                    gap: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  size="small"
                >
                  <MoreVertOutlinedIcon />
                </IconButton>
              </Tooltip>
            </ThemeProvider>
          </Box>
          <ThemeProvider theme={theme}>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  padding: "4px 8px",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  "& .MuiAvatar-root": {
                    width: 30,
                    height: 30,
                    ml: -0.5,
                    mr: 1,
                  },
                  p: "0.5em 0.75em",
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Calendar
                months={months}
                weekDays={days}
                className="rmdp-container blue"
                value={activityDate}
                onChange={handleCalendar}
                format="DD/MM/YYYY"
              />
              <MenuItem onClick={AddActivity} sx={{ gap: 1 }}>
                <AddCircleOutlineIcon color="primary" sx={{ fontSize: 22.5 }} />
                <p id="NavbarActivity-pMenu">Nova atividade</p>
              </MenuItem>

              <Divider sx={{ margin: "8px 0px" }} />
              <MenuItem onClick={PrintRAT} sx={{ gap: 1 }} disabled={isLoading}>
                <PrintOutlinedIcon color="primary" sx={{ fontSize: 22.5 }} />
                <p id="NavbarActivity-pMenu">Gerar RAT</p>
              </MenuItem>
              <Modal open={openRAT} onClose={handleRATClose}>
                <RAT
                  handleRATClose={handleRATClose}
                  repoCompany={dataCompany}
                  repoUsers={dataUsers}
                  loadRegisters={loadRegisters}
                />
              </Modal>
            </Menu>
          </ThemeProvider>
        </Box>
      )}
      <Modal open={openCriar} onClose={handleCriarClose}>
        <ActivityModal
          activityDate={activityDate}
          handleCriarClose={handleCriarClose}
          handleClickAdd={handleClickAdd}
          ActivityRefetch={ActivityRefetch}
          setSemRegistros={setSemRegistros}
          setIsLoading={setIsLoading}
        />
      </Modal>
    </>
  );
}

import React from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  IconButton,
  Link,
  TextField,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import "./NewsPage.css";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import HomePageInterface from "../../../Portal/types/HomePageInterface";
import HeaderNewsPage from "./Header/HeaderNewsPage";
import Bottom from "../Bottom";
import FooterPage from "../FooterPage/FooterPage";

export default function NewsPage() {
  const mobile = useMediaQuery("(min-width: 901px)");
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const { id } = useParams();

  const data1 = [
    {
      nr_sequency: 1,
      dsTitulo: "EUA criam centros tecnológicos para impulsionar inovação",
      dsConteudo:
        "O governo de Joe Biden divulgou, nesta segunda-feira (23), a lista de 31 centros tecnológicos que serão criados nos Estados Unidos, como parte dos planos de investimento que o presidente está promovendo no âmbito de sua candidatura à reeleição no próximo ano.",
      dsConteudo2:
        "A secretária americana do Comércio, Gina Raimondo, disse que estes centros vão criar bons empregos e melhorar nossa segurança nacional e também nossa segurança econômica. ",
      dsConteudo3:
        "Esses investimentos em inovação (...) apoiarão nossa capacidade de competir e, francamente, de superar o resto do mundo, acrescentou, em entrevista coletiva. ",
      dsConteudo4:
        "Selecionados entre 400 inscrições, os 31 centros poderão solicitar bolsas de até US$ 75 milhões (R$ 378,7 milhões na cotação do dia). Foram criados no marco da Lei dos Chips e da Ciência, aprovada no ano passado e que destina US$ 52 bilhões (R$ 262,6 bilhões na cotação do dia) em subsídios para apoiar a produção de semicondutores no país. ",
      dsConteudo5:
        "Esses componentes cruciais para muitas indústrias (automotiva, eletrônica, defesa etc.) são produzidos, principalmente, na Ásia, e o governo americano transformou em prioridade alavancar a produção interna. ",
      dsAdicional:
        "https://noticias.r7.com/tecnologia-e-ciencia/eua-cria-centros-tecnologicos-para-impulsionar-inovacao-23102023",
    },
    {
      nr_sequency: 2,
      dsTitulo: "EUA criam centros tecnológicos para impulsionar inovação",
      dsConteudo:
        "O governo de Joe Biden divulgou, nesta segunda-feira (23), a lista de 31 centros tecnológicos que serão criados nos Estados Unidos, como parte dos planos de investimento que o presidente está promovendo no âmbito de sua candidatura à reeleição no próximo ano.",
      dsConteudo2:
        "A secretária americana do Comércio, Gina Raimondo, disse que estes centros vão criar bons empregos e melhorar nossa segurança nacional e também nossa segurança econômica. ",
      dsConteudo3:
        "Esses investimentos em inovação (...) apoiarão nossa capacidade de competir e, francamente, de superar o resto do mundo, acrescentou, em entrevista coletiva. ",
      dsConteudo4:
        "Selecionados entre 400 inscrições, os 31 centros poderão solicitar bolsas de até US$ 75 milhões (R$ 378,7 milhões na cotação do dia). Foram criados no marco da Lei dos Chips e da Ciência, aprovada no ano passado e que destina US$ 52 bilhões (R$ 262,6 bilhões na cotação do dia) em subsídios para apoiar a produção de semicondutores no país. ",
      dsConteudo5:
        "Esses componentes cruciais para muitas indústrias (automotiva, eletrônica, defesa etc.) são produzidos, principalmente, na Ásia, e o governo americano transformou em prioridade alavancar a produção interna. ",
      dsAdicional:
        "https://noticias.r7.com/tecnologia-e-ciencia/eua-cria-centros-tecnologicos-para-impulsionar-inovacao-23102023",
    },
    {
      nr_sequency: 3,
      dsTitulo: "EUA criam centros tecnológicos para impulsionar inovação",
      dsConteudo:
        "O governo de Joe Biden divulgou, nesta segunda-feira (23), a lista de 31 centros tecnológicos que serão criados nos Estados Unidos, como parte dos planos de investimento que o presidente está promovendo no âmbito de sua candidatura à reeleição no próximo ano.",
      dsConteudo2:
        "A secretária americana do Comércio, Gina Raimondo, disse que estes centros vão criar bons empregos e melhorar nossa segurança nacional e também nossa segurança econômica. ",
      dsConteudo3:
        "Esses investimentos em inovação (...) apoiarão nossa capacidade de competir e, francamente, de superar o resto do mundo, acrescentou, em entrevista coletiva. ",
      dsConteudo4:
        "Selecionados entre 400 inscrições, os 31 centros poderão solicitar bolsas de até US$ 75 milhões (R$ 378,7 milhões na cotação do dia). Foram criados no marco da Lei dos Chips e da Ciência, aprovada no ano passado e que destina US$ 52 bilhões (R$ 262,6 bilhões na cotação do dia) em subsídios para apoiar a produção de semicondutores no país. ",
      dsConteudo5:
        "Esses componentes cruciais para muitas indústrias (automotiva, eletrônica, defesa etc.) são produzidos, principalmente, na Ásia, e o governo americano transformou em prioridade alavancar a produção interna. ",
      dsAdicional:
        "https://noticias.r7.com/tecnologia-e-ciencia/eua-cria-centros-tecnologicos-para-impulsionar-inovacao-23102023",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        width: "100svw",
        height: "100svh",
        flexDirection: "column",
      }}
    >
      <Fab
        sx={{
          backgroundColor: "rgba(53, 68, 145, 1)",
          "&:hover": { backgroundColor: "rgba(53, 68, 100, 1)" },
        }}
        className="NewsPage-FloatingButton"
        href="javascript:history.back()"
      >
        <ArrowBackOutlinedIcon id="NewsPage-BackIcon" />
      </Fab>

      <Box className="NewsPage-Box">
        <HeaderNewsPage />
        {data1?.map(val => {
          if (val.nr_sequency === parseInt(id ?? "", 10)) {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                  padding: mobile ? "0% 10% 2.5em" : "0em 5% 2.5em",
                  gap: "2.5em",
                }}
                key={val.nr_sequency}
              >
                <p id="NewsPage-pText">{val.dsConteudo}</p>
                <p id="NewsPage-pText">{val.dsConteudo2}</p>
                <p id="NewsPage-pText">{val.dsConteudo3}</p>
                <p id="NewsPage-pText">{val.dsConteudo4}</p>
                <p id="NewsPage-pText">{val.dsConteudo5}</p>
                <Link href={val.dsAdicional} underline="none">
                  <p id="NewsPage-pLink">Acesse em: {val.dsAdicional}</p>
                </Link>
              </Box>
            );
          }
          return "";
        })}
        <Bottom />
        <FooterPage />
      </Box>
    </Box>
  );
}

import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import axios from "axios";
import { useQuery } from "react-query";
import "./Views.css";
import ViewBIInterface from "../../types/ViewBIInterface";
import { useParams } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

export default function CollapsibleTable() {
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const mobile = useMediaQuery("(min-width: 901px)");

  const { data } = useQuery<ViewBIInterface[]>(
    "ViewBI",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/viewbi/list"
      );
      return response.data;
    },
    {
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  const { nome } = useParams();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: mobile ? "100svh" : "92.5svh",
          padding: "0.25em",
        }}
      >
        <Box className="Views-Main">
          {data?.map(val => {
            if (val.nm_view === nome) {
              return (
                <PowerBIEmbed
                  embedConfig={{
                    type: "report",
                    id: undefined,
                    embedUrl: `${val.ds_link}`,
                    accessToken: undefined,
                    tokenType: models.TokenType.Embed,
                    settings: {
                      panes: {
                        filters: {
                          expanded: false,
                          visible: false,
                        },
                      },
                      zoomLevel: 4,
                      background: models.BackgroundType.Transparent,
                    },
                  }}
                  key={val.nr_sequency}
                  cssClassName="Views-PowerBI"
                />
              );
            }
            return "";
          })}
        </Box>
      </Box>
    </>
  );
}

/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useRef, useState } from "react";
// Chakra imports
import {
  Box,
  Grid,
  Icon,
  SimpleGrid,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
// Assets
// Custom components
import TotalSpent from "./components/GraficoLinha/TotalSpent";
import Navbar from "./components/navbar/NavbarAdmin";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useQuery } from "react-query";
import axios from "axios";
import _ from "lodash";
import ActivityInterface from "../../../types/ActivityInterface";
import ComplexTable from "./components/Tabela/NotasCanceladas";
import MiniStatistics from "../Components/card/MiniStatistics";
import IconBox from "../Components/icons/IconBox";
import MiniCalendar from "../Components/calendar/MiniCalendar";
import GraficoBarraVariacaoMensal from "./components/GraficoBarra/VariacaoMensal";
import GraficoBarraVariacaoAnual from "./components/GraficoBarra/VariacaoAnual";
import GraficoPizzaCentroCusto from "./components/GraficoPizza/CentroCusto";
import GraficoBarraEstruturaConta from "./components/GraficoBarra/EstruturaConta";
import GraficoBarraSetor from "./components/GraficoBarra/Setor";
import GraficoPizzaConvenio from "./components/GraficoPizza/Convenio";
import GraficoPizzaProcedimento from "./components/GraficoPizza/Procedimento";
import GraficoPizzaTipoAtencao from "./components/GraficoPizza/TipoAtencao";
import GraficoBarraProcedimento from "./components/GraficoBarra/Procedimento";
import GraficoBarraDiagnostico from "./components/GraficoBarra/Diagnostico";

export default function UserReports() {
  const [mobile] = useMediaQuery("(min-width: 901px)");
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { onOpen } = useDisclosure();
  const currentUserId = localStorage.getItem("currentUserId") ?? "-1";
  const [graphReady, setGraphReady] = useState(true);
  const meses1 = useRef([
    {
      name: "Horas",
      data: [32, 27, 39, 43, 56, 28, 45, 66, 34, 42, 21, 58],
    },
  ]);
  const meses2 = useRef([
    {
      name: "Horas",
      data: [58, 62, 32, 37, 24, 22, 30, 28, 18, 35, 48, 62],
    },
  ]);
  const [dataFinal1, setDataFinal1] = useState<
    React.MutableRefObject<
      {
        name: string;
        data: number[];
      }[]
    >
  >(meses1);
  const [dataFinal2, setDataFinal2] = useState<
    React.MutableRefObject<
      {
        name: string;
        data: number[];
      }[]
    >
  >(meses2);

  const horaCliente = useRef([
    {
      1: [67, 33],
      2: [60, 40],
      3: [10, 90],
      4: [85, 15],
    },
  ]);
  const [horaFinalCliente, setHoraFinalCliente] = useState<
    React.MutableRefObject<
      {
        1: number[];
        2: number[];
        3: number[];
        4: number[];
      }[]
    >
  >(horaCliente);

  const clientes = useRef<string[]>(["Opção 1", "Opção 2"]);

  const [lista, setLista] =
    useState<React.MutableRefObject<string[]>>(clientes);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { data, refetch: ActivityRefetch } = useQuery<ActivityInterface[]>(
    "Activity",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/activity/user/" +
          currentUserId
      );
      setGraphReady(true);
      setIsLoading(false);
      return response.data;
    },
    {
      staleTime: 30 * 1000,
    }
  );

  const dataFinalFiltrado = dataFinal1.current[0].data.filter(val => val != 0);
  const ValorTotal = dataFinalFiltrado.reduce((sum, value) => sum + value, 0);
  const mesAtual =
    (ValorTotal / dataFinalFiltrado.length).toFixed(2) + " horas";

  return (
    <Box
      w="100%"
      h={mobile ? "100svh" : "92.5svh"}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {isLoading ? (
        <Box
          w="30%"
          h="auto"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap="1em"
          position="fixed"
          zIndex={10}
        >
          <img
            src="../Images/Others/dashboardLine.gif"
            alt="foto"
            id="Dashboard-Image"
          />
          <img
            src="../Images/Others/dashboardBar.gif"
            alt="foto"
            id="Dashboard-Image"
          />
          <img
            src="../Images/Others/dashboardPie.gif"
            alt="foto"
            id="Dashboard-Image"
          />
        </Box>
      ) : (
        ""
      )}
      <Box
        w="100%"
        h="100%"
        overflow="scroll"
        overflowX="hidden"
        pl="20px"
        pr="20px"
        pt={{ base: "20px" }}
        sx={{
          "&::-webkit-scrollbar": {
            width: "0.3em",
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(255, 255, 255)",
            boxShadow: "rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px",
            borderRadius: "150px",
          },
        }}
      >
        <SimpleGrid
          columns={{ base: 1 }}
          mb="20px"
          sx={{ position: "sticky", top: "0px", zIndex: 2 }}
        >
          <Navbar
            onOpen={onOpen}
            logoText={"Horizon UI Dashboard PRO"}
            brandText={"Faturamento"}
            secondary={false}
            message={"Colocar algo aqui"}
            fixed={false}
          />
        </SimpleGrid>
        {isLoading === false ? (
          <>
            <Grid
              gridTemplateColumns={{
                base: "auto 37.5% 37.5%",
                sm: "100%",
                md: "100%",
                xl: "auto 37.5% 37.5%",
              }}
              gridTemplateRows={{
                base: "55svh",
                sm: "55svh 55svh 55svh",
                md: "55svh 55svh 55svh",
                xl: "55svh",
              }}
              gap="20px"
              mb="20px"
            >
              <SimpleGrid columns={{ base: 1, sm: 1, md: 3, xl: 1 }} gap="20px">
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={AccessTimeOutlinedIcon}
                          color={brandColor}
                          sx={{
                            transition: "0.5s",
                            "&:hover": { transform: "scale(1.15)" },
                          }}
                        />
                      }
                    />
                  }
                  name="Total venda"
                  value={"R$ 23 MI"}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={PlayCircleOutlineOutlinedIcon}
                          color={brandColor}
                          sx={{
                            transition: "0.5s",
                            "&:hover": { transform: "scale(1.15)" },
                          }}
                        />
                      }
                    />
                  }
                  name="Total impostos"
                  value="R$ 3,5 MI"
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={CheckCircleOutlineOutlinedIcon}
                          color={brandColor}
                          sx={{
                            transition: "0.5s",
                            "&:hover": { transform: "scale(1.15)" },
                          }}
                        />
                      }
                    />
                  }
                  name="Total faturado"
                  value="R$ 26,5 MI"
                />
              </SimpleGrid>
              <GraficoBarraVariacaoMensal
                graphReady={graphReady}
                dataFinal={dataFinal1}
              />
              <GraficoBarraVariacaoAnual
                graphReady={graphReady}
                dataFinal={dataFinal2}
              />
            </Grid>
            <Grid
              gridTemplateColumns={{
                base: "auto 27.5% 47.5%",
                sm: "100%",
                md: "100%",
                xl: "auto 27.5% 47.5%",
              }}
              gridTemplateRows={{
                base: "55svh",
                sm: "55svh 55svh 55svh",
                md: "55svh 55svh 55svh",
                xl: "55svh",
              }}
              gap="20px"
              mb="20px"
            >
              <SimpleGrid columns={{ base: 1, sm: 1, md: 3, xl: 1 }} gap="20px">
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={AccessTimeOutlinedIcon}
                          color={brandColor}
                          sx={{
                            transition: "0.5s",
                            "&:hover": { transform: "scale(1.15)" },
                          }}
                        />
                      }
                    />
                  }
                  name="Ticket médio"
                  value={"R$ 7 MIL"}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={PlayCircleOutlineOutlinedIcon}
                          color={brandColor}
                          sx={{
                            transition: "0.5s",
                            "&:hover": { transform: "scale(1.15)" },
                          }}
                        />
                      }
                    />
                  }
                  name="Notas fiscais"
                  value="2500"
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={CheckCircleOutlineOutlinedIcon}
                          color={brandColor}
                          sx={{
                            transition: "0.5s",
                            "&:hover": { transform: "scale(1.15)" },
                          }}
                        />
                      }
                    />
                  }
                  name="Atendimentos"
                  value="2723"
                />
              </SimpleGrid>
              <GraficoPizzaCentroCusto
                graphReady={graphReady}
                horaFinalCliente={horaFinalCliente.current[0][1]}
                clientes={lista}
              />
              <TotalSpent graphReady={graphReady} />
            </Grid>
            <Grid
              gridTemplateColumns={{
                base: "51% auto auto",
                sm: "100%",
                md: "100%",
                xl: "51% auto auto",
              }}
              gridTemplateRows={{
                base: "55svh",
                sm: "55svh 55svh 55svh",
                md: "55svh 55svh 55svh",
                xl: "55svh",
              }}
              gap="20px"
              mb="20px"
            >
              <GraficoBarraEstruturaConta graphReady={graphReady} />
              <GraficoPizzaConvenio
                graphReady={graphReady}
                horaFinalCliente={horaFinalCliente.current[0][2]}
                clientes={lista}
              />
              <GraficoPizzaProcedimento
                graphReady={graphReady}
                horaFinalCliente={horaFinalCliente.current[0][3]}
                clientes={lista}
              />
            </Grid>
            <Grid
              gridTemplateColumns={{
                base: "auto 51% auto",
                sm: "100%",
                md: "100%",
                xl: "auto 51% auto",
              }}
              gridTemplateRows={{
                base: "55svh",
                sm: "55svh 55svh 55svh",
                md: "55svh 55svh 55svh",
                xl: "55svh",
              }}
              gap="20px"
              mb="20px"
            >
              <GraficoBarraProcedimento graphReady={graphReady} />
              <TotalSpent graphReady={graphReady} />
              <GraficoPizzaTipoAtencao
                graphReady={graphReady}
                horaFinalCliente={horaFinalCliente.current[0][4]}
                clientes={lista}
              />
            </Grid>
            <Grid
              gridTemplateColumns={{
                base: "100%",
              }}
              gap="20px"
              mb="20px"
            >
              <ComplexTable tableData={data} graphReady={graphReady} />
            </Grid>
            <Grid
              gridTemplateColumns={{
                base: "auto 35% 35%",
                sm: "100%",
                md: "100%",
                xl: "auto 36% 36%",
              }}
              gridTemplateRows={{
                base: "55svh",
                sm: "55svh 55svh 55svh",
                md: "55svh 55svh 55svh",
                xl: "55svh",
              }}
              gap="20px"
              mb="20px"
            >
              <MiniCalendar selectRange graphReady={graphReady} />
              <GraficoBarraSetor graphReady={graphReady} />
              <GraficoBarraDiagnostico
                graphReady={graphReady}
                dataFinal={dataFinal1}
              />
            </Grid>
          </>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}

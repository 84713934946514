import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import {
  createTheme,
  Button,
  Box,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  DesktopTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import React, { useCallback, useContext, useState } from "react";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
  useQuery,
} from "react-query";
import { CurrentUserContext } from "../../contexts/ContextProvider";
import ExpensesInterface from "../../types/ExpensesInterface";
import CompanyInterface from "../../types/CompanyInterface";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { DateObject } from "react-multi-date-picker";
import { NumericFormat, NumericFormatProps } from "react-number-format";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

interface ModalProps {
  despesasDate: DateObject;
  handleCriarClose: () => void;
  handleClickAdd: () => void;
  DespesasRefetch: (despesasDate: DateObject) => Promise<any>;
  setSemRegistros: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        valueIsNumericString
        prefix="R$"
      />
    );
  }
);

export default function DespesasModal({
  despesasDate,
  handleCriarClose,
  handleClickAdd,
  DespesasRefetch,
  setSemRegistros,
  setIsLoading,
}: ModalProps) {
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const loggedUserId =
    parseInt(localStorage.getItem("currentUserId") ?? "-1", 10) ?? -1;
  const [DespesasDescription, setDespesasDescription] = useState("");
  const [DespesasCompany, setDespesasCompany] = useState(0);
  const [DespesasValor, setDespesasValor] = useState("");
  const [DespesasTipo, setDespesasTipo] = useState(0);

  const [DespesasDescriptionError, setDespesasDescriptionError] =
    useState(false);
  const [DespesasCompanyError, setDespesasCompanyError] = useState(false);
  const [DespesasValorError, setDespesasValorError] = useState(false);
  const [DespesasTipoError, setDespesasTipoError] = useState(false);
  const [dsMsgError, setDsMsgError] = useState("");

  const handleDespesasValor = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== "") {
      setDespesasValorError(false);
    }
    setDespesasValor(event.target.value);
  };

  const handleDespesasDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setDespesasDescriptionError(false);
      }
      setDespesasDescription(event.target.value);
    },
    []
  );

  const handleCompanyId = useCallback((event: SelectChangeEvent<number>) => {
    if (event.target.value !== 0) {
      setDespesasCompanyError(false);
    }
    setDespesasCompany(parseInt(event.target.value.toString(), 10) ?? 0);
  }, []);

  const handleDespesasTipo = useCallback((event: SelectChangeEvent<number>) => {
    if (event.target.value !== 0) {
      setDespesasTipoError(false);
    }
    setDespesasTipo(parseInt(event.target.value.toString(), 10) ?? 0);
  }, []);

  const addDespesas = () => {
    const Despesas = {
      ds_expenses: DespesasDescription,
      dt_expenses: despesasDate?.format("DD/MM/YYYY"),
      nr_expense_type: DespesasTipo,
      vl_expenses: parseFloat(DespesasValor),
      user: {
        nr_sequency: loggedUserId,
      },
      company: {
        nr_sequency: DespesasCompany,
      },
    };

    axios
      .post("https://api.incortec.com.br:8080/registrationservice/expenses/add", Despesas)
      .then(() => {
        handleCriarClose();
        DespesasRefetch(despesasDate);
        setIsLoading(true);
        setSemRegistros(true);
        handleClickAdd();
      });
  };

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (DespesasDescription === "") {
      setDespesasDescriptionError(true);
      setDsMsgError("Favor informar a descrição");
      handleClick();
    } else if (DespesasValor === "") {
      setDespesasValorError(true);
      setDsMsgError("Favor informar o valor");
      handleClick();
    } else if (DespesasTipo === 0) {
      setDespesasTipoError(true);
      setDsMsgError("Favor informar o tipo");
      handleClick();
    } else if (DespesasCompany === 0) {
      setDespesasCompanyError(true);
      setDsMsgError("Favor informar o Cliente");
      handleClick();
    } else {
      addDespesas();
    }
  }, [
    DespesasDescription,
    DespesasValor,
    DespesasTipo,
    DespesasCompany,
    despesasDate,
    loggedUserId,
  ]);

  const { data: repoClientes } = useQuery<CompanyInterface[]>(
    "company",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/company/list"
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}
        onKeyDown={event => {
          if (event.key === "Enter") {
            multiFunctions();
          }
        }}
      >
        <Box className="ModalDespesas-Main">
          <Box className="ModalDespesas-Wrapper">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p id="ModalDespesas-pTitle">Inserir nova despesa</p>
              <IconButton onClick={handleCriarClose}>
                <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
              </IconButton>
            </Box>
            <TextField
              error={DespesasDescriptionError}
              fullWidth
              onChange={handleDespesasDescription}
              value={DespesasDescription}
              size="small"
              label={<p>Descrição da despesa</p>}
              variant="standard"
              color="primary"
            />
            <TextField
              fullWidth
              size="small"
              color="primary"
              variant="standard"
              label={<p>Valor da despesa</p>}
              value={DespesasValor}
              onChange={handleDespesasValor}
              error={DespesasValorError}
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
            />
            <FormControl fullWidth sx={{ textAlign: "left" }}>
              <InputLabel>
                <p>Tipo da despesa</p>
              </InputLabel>
              <Select
                label={<p>Tipo da despesa</p>}
                error={DespesasTipoError}
                onChange={handleDespesasTipo}
                fullWidth
                color="primary"
                variant="standard"
              >
                <MenuItem value={1}>Despesa 1</MenuItem>
                <MenuItem value={2}>Despesa 2</MenuItem>
                <MenuItem value={3}>Despesa 3</MenuItem>
                <MenuItem value={4}>Despesa 4</MenuItem>
                <MenuItem value={5}>Despesa 5</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ textAlign: "left" }}>
              <InputLabel>
                <p>Cliente</p>
              </InputLabel>
              <Select
                error={DespesasCompanyError}
                label={<p>Cliente</p>}
                onChange={handleCompanyId}
                fullWidth
                color="primary"
                variant="standard"
              >
                {repoClientes?.map(val => {
                  return (
                    <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                      {val.nm_company}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <ThemeProvider theme={theme}>
              <Button
                size="small"
                onClick={multiFunctions}
                disableRipple
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: "10px",
                  boxShadow: 5,
                  textTransform: "none",
                  padding: "0.25em 5em",
                }}
              >
                <p id="ModalDespesas-pButton">Inserir despesa</p>
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </Box>
      <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
        <Alert severity="error" variant="filled">
          {dsMsgError}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

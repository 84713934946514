import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
import Card from "../../../Components/card/Card";
import * as React from "react";
// Assets
import { MdCancel, MdCheckCircle, MdOutlineError } from "react-icons/md";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import { rest } from "lodash";
import ActivityInterface from "../../../../../types/ActivityInterface";

// const columns = columnsDataCheck;
export default function ComplexTable(props: {
  tableData: ActivityInterface[] | undefined;
  graphReady: boolean;
}) {
  const { tableData, graphReady } = props;

  const mockTabel = [
    {
      id: 1,
      ds_activity: "nota 1",
      dt_activity: "20/06/2023",
      motivo: "motivo 1",
    },
    {
      id: 2,
      ds_activity: "nota 2",
      dt_activity: "20/06/2023",
      motivo: "motivo 2",
    },
    {
      id: 3,
      ds_activity: "nota 3",
      dt_activity: "20/06/2023",
      motivo: "motivo 3",
    },
    {
      id: 4,
      ds_activity: "nota 4",
      dt_activity: "20/06/2023",
      motivo: "motivo 4",
    },
    {
      id: 5,
      ds_activity: "nota 5",
      dt_activity: "20/06/2023",
      motivo: "motivo 5",
    },
    {
      id: 6,
      ds_activity: "nota 6",
      dt_activity: "20/06/2023",
      motivo: "motivo 6",
    },
  ];

  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const bgList = useColorModeValue("white", "whiteAlpha.100");

  const iconColor = useColorModeValue("brand.500", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const colunas = ["Data", "Descrição", "Motivo"];

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
      h="100%"
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Notas de origem canceladas
        </Text>
        <Menu isOpen={isOpen1} onClose={onClose1}>
          <MenuButton
            alignItems="center"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            w="37px"
            h="37px"
            lineHeight="100%"
            borderRadius="10px"
            onClick={onOpen1}
            {...rest}
            boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
          >
            <Icon
              as={MoreVertOutlinedIcon}
              color={iconColor}
              w="24px"
              h="24px"
            />
          </MenuButton>
          <MenuList
            w="150px"
            minW="unset"
            maxW="150px !important"
            border="transparent"
            backdropFilter="blur(63px)"
            bg={bgList}
            boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
            borderRadius="20px"
            p="15px"
          >
            <MenuItem
              transition="0.2s linear"
              color={textColor}
              _hover={textHover}
              p="0px"
              borderRadius="8px"
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              mb="10px"
              onClick={onOpen2}
            >
              <Flex alignItems="center" flexDirection="row">
                <Icon as={ZoomOutMapOutlinedIcon} h="16px" w="16px" me="8px" />
                <Text fontSize="sm" fontWeight="400">
                  Maximizar
                </Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Drawer isOpen={isOpen2} onClose={onClose2}>
        <DrawerOverlay />
        <DrawerContent minH="100svh" maxH="100svh" minW="95svw" maxW="95svw">
          <DrawerHeader>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <p>Notas de origem canceladas</p>
              <Menu isOpen={isOpen1} onClose={onClose1}>
                <MenuButton
                  alignItems="center"
                  justifyContent="center"
                  bg={bgButton}
                  _hover={bgHover}
                  _focus={bgFocus}
                  _active={bgFocus}
                  w="37px"
                  h="37px"
                  lineHeight="100%"
                  borderRadius="10px"
                  onClick={onOpen1}
                  {...rest}
                  boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                >
                  <Icon
                    as={MoreVertOutlinedIcon}
                    color={iconColor}
                    w="24px"
                    h="24px"
                  />
                </MenuButton>
                <MenuList
                  w="150px"
                  minW="unset"
                  maxW="150px !important"
                  border="transparent"
                  backdropFilter="blur(63px)"
                  bg={bgList}
                  boxShadow="rgba(0, 0, 0, 0.15) 2.5px 2.5px 10px"
                  borderRadius="20px"
                  p="15px"
                >
                  <MenuItem
                    transition="0.2s linear"
                    color={textColor}
                    _hover={textHover}
                    p="0px"
                    borderRadius="8px"
                    _active={{
                      bg: "transparent",
                    }}
                    _focus={{
                      bg: "transparent",
                    }}
                    mb="10px"
                    onClick={onClose2}
                  >
                    <Flex alignItems="center" flexDirection="row">
                      <Icon
                        as={ZoomInMapOutlinedIcon}
                        h="16px"
                        w="16px"
                        me="8px"
                      />
                      <Text fontSize="sm" fontWeight="400">
                        Minimizar
                      </Text>
                    </Flex>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </DrawerHeader>
          <DrawerBody overflow="hidden">
            {graphReady && (
              <Table variant="simple" color="gray.500" mb="24px" mt="12px">
                <Thead>
                  <Tr>
                    {colunas.map(val => {
                      return (
                        <Th
                          pe="10px"
                          borderColor={borderColor}
                          cursor="pointer"
                          key={val}
                        >
                          <Flex
                            justifyContent="space-between"
                            align="center"
                            fontSize={{ sm: "10px", lg: "12px" }}
                            color="gray.400"
                          >
                            <Text
                              justifyContent="space-between"
                              align="center"
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="gray.400"
                            >
                              {val}
                            </Text>
                          </Flex>
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {mockTabel?.map(row => {
                    return (
                      <Tr key={row.id}>
                        <Td
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {row.dt_activity}
                        </Td>
                        <Td
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {row.ds_activity}
                        </Td>
                        <Td
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {row.motivo}
                        </Td>
  
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Box>
        {graphReady && (
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              <Tr>
                {colunas.map(val => {
                  return (
                    <Th
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      key={val}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {val}
                        </Text>
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {mockTabel?.map(row => {
                return (
                  <Tr key={row.id}>
                    <Td
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {row.dt_activity}
                    </Td>
                    <Td
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {row.ds_activity}
                    </Td>
                    <Td
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {row.motivo}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        )}
      </Box>
    </Card>
  );
}

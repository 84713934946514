import {
  Box,
  Button,
  Card,
  Fab,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import React from "react";
import "./HomePage.css";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Navbar from "./Components/Navbar/Navbar";
import News from "./Components/News/News";
import Newsletter from "./Components/Newsletter/Newsletter";
import Solutions from "./Components/Solutions/Solutions";
import Footer from "./Components/Footer/Footer";
import { CircularProgress } from "@mui/joy";

export default function HomePage() {
  const w = window.innerWidth;
  const h = window.innerHeight;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        width: "100vw",
        height: "100%",
        flexDirection: "column",
        justifyContent: "flex-end"
      }}
      id="Top"
    >
      <IconButton
        sx={{
          backgroundColor: "rgba(37, 211, 102, 1)",
          color: "white",
          "&:hover": { backgroundColor: "rgba(18, 140, 126, 1)" },
          boxShadow:
            "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        }}
        href="https://wa.me/31701400?text=Bom%20dia!%20Estou%20entrando%20em%20contato%20através%20do%20link%20do%20site."
        target="_blank"
        className="HomePage-FloatingButton"
        rel="noopener noreferrer"
        size="large"
      >
        <WhatsAppIcon />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100vw",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Navbar />
        <Box className="HomePage-ImageBox"></Box>
        <Box className="HomePage-Card">
          <div id="About" />
          <About />
          <img src="Images/Others/banner.jpg" alt="logo" id="HomePage-Image" />
          <div id="Solutions" />
          <Solutions />
          <div id="News" />
          <News />
          <div id="Newsletter" />
          <Newsletter />
          <div id="Contact" />
          <Contact />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}

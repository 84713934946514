import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  createTheme,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Snackbar,
  styled,
  TextField,
  ThemeProvider,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useState } from "react";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
  useQuery,
} from "react-query";
import HomePageInterface from "../../types/HomePageInterface";
import "./Activity.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import ActivityInterface from "../../types/ActivityInterface";
import dayjs, { Dayjs } from "dayjs";
import {
  DesktopTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CompanyInterface from "../../types/CompanyInterface";
import { DateObject } from "react-multi-date-picker";

export default function ActivityEditModal(props: {
  row: ActivityInterface;
  handleCloseEdit: () => void;
  ActivityRefetch: (activityDate: DateObject) => void;
  handleClickUpdate: () => void;
  activityDate: DateObject;
  setSemRegistros: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "rgba(53, 68, 145, 1)",
      },
      secondary: {
        main: "#ffffff",
      },
    },
  });

  const {
    row,
    handleCloseEdit,
    ActivityRefetch,
    handleClickUpdate,
    activityDate,
    setSemRegistros,
    setIsLoading,
  } = props;
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const loggedUserId =
    parseInt(localStorage.getItem("currentUserId") ?? "-1", 10) ?? -1;

  const [ActivitySequence] = useState(row.id);
  const [ActivityDescriptionInput, setActivityDescriptionInput] = useState(
    row.ds_activity
  );
  const [ActivityTimeIni, setActivityTimeIni] = React.useState<Dayjs | null>(
    dayjs(dayjs(row.dt_time_ini, "HH:mm"))
  );
  const [ActivityTimeFin, setActivityTimeFin] = React.useState<Dayjs | null>(
    dayjs(dayjs(row.dt_time_fin, "HH:mm"))
  );
  const [ActivityCompanyInput, setActivityCompanyInput] = useState(
    row.company.nr_sequency
  );

  const [ActivityDescriptionInputError, setActivityDescriptionInputError] =
    useState(false);
  const [ActivityTimeIniError, setActivityTimeIniError] = useState(false);
  const [ActivityTimeFinError, setActivityTimeFinError] = useState(false);
  const [ActivityCompanyInputError, setActivityCompanyInputError] =
    useState(false);
  const [dsMsgInputError, setDsMsgInputError] = useState("");

  const handleActivityDescriptionInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setActivityDescriptionInputError(false);
      }
      setActivityDescriptionInput(event.target.value);
    },
    []
  );

  const handleActivityTimeIni = useCallback(
    (newActivityTimeIni: Dayjs | null) => {
      if (newActivityTimeIni !== null) {
        setActivityTimeIniError(false);
      }
      setActivityTimeIni(newActivityTimeIni);
    },
    []
  );

  const handleActivityTimeFin = useCallback(
    (newActivityTimeFin: Dayjs | null) => {
      if (newActivityTimeFin !== null) {
        setActivityTimeFinError(false);
      }
      setActivityTimeFin(newActivityTimeFin);
    },
    []
  );

  const handleCompanyIdInput = useCallback(
    (event: SelectChangeEvent<number>) => {
      if (event.target.value !== 0) {
        setActivityCompanyInputError(false);
      }
      setActivityCompanyInput(parseInt(event.target.value.toString(), 10) ?? 0);
    },
    []
  );

  const updateActivity = () => {
    const Activity = {
      id: ActivitySequence,
      ds_activity: ActivityDescriptionInput,
      dt_activity: activityDate?.format("DD/MM/YYYY"),
      dt_time_ini: ActivityTimeIni?.format("HH:mm"),
      dt_time_fin: ActivityTimeFin?.format("HH:mm"),
      dt_time_dif: ActivityTimeFin?.diff(ActivityTimeIni, "h", true).toFixed(2),
      user: {
        nr_sequency: loggedUserId,
      },
      company: {
        nr_sequency: ActivityCompanyInput,
      },
    };

    axios
      .post(
        "https://api.incortec.com.br:8080/registrationservice/activity/update",
        Activity
      )
      .then(() => {
        handleCloseEdit();
        ActivityRefetch(activityDate);
        setIsLoading(true);
        setSemRegistros(true);
        handleClickUpdate();
      });
  };

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (ActivityTimeIni == null) {
      setActivityTimeIniError(true);
      setDsMsgInputError("Favor informar o horário inicial");
      handleClick();
    } else if (ActivityTimeFin == null) {
      setActivityTimeFinError(true);
      setDsMsgInputError("Favor informar o horário final");
      handleClick();
    } else if (ActivityDescriptionInput === "") {
      setActivityDescriptionInputError(true);
      setDsMsgInputError("Favor informar a descrição");
      handleClick();
    } else if (ActivityCompanyInput === 0) {
      setActivityCompanyInputError(true);
      setDsMsgInputError("Favor informar o Cliente");
      handleClick();
    } else {
      updateActivity();
    }
  }, [
    ActivityDescriptionInput,
    ActivityTimeIni,
    ActivityTimeFin,
    activityDate,
    loggedUserId,
    ActivityCompanyInput,
  ]);

  const { data: repoClientes } = useQuery<CompanyInterface[]>(
    "company",
    async () => {
      const response = await axios.get(
        "https://api.incortec.com.br:8080/registrationservice/company/list"
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}
        onKeyDown={event => {
          if (event.key === "Enter") {
            multiFunctions();
          }
        }}
      >
        <Box className="ModalActivity-Main">
          <Box className="ModalActivity-Wrapper">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p id="ModalActivity-pTitle">Alterar atividade</p>
              <IconButton onClick={handleCloseEdit}>
                <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
              </IconButton>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box className="ModalActivity-TimePickerBox">
                <DesktopTimePicker
                  value={ActivityTimeIni}
                  label={<p>horário de início</p>}
                  inputFormat={"HH:mm"}
                  onChange={handleActivityTimeIni}
                  disableOpenPicker={true}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      color="primary"
                      error={ActivityTimeIniError}
                      fullWidth
                    />
                  )}
                />
                <DesktopTimePicker
                  value={ActivityTimeFin}
                  label={<p>horário de término</p>}
                  inputFormat={"HH:mm"}
                  onChange={handleActivityTimeFin}
                  disableOpenPicker={true}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      color="primary"
                      error={ActivityTimeFinError}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
            <TextField
              error={ActivityDescriptionInputError}
              fullWidth
              onChange={handleActivityDescriptionInput}
              defaultValue={ActivityDescriptionInput}
              value={ActivityDescriptionInput}
              size="small"
              label={<p>Descrição da atividade</p>}
              variant="standard"
              color="primary"
            />
            <FormControl fullWidth sx={{ textAlign: "left" }}>
              <InputLabel>
                <p>Cliente</p>
              </InputLabel>
              <Select
                error={ActivityCompanyInputError}
                label={<p>Cliente</p>}
                onChange={handleCompanyIdInput}
                defaultValue={ActivityCompanyInput}
                fullWidth
                color="primary"
                variant="standard"
              >
                {repoClientes?.map(val => {
                  return (
                    <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                      {val.nm_company}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <ThemeProvider theme={theme}>
              <Button
                size="small"
                onClick={multiFunctions}
                disableRipple
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: "10px",
                  boxShadow: 5,
                  textTransform: "none",
                  padding: "0.25em 5em",
                }}
              >
                <p id="ModalActivity-pButton">Atualizar atividade</p>
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </Box>
      <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
        <Alert severity="error" variant="filled">
          {dsMsgInputError}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
